import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
  Statistic,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format, addSeconds } from "date-fns"
import RadialRechart from '../../chart/RadialRechart';
import PieRechart from '../../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketWhatsappStatistic } from "../../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps= (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    socketWhatsappStatistic:(objParam) => dispatch(socketWhatsappStatistic(objParam)),
  }
}


class MessagingStatisticWidget extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        isUnique: false,
        stamp: '',
      };
    }
    
    componentDidUpdate() {
      const {statisticMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})

      if (statisticMessage && statisticMessage.stamp!=this.state.stamp) {
        this.setState({
          stamp: statisticMessage.stamp,
        })
      }
    }

    setUnique() {
      this.setState({
        isUnique: !this.state.isUnique,
      })
    }
  
    formatSeconds(seconds) {
      const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
      // console.log(date)
      return format(date, 'HH : mm : ss');
    }

    render() {
      const {dark, screenWidth, screenHeight}= this.props
      const {isUnique}= this.state
      const {statisticMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})

      return(<>
        <div style={{display: 'flex', minHeight: '100%'}}>
          <div style={{width: 250, background: '#f5f5f5', minHeight: '100%'}}>
            <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: '100%'}}>
              <div style={{marginBottom: '1.5em'}}>
                <Header as='h5'>
                  <b><i>Today!</i> Messaging</b>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' style={{margin: '0'}}>
                  <Icon color='red' name='angle double right' style={{fontSize: '2em'}} />
                  <Header.Content>
                    <b>{numeral((statisticMessage && statisticMessage.totInbound) || 0).format('0,0')}</b>
                    <Header.Subheader>
                      <b>Tot. Inbound</b>
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Divider style={{margin: '1.5em 0'}} />
                <Header as='h2' style={{margin: '0'}}>
                  <Icon color='blue' name='angle double left' style={{fontSize: '2em'}} />
                  <Header.Content>
                    <b>{numeral((statisticMessage && statisticMessage.totOutbound) || 0).format('0,0')}</b>
                    <Header.Subheader>
                      <b>Tot. Outbound</b>
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </div>
          <div style={{background: '#fafafa', flex: 1, minHeight: '100%', padding: '.5em 0'}}>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
              <div style={{flex: '1'}}>
                <div style={{display: 'flex', textAlign: 'center'}}>
                  <div style={{flex: '1'}}>
                    <Header as='h3' icon>
                      <Icon name='thermometer half' style={{fontSize: '1.6em'}} circular inverted color='orange' />
                      <Header.Content>
                        <b>{numeral((statisticMessage && statisticMessage.totWaiting) || 0).format('0,0')}</b>
                        <Header.Subheader>ON QUEUE / WAITING</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{flex: '1'}}>
                    <Header as='h3' icon>
                      <Icon name='podcast' style={{fontSize: '1.6em'}} circular inverted color='pink' />
                      <Header.Content>
                        <b>{numeral((statisticMessage && statisticMessage.totQuickChat) || 0).format('0,0')}</b>
                        <Header.Subheader>QUICK CHAT / BY AGENT</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                </div>
              </div>
              <div>
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='red'><b>OPEN</b></Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress progress color='red' size='medium' percent={(statisticMessage && Math.round(100 * (statisticMessage.totOpen || 0) / ((parseInt(statisticMessage.totInbound || 0)+parseInt(statisticMessage.totOutbound || 0)) || 1))) || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                  </div>
                </div>
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='yellow'><b>PROGRESS</b></Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress progress color='yellow' size='medium' percent={(statisticMessage && Math.round(100 * (statisticMessage.totProgress || 0) / (statisticMessage.totOpen || 1))) || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                  </div>
                </div>
                <div style={{display: 'flex', margin: '.5em 1em'}}>
                  <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                    <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='blue'><b>CLOSED</b></Label>
                  </div>
                  <div style={{flex: '1'}}>
                    <Progress progress color='blue' size='medium' percent={(statisticMessage && Math.round(100 * (statisticMessage.totClosed || 0) / (statisticMessage.totOpen || 1))) || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{width: '11em', textAlign:'center', padding: '.5em 0'}}>
            <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
              <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                <b>{this.formatSeconds((statisticMessage && statisticMessage.avgWaiting) || 0).replace(/00/g,'--')}</b>
              </Statistic.Value>
              <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>AVG. WAITING</Statistic.Label>
            </Statistic>
            <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
              <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                <b>{this.formatSeconds((statisticMessage && statisticMessage.avgResponse) || 0).replace(/00/g,'--')}</b>
              </Statistic.Value>
              <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>AVG. RESPONSE</Statistic.Label>
            </Statistic>
            <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
              <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                <b>{this.formatSeconds((statisticMessage && statisticMessage.avgSession) || 0).replace(/00/g,'--')}</b>
              </Statistic.Value>
              <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>AVG. SESSION</Statistic.Label>
            </Statistic>
          </div>
        </div>
      </>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingStatisticWidget)