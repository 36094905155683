import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
  Checkbox
} from 'semantic-ui-react'

import LineChart from '../chart/LineRechart';
import { parse, format, addSeconds } from "date-fns"


import { connect } from "react-redux"

import { perfClient } from "../../actions/halo/performanceAction"

import PerformanceMessagingSearchByDate from './PerformanceMessagingSearchByDate';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    performanceStore: state.performanceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfClient:(objParam) => dispatch(perfClient(objParam)),
  }
};

class PerformanceMessagingByDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
    };
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.performanceStore.perfClient!==undefined ? this.props.performanceStore.perfClient : {})
    this.props.perfClient(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.performanceStore.perfClient!==undefined ? this.props.performanceStore.perfClient : {})
    this.props.perfClient({search: {date: format(new Date(), 'yyyy-MM-dd'), period: format(new Date(), 'yyyyMM'), view: 'Today', ...search, groupId: 'Client'}, paging: paging})
  }
  
  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    // return format(date, 'HH:mm:ss');
    return format(date, 'HH:mm');
  }

  render() {
    const {screenWidth, screenHeight, mode}= this.props
    const { data, search, paging }= (this.props.performanceStore.perfClient!==undefined ? this.props.performanceStore.perfClient : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return(
      <>
        <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
          <div>
            <PerformanceMessagingSearchByDate setMode={this.props.setMode} mode={mode} />
          </div>
          <div className='noscroll' style={{flex: '1', width: '100%', overflowX: 'scroll'}}>
            <div>
              <Table unstackable selectable columns='16' celled striped basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue' style={{minWidth: 110}}>
                        <b>PERIOD</b>
                        {/* <Header.Subheader>{search && search.view=='Monthly' ? 'Month' : 'Date'}</Header.Subheader> */}
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='2' textAlign='center'>
                      <Header as='h5' color='blue'>
                        TOT. INBOUND
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='2' textAlign='center'>
                      <Header as='h5' color='blue'>
                        TOT. OUTBOUND
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='2' textAlign='center'>
                      <Header as='h5' color='blue'>
                        OPEN TICKET
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='2' textAlign='center'>
                      <Header as='h5' color='blue'>
                        PROGRESS TICKET
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='2' textAlign='center'>
                      <Header as='h5' color='blue'>
                        CLOSED TICKET
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='2' textAlign='center'>
                      <Header as='h5' color='blue'>
                        QUICK CHAT
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='3' textAlign='center'>
                      <Header as='h5' color='blue'>
                        TOT. TICKET
                      </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                  { data!==undefined ? data.map((item,key)=>{
                      const performs= {
                        totInbound: 0,
                        totOutbound: 0,

                        totTicket: 0,
                        totOpen: 0,
                        totProgress: 0,
                        totClosed: 0,
                        totQuickChat: 0,
                      };

                      
                      item && item.statPerformances && Object.entries(item.statPerformances).forEach(([key, value]) => {
                        if ((search.view=='Today' && key==format(new Date(), 'dd')) || (search.view=='Daily' && key==format(parse(search.date.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd'))) {
                          performs['day']= key;
                          
                          performs['totInbound']= value.totInbound;
                          performs['totOutbound']= value.totOutbound;

                          performs['totTicket']= value.totTicket;
                          performs['totOpen']= value.totOpen;
                          performs['totProgress']= value.totProgress;
                          performs['totClosed']= value.totClosed;
                          performs['totQuickChat']= value.totQuickChat;
                        } else if (search.view=='Monthly') {
                          performs['totInbound']= value.totInbound;
                          performs['totOutbound']= value.totOutbound;
                          performs['totTicket']+= parseInt(value.totTicket || '0');
                          performs['totOpen']+= parseInt(value.totOpen || '0');
                          performs['totProgress']+= parseInt(value.totProgress || '0');
                          performs['totClosed']+= parseInt(value.totClosed || '0');
                          performs['totQuickChat']+= parseInt(value.totQuickChat || '0');
                        }
                      })
                      
                      return(
                        <Table.Row key={++seq}>
                          <Table.Cell>{seq}</Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{margin: '0'}}>
                              <Header.Content>
                                {search && search.view=='Monthly' && ((item.period && format(parse(item.period, 'yyyyMM', new Date()), 'yyyy / MMM')) || '-')}
                                {search && search.view=='Today' && ((item.period && format(parse(item.period + format(new Date(), 'dd'), 'yyyyMMdd', new Date()), 'dd/MMM')) || '-')}
                                {search && search.view=='Daily' && ((search.date && format(parse(search.date.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')) || '-')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {(item.period && ((search.view=='Today' || search.view=='Daily') || search.view=='Monthly') && performs.totInbound) || 0}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {(item.period && ((search.view=='Today' || search.view=='Daily') || search.view=='Monthly') && performs.totOutbound) || 0}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {(item.period && ((search.view=='Today' || search.view=='Daily') || search.view=='Monthly') && performs.totOpen) || 0}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {(item.period && ((search.view=='Today' || search.view=='Daily') || search.view=='Monthly') && performs.totProgress) || 0}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {(item.period && ((search.view=='Today' || search.view=='Daily') || search.view=='Monthly') && performs.totClosed) || 0}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {(item.period && ((search.view=='Today' || search.view=='Daily') || search.view=='Monthly') && performs.totQuickChat) || 0}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center' style={{minWidth: '150px'}}>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {(item.period && ((search.view=='Today' || search.view=='Daily') || search.view=='Monthly') && performs.totTicket) || 0}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                        </Table.Row>
                      )
                    }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                  }
                </Table.Body>
              </Table>
            </div>
          </div>
          {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
        </div>
      </> 
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceMessagingByDate)
