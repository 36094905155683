import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
  Statistic,
  Progress,
} from 'semantic-ui-react'

import AgentWidget from '../../libraries/widget/whatsapp/AgentWidget';
import BillingWidget from '../../libraries/widget/whatsapp/BillingWidget';
import CampaignStatisticWidget from '../../libraries/widget/whatsapp/CampaignStatisticWidget';
import CampaignWidget from '../../libraries/widget/whatsapp/CampaignWidget';
import MessagingStatisticWidget from '../../libraries/widget/whatsapp/MessagingStatisticWidget';
import MessagingWidget from '../../libraries/widget/whatsapp/MessagingWidget';
import TicketStatisticWidget from '../../libraries/widget/whatsapp/TicketStatisticWidget';


class DashboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', background: '#f8f8f8', padding: '2em 1.5em'}}>
              <Card.Group>
                <Card fluid as='a' style={{padding: '1em', border: '1px dashed rgba(71, 185, 255, .8)', borderRadius: 0}} onClick={this.props.openModal.bind(this, 'performance_campaign')}>
                  <Card.Content style={{background: '#f5f5f5', padding: '0em'}}>
                    <CampaignStatisticWidget />
                  </Card.Content>
                </Card>

                <Card fluid as='a' style={{padding: '1em', border: '1px dashed rgba(71, 185, 255, .8)', borderRadius: 0}} onClick={this.props.openModal.bind(this, 'performance_message')}>
                  <Card.Content style={{background: '#f5f5f5', padding: '0em'}}>
                    <MessagingStatisticWidget />
                  </Card.Content>
                </Card>

                <Card fluid as='a' style={{padding: '1em', border: '1px dashed rgba(71, 185, 255, .8)', borderRadius: 0}} onClick={this.props.openModal.bind(this, 'performance_ticket')}>
                  <Card.Content style={{background: '#f5f5f5', padding: '0em'}}>
                    <TicketStatisticWidget />
                  </Card.Content>
                </Card>
              </Card.Group>
            </div>
            <div style={{width: 350, background: '#f5f5f5', padding: '2em 1.5em'}}>
              <MessagingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} modal={this.props.modal}/>
              <CampaignWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <AgentWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <BillingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div>
          </div>
        </Container>
      )
    }
}

export default DashboardBody
