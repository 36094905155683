import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import { scheduleOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { searchMessaging } from "../../actions/stream/messagingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchMessaging:(objParam) => dispatch(searchMessaging(objParam)),
  }
}


class ChatboxSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
  }

  submitForm(values) {
    const { search }= this.state
    // this.props.searchMessaging({search: {...values, ...search, channelType: 'WA'}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.messagingStore.searchMessaging!==undefined ? this.props.messagingStore.searchMessaging : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        this.props.setChatWindow(v=='' ? undefined : v, 'keyword')
        parent.props.searchMessaging({search: {...search, keyword: v}, paging: {page: 1, size: 20}})
      }
    }, 500)
  }

  onTicketStatusClick(v, k) {
    const { search, paging }= (this.props.messagingStore.searchMessaging!==undefined ? this.props.messagingStore.searchMessaging : {})

    this.props.setChatWindow(v, k);
    this.props.searchMessaging({search: {...search, ticketStatus: v}, paging: {page: 1, size: 20}})
  }

  onChatWindowClick(v) {
    const { search, paging }= (this.props.messagingStore.searchMessaging!==undefined ? this.props.messagingStore.searchMessaging : {})

    this.props.setChatWindow(v, 'period');
    this.props.searchMessaging({search: {...search, chatWindow: v}, paging: {page: 1, size: 20}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, chatWindow, ticketStatus, keyword }=this.props
    
    return (<>
        <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                <Form.Group widths='equal'>
                  <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                  // icon='circle notched'
                  // loading
                  width={16}
                  placeholder= 'Name/phone ... <ENTER>' />
                </Form.Group>
              </Form>
            </div>
            <div style={{marginLeft: '.3em'}}>
              {/* <Header as='h4' style={{marginTop: '.25em'}}>
                <Header.Content>
                  Search
                  <Header.Subheader>Phone / name</Header.Subheader>
                </Header.Content>
              </Header> */}
              <Popup position= 'top left' inverted content={'Chat Period - '+ ((chatWindow=='today' && 'TODAY') || (chatWindow=='month' && 'THIS MONTH') || (chatWindow=='year' && 'THIS YEAR') || 'TODAY') +' -'}
                trigger= {
                  <Button.Group>
                    <Button type='button' size='big' icon style={{padding: '.55em .2em'}} disabled={!keyword}>
                      <Icon name={(chatWindow=='today' && 'calendar check outline') || (chatWindow=='month' && 'calendar alternate outline') || (chatWindow=='year' && 'calendar alternate') || 'calendar check outline'} />
                    </Button>
                    <Dropdown className='button icon' floating trigger={<></>}  disabled={!keyword}>
                      <Dropdown.Menu>
                        <Dropdown.Item text='Today' value='today' icon='calendar check outline' onClick={this.onChatWindowClick.bind(this, 'today')} />
                        <Dropdown.Item text='This Month' value='month' icon='calendar alternate outline' onClick={this.onChatWindowClick.bind(this, 'month')} />
                        <Dropdown.Item text='This Year' value='month' icon='calendar alternate' onClick={this.onChatWindowClick.bind(this, 'year')} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                } 
              />
            </div>
            <div style={{marginLeft: '.3em'}}>
              <Popup position= 'top left' inverted content={'Ticket Status - '+ ((ticketStatus=='open' && 'OPEN') || (ticketStatus=='progress' && 'PROGRESS') || (ticketStatus=='closed' && 'CLOSED') || (ticketStatus=='all' && 'ALL TICKET') || 'ALL MESSAGE') +' -'}
                trigger= {
                  <Button.Group>
                    <Button type='button' size='big' icon style={{padding: '.55em .2em', cursor: 'none'}}>
                      <Icon name={(ticketStatus=='open' && 'folder') || (ticketStatus=='progress' && 'hourglass half') || (ticketStatus=='closed' && 'times') || (ticketStatus=='all' && 'tag') || 'cloud download'}/>
                    </Button>
                    <Dropdown className='button icon' floating trigger={<></>}>
                      <Dropdown.Menu>
                        <Dropdown.Item text='Open' value='open' icon='folder' onClick={this.onTicketStatusClick.bind(this, 'open', 'status')} />
                        <Dropdown.Item text='Progress' value='progress' icon='hourglass half' onClick={this.onTicketStatusClick.bind(this, 'progress', 'status')} />
                        <Dropdown.Item text='Closed' value='closed' icon='times' onClick={this.onTicketStatusClick.bind(this, 'closed', 'status')} />
                        <Divider />
                        <Dropdown.Item text='All Ticket' value='all' icon='tag' onClick={this.onTicketStatusClick.bind(this, 'all', 'status')} />
                        <Divider />
                        <Dropdown.Item text='Message Stream' value='all' icon='cloud download' onClick={this.onTicketStatusClick.bind(this, undefined, 'status')} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                } 
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.messagingStore.searchMessaging!=undefined && state.messagingStore.searchMessaging.progress==1 ? state.messagingStore.searchMessaging.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'ChatboxSearch',	// a unique identifier for this form
  })(ChatboxSearch)
  )
)