import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import MessagingBlendedRecent from '../../libraries/whatsapp/MessagingBlendedRecent';
import MessagingOutboundRecent from '../../libraries/whatsapp/MessagingOutboundRecent';
import BillingWidget from '../../libraries/widget/whatsapp/BillingWidget';
import CampaignWidget from '../../libraries/widget/whatsapp/CampaignWidget';
import MessagingWidget from '../../libraries/widget/whatsapp/MessagingWidget';
import QueueingWidget from '../../libraries/widget/whatsapp/QueueingWidget';
import ChatboxConversation from '../../libraries/whatsapp/ChatboxConversation';
import ChatboxSearch from '../../libraries/whatsapp/ChatboxSearch';
import ChatboxBrowse from '../../libraries/whatsapp/ChatboxBrowse';

import { connect } from "react-redux"
import { checkToken} from "../../actions/auth/authAction"
import { dispatchOmniphone, mesgOmniphone } from "../../actions/stream/omniphoneAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
    dispatchOmniphone:(objParam) => dispatch(dispatchOmniphone(objParam)),
    mesgOmniphone:(objParam) => dispatch(mesgOmniphone(objParam)),
  }
}

class ChatBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
 
    }

    openChatbox(item) {
      const token= this.props.authStore.checkToken && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data : {};

      this.setState({
        chatbox: item,
      })
      if (item && token && token.groupId=='Agent') {
        this.props.dispatchOmniphone({messagingId: item.messagingId})
      }
    }

    closeChatbox() {
      this.setState({
        chatbox: null,
      })
    }

    setChatWindow(v, p) {
      if (p=='period') {
        this.setState({
          chatWindow: v,
        })
      } else if (p=='status') {
        this.setState({
          ticketStatus: v,
        })
      } else if (p=='keyword') {
        if (v) {
          this.setState({
            keyword: v,
          })
        } else {
          this.setState({
            chatWindow: v,
            keyword: v,
          })
        }
      }
    }

    render() {
      const {mode, campaignId, ticketStatus, chatWindow, keyword}= this.state;
      const {screenWidth, screenHeight, module}= this.props;

      // console.log('chatWindow ticketStatus keyword', chatWindow, ticketStatus, keyword)
      return(
        <Container fluid>
          <div style={{background: 'none', height: '1px', width: '100%', top: '1.8em', position: 'fixed', zIndex: 9999999, textAlign: 'center'}}>
          <Popup content='Change Interaction Mode' inverted position='bottom left' trigger={<Icon color='red' name='comments' circular inverted style={{margin: '0', fontSize: '1.4em', cursor: 'pointer'}} onClick={this.props.openWindow.bind(this, 'interaction_flow')} />} />
            
          </div>

          <div style={{display: 'flex'}}>
            <div style={{flex: '1', background: '#f8f8f8', padding: '2em 1.5em 1em 1.5em'}}>
              <div style={{display: 'flex'}}>
                <div style={{width: 350, background: '#f9f9f9', marginRight: '1.5em'}}>
                  <Segment attached='top' style={{background: '#eeeeee'}}>
                      <div style={{flex: '1', margin: '-1.5em -1.5em -1em -1.5em', padding: '1em 1em 0 1em'}}>
                        <ChatboxSearch openModal={this.props.openModal} openChatbox={this.openChatbox.bind(this)} setChatWindow={this.setChatWindow.bind(this)} chatWindow={chatWindow} ticketStatus={ticketStatus} keyword={keyword} />
                      </div>
                  </Segment>
                  <Segment className='noscroll' attached='bottom' style={{height: screenHeight-160, overflowY: 'scroll', background: '#f5f5f5'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div>
                        {(ticketStatus || chatWindow || keyword) && 
                          <Label size='large' color='yellow' style={{margin: '0', padding: '.5em 1em', borderRadius: '1em', marginLeft: '-.5em'}}>
                            {((ticketStatus=='open' && 'Open Ticket') || (ticketStatus=='progress' && 'On Progress Ticket') || (ticketStatus=='closed' && 'Closed Ticket') || (ticketStatus=='all' && 'All Ticket')) || 'All Messages'}
                            - {((chatWindow=='today' && 'On') || (chatWindow=='month' && 'In') || (chatWindow=='year' && 'In') || 'On')} {((chatWindow=='today' && 'Today') || (chatWindow=='month' && 'This Month') || (chatWindow=='year' && 'This Year') || 'Today')}
                          </Label>
                        }
                        {(!ticketStatus && !chatWindow && !keyword) && 
                          <Label size='large' color='teal' style={{margin: '0', padding: '.5em 1em', borderRadius: '1em', marginLeft: '-.5em'}}>
                            Message Stream
                          </Label>
                        }
                        <Divider />
                      </div>
                      {(ticketStatus || chatWindow || keyword) && <div style={{flex: 1, margin: '0 -1.5em  -.5em  -1.5em  ', height: screenHeight}}>
                        <ChatboxBrowse openModal={this.props.openModal} openChatbox={this.openChatbox.bind(this)} screenHeight={screenHeight-240} chatWindow={chatWindow} ticketStatus={ticketStatus} keyword={keyword}/>
                      </div>}
                      {(!ticketStatus && !chatWindow && !keyword) && <div style={{flex: 1, margin: '0 -1.5em  -.5em  -1.5em  ', height: screenHeight}}>
                        <div style={{margin: '0 1em 1.5em 1em'}}>
                          <MessagingBlendedRecent openModal={this.props.openModal} openChatbox={this.openChatbox.bind(this)} chatWindow={chatWindow} ticketStatus={ticketStatus} />
                        </div>
                      </div>}
                    </div>
                  </Segment>
                </div>
                <div style={{flex: 1}}>
                  <Segment style={{height: screenHeight-90, background: '#f5f5f5', padding: '0'}}>
                    {this.state.chatbox && <ChatboxConversation params={this.state.chatbox || this.props.params} mode={this.props.mode || 'blended'} screenWidth={screenWidth} screenHeight={screenHeight-40} openWindow={this.props.openWindow} openModal={this.props.openModal} closeModal={this.props.closeModal} closeChatbox={this.closeChatbox.bind(this)}/>}
                  </Segment>
                </div>
              </div>
            </div>
            {screenWidth>=1600 && <div style={{width: 350, background: '#f5f5f5', padding: '2em 1.5em'}}>
              <QueueingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} modal={this.props.modal}/>
              <CampaignWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <BillingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div>}
          </div>
        </Container>
      )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatBody)
