import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'
import ChatboxConversation from '../../libraries/whatsapp/ChatboxConversation';
import TransferForm from '../../libraries/whatsapp/TransferForm';
import ContactBody from './ContactBody';

// import ApiForm from '../../libraries/whatsapp/ApiForm'


class TransferBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div style={{margin: '1em 1em 1em 1em'}}>
          <TransferForm contactId={0} setMode={this.setMode.bind(this)} closeModal={this.props.closeModal} params={this.props.params}/>
        </div>
      )
    }
}

export default TransferBody
