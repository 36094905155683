import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Label, Image, Modal, Form, Input, Grid, Card, Icon, Dropdown, Select } from 'semantic-ui-react'

import { channelOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, WysiwygField, DatePickerField, TextAreaField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetScript, getScript,updateScript, createScript } from "../../actions/halo/scriptAction"
import { listRemark, listTemplate } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ScriptForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    scriptStore: state.scriptStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetScript:(objParam) => dispatch(resetScript(objParam)),
    getScript:(objParam) => dispatch(getScript(objParam)),
    createScript:(objParam) => dispatch(createScript(objParam)),
    updateScript:(objParam) => dispatch(updateScript(objParam)),

    listTemplate:(objParam) => dispatch(listTemplate(objParam)),
    listRemark:(objParam) => dispatch(listRemark(objParam)),
  }
}

class ScriptForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      image: false,
      buttons: true,
      preview: true,
    }
  }

  componentDidMount(){
    this.props.listRemark({campaignId: -1});
    this.props.resetScript(this.props.mode)

    if (this.props.mode==2) { //update
      this.props.getScript({scriptId: this.props.scriptId})
    }
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.scriptStore.updateScript!=undefined ? this.props.scriptStore.updateScript : {}) : (this.props.scriptStore.createScript!=undefined ? this.props.scriptStore.createScript : {}))
    if (!modal && progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (modal && progress==1 && code=='00') {
      this.props.closeModal('hide');
      this.props.listTemplate({search: {clientId: (this.props.clientId || null), media: 'Whatsapp'}})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateScript({...values, media: 'Whatsapp'}) : this.props.createScript({...values, ...{clientId: this.props.clientId}, media: 'Whatsapp'})
  }
  
  insertVariable(v) {
    // console.log(v)
    if (this.props.media!='Email') {
      this.props.dispatch(change('ScriptForm', 'script', (this.props.script||'') +' '+ v +' '));
    } else {
      navigator.clipboard.writeText(v)
    }
    // e.target.focus()
  }

  onChangeSelect(k, v) {
    this.setState({
      script: v.value
    })
  }

  setPreview(v) {
    this.setState({
      preview: v,
    })
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, modal, screenWidth, screenHeight } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.scriptStore.updateScript!=undefined ? this.props.scriptStore.updateScript : {}) : (this.props.scriptStore.createScript!=undefined ? this.props.scriptStore.createScript : {}))
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {this.props.mode!=3 &&
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>}
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              validate={[required]}
              label='Name'
              placeholder='Script Name' />
              {/* <Field name='media' component={SelectField}
              options={channelOptions()}
              validate={[required]}
              label='Media'
              placeholder='Media Container' /> */}
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='descript' component={InputField}
              label='Description'
              placeholder='Description' />
            </Form.Group> */}
          </Segment>
          <Segment>
            <Header color='blue' as='h5' style={{margin: '0'}}>
              <Header.Content>Script / Code Snippet</Header.Content>
            </Header>
            <div style={{display: (modal && 'block') ||'flex'}}>
              <div className='variable' style={{width: (modal && 'auto') || '220px', marginTop: '.3em', marginRight: !modal && '1.3em', padding: '1em 1.3em', background: '#f5f5f5', border: '1px solid #ddd'}}>
                <Header as='h4' color='red'>
                  <Header.Content>
                    Dynamic Variable !
                    <Header.Subheader>Applicable Variables to script.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Select style={{marginBottom: '1em'}} label='Variables' fluid onChange={this.onChangeSelect.bind(this)}
                options={[
                  {key: 1, value: 'telesales', text: 'Telesales'},
                  {key: 2, value: 'collection', text: 'Collection'},
                  <Divider key='0' style={{margin: '0'}}/>,
                  {key: 3, value: 'payment', text: 'Payment Method'},
                ]}
                />
                <Label.Group style={{textAlign: 'center'}}>
                  <Popup inverted size='small' header='{stampId}' content='Unique Stamp Id' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{stampId}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Stamp Id</Label> 
                  } />
                  <Popup inverted size='small' header='{custTitle}' content='Customer or target title' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custTitle}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Cust. Title</Label> 
                  } />
                  <Popup inverted size='small' header='{custName}' content='Customer or target name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custName}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Cust. Name</Label> 
                  } />
                  <Popup inverted size='small' header='{custRef}' content='Customer ref. identity' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custRef}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Cust. Ref. Id</Label>
                  } />
                  <Popup inverted size='small' header='{contractRef}' content='Contract ref. for customer' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{contractRef}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Contract Ref.</Label>
                  } />
                  <Popup inverted size='small' header='{merchantName}' content='Merchant information / name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{merchantName}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Merchant</Label>
                  } />

                  <Popup inverted size='small' header='{city}' content='City where customer stays' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{city}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>City</Label>
                  } />
                  <Popup inverted size='small' header='{information}' content='General information which important for customers' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{information}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Free Info</Label>
                  } />

                  {this.state.script && <Divider style={{margin: '.5em 0'}} /> }

                  {this.state.script=='collection' && <>
                    <Popup inverted size='small' header='{billAmount}' content='Bill amount which must paid' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{billAmount}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Bill Amount</Label>
                    } />
                    <Popup inverted size='small' header='{overdueAmount}' content='Penalty or fine amount' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{overdueAmount}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Penalty Amt.</Label>
                    } />
                    <Popup inverted size='small' header='{overdueDay}' content='Payment was late in days' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{overdueDay}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Late In Days</Label> 
                    } />
                    <Popup inverted size='small' header='{billDate}' content='Date when billing generated' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{billDate}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Billing Date</Label>
                    } />
                    <Popup inverted size='small' header='{dueDate}' content='Due date when bill should be paid ' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{dueDate}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Due Date</Label>
                    } />
                  </>}

                  {this.state.script=='telesales' && <>
                    <Popup inverted size='small' header='{product}' content='Product information / name' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{product}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Product</Label>
                    } />
                    <Popup inverted size='small' header='{service}' content='Subscription product or service information' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{service}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>Subsc. Info</Label>
                    } />
                  </>}

                  {this.state.script=='payment' && <>
                    <Popup inverted size='small' header='{vaBNI}' content='VA BNI' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBNI}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BNI</Label>
                    } />
                    <Popup inverted size='small' header='{vaBRI}' content='VA BRI' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBRI}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BRI</Label>
                    } />
                    <Popup inverted size='small' header='{vaBSI}' content='VA BSI' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBSI}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BSI</Label>
                    } />
                    <Popup inverted size='small' header='{vaBTN}' content='VA BTN' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBTN}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BTN</Label>
                    } />
                    <Popup inverted size='small' header='{vaBCA}' content='VA BCA' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaBCA}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA BCA</Label>
                    } />
                    <Popup inverted size='small' header='{vaMandiri}' content='VA Mandiri' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaMandiri}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Mandiri</Label>
                    } />
                    <Popup inverted size='small' header='{vaPermata}' content='VA Permata' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaPermata}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Permata</Label>
                    } />
                    <Popup inverted size='small' header='{vaPanin}' content='VA Panin' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaPanin}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Panin</Label>
                    } />
                    <Popup inverted size='small' header='{vaNiaga}' content='VA Niaga' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaNiaga}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Niaga</Label>
                    } />
                    <Popup inverted size='small' header='{vaMuamalat}' content='VA Muamalat' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaMuamalat}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Muam..</Label>
                    } />
                    <Popup inverted size='small' header='{vaIndomaret}' content='VA Indomaret' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaIndomaret}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Indo..</Label>
                    } />
                    <Popup inverted size='small' header='{vaAlfamart}' content='VA Alfamart' trigger={
                      <Label as='a' onClick={this.insertVariable.bind(this, '{vaAlfamart}')} style={{width: '7em', padding: '.5em .3em', textAlign: 'center', borderRadius: '1em', margin: '0 .2em .5em .2em'}}>VA Alfa..</Label>
                    } />
                  </>}
                </Label.Group>
                <Divider style={{margin: '.5em 0'}}/>
                <Header as='h6' style={{margin: '0'}}>
                  <Header.Content>
                    Click to copied and paste your selected variable if not added automaticaly.
                  </Header.Content>
                </Header>
              </div>

              <div style={{flex: '1', marginTop: modal ? '1em' : '-1.5em'}}>
                <Form.Group widths='16'>
                {this.props.media!='Email' && 
                  <Field name='script' component={TextAreaField}
                  width='16'
                  rows='13'
                  validate={[required]}
                  label={<b>Content Script</b>} /> }

                {this.props.media=='Email' && 
                  <Field name='script' component={WysiwygField} 
                  modal={modal}
                  screenWidth={screenWidth}
                  width='16'
                  rows='8'
                  validate={[required]}
                  label='Content Script' />}
                </Form.Group>
                <Label.Group style={{marginTop: '-.8em', borderRadius: '.3em', background: '#fafafa', padding: '.5em .5em 0 .5em', border: '1px solid #e0e0e0'}}>
                  <Label style={{fontWeight: '300'}}>_italic_ : <i>italic</i></Label>
                  <Label style={{fontWeight: '300'}}>*bold*: <b>bold</b></Label>
                  <Label style={{fontWeight: '300'}}>~strikethrough~: <span style={{textDecoration: 'line-through'}}>strikethrough</span></Label>
                </Label.Group>
                {(this.props.media=='Email' || this.props.media=='Whatsapp' || this.props.media=='Telegram' || this.props.media=='Facebook' || this.props.media=='Twitter' || this.props.media=='Instagram') && <>
                  <Divider hidden/>
                  {this.state.image && 
                    <Form.Group widths='equal' style={{marginTop: '2em'}}>
                      <Form.Field>
                        <Field name='image' component={ImageUploadField}
                        // width={420} 
                        height={420} 
                        aspectRatio= '1:1' />
                      </Form.Field>
                    </Form.Group>
                  }
                  {this.state.buttons && <>
                    <Header as='h6' color='blue' style={{margin: '0 0 .3em 0'}}>
                      <Header.Content>
                        Button Replies
                      </Header.Content>
                    </Header>
                    <div style={{padding: '1em', border: '1px solid #e0e0e0', background: '#fafafa', borderRadius: '.3em'}}>
                      <div style={{display: 'flex', marginBottom: '.5em'}}>
                        <div style={{flex: 1}}>
                        </div>
                        <div>
                        </div>
                      </div>
                      <Form.Group widths='16'>
                        <Field name='buttonCaption1' component={LabelInputField}
                        width={10}
                        label='Btn 1'
                        placeholder='Button Text / Caption'/>
                        <Field name='buttonRemark1' component={SelectField}
                        width={6}
                        options={listRemark.options || []}
                        placeholder='Cust. Remark'/>
                      </Form.Group>
                      <Form.Group widths='16'>
                        <Field name='buttonCaption2' component={LabelInputField}
                        width={10}
                        label='Btn 2'
                        placeholder='Button Text / Caption'/>
                        <Field name='buttonRemark2' component={SelectField}
                        width={6}
                        options={listRemark.options || []}
                        placeholder='Cust. Remark'/>
                      </Form.Group>
                      <Form.Group widths='16'>
                        <Field name='buttonCaption3' component={LabelInputField}
                        width={10}
                        readOnly
                        validate={[required]}
                        label='Btn 3'
                        placeholder='Button Text / Caption'/>
                        <Field name='buttonRemark3' component={InputField}
                        width={6}
                        validate={[required]}
                        readOnly
                        placeholder='Blocked'/>
                      </Form.Group>
                    </div>
                  </>}
                </>}
                {/* <Form.Group widths='16'>
                  <Field name='video' component={TextAreaField}
                  width='16'
                  label='Video Attachment' />
                </Form.Group> */}
              </div>
            </div>
          </Segment>
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Script' : 'Insert Script') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{display: 'flex'}}>
              <div style={{flex: 1}}>
                <Button type='button' size='small' content='Preview' color='blue' onClick={this.setPreview.bind(this, true)} />
              </div>
              <div>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
              </div>
            </div>
          </Segment>
        </Form>
        {this.state.preview && <div style={{background: '#666666aa', position: 'fixed', width: '100%', height: '100%', top: 0, left: 0}}>
          <div style={{background: '#f0f0f0', minHeight: '60%', maxWidth: 400, margin: '15% auto 0 auto'}}>
            <div style={{display: 'flex', padding: '1em 1.5em', flexDirection: 'column'}}>
              <div style={{padding: '0 0 1.5em 0'}}>
                <Header as='h5'>
                  <Header.Content>
                    Content Preview
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', overflowY: 'auto', maxHeight: 600}}>
                <Segment>
                  <i>Broadcast Content shows here ...</i>
                  <Divider />
                  <div dangerouslySetInnerHTML={{__html: (this.props.script && this.props.script.replace(/\n/gm,'<br />')) || '-'}} />
                </Segment>
                <Segment>
                  <i>Replies Buttons shows here ...</i>
                  <Divider />
                  <Popup inverted header='Auto Adjustment Header' content='Title adjustment is based on campaign type Collection or Telesales or Verification' trigger={
                    <p style={{fontWeight: 'bold'}}>{'[Konfirmasi Pembayaran / Feedback Pelanggan]'}</p>
                  } />
                  <Popup inverted header='Auto Adjustment Body' content='Content adjustment is based on campaign type Collection or Telesales or Verification' trigger={
                    <p>{'Untuk memastikan bahwa Anda tidak dihubungi lagi dari nomor ini, [silahkan tekan tombol dibawah untuk informasi pembayaran sesuai keperluan. / silahkan tekan tombol dibawah untuk menentukan response Anda.]'}</p>
                  } />
                  <p style={{fontSize: '.9em'}}>{'Terimakasih atas konfirmasi dan waktunya.'}</p>
                  {this.props.buttonCaption1 && <Label size='large' style={{display: 'block', textAlign: 'center', margin: '.5em 0'}}>{this.props.buttonCaption1}</Label>}
                  {this.props.buttonCaption2 && <Label size='large' style={{display: 'block', textAlign: 'center', margin: '.5em 0'}}>{this.props.buttonCaption2}</Label>}
                  {this.props.buttonCaption3 && <Label size='large' style={{display: 'block', textAlign: 'center', margin: '.5em 0'}}>{this.props.buttonCaption3}</Label>}
                </Segment>
              </div>
              <div style={{padding: '1.5em 0 0 0', textAlign: 'center'}}>
                <Button type='button' color='blue' onClick={this.setPreview.bind(this, false)}>Close</Button>
                {/* <Button style={{float:'right'}} type='button'>Submit</Button> */}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.scriptStore.getScript!=undefined && state.scriptStore.getScript.progress==1 ? {...state.scriptStore.getScript.data, buttonCaption3: 'Block / Jangan Kirim Lagi', buttonRemark3: 'Blocked'} : {buttonCaption3: 'Block / Jangan Kirim Lagi', buttonRemark3: 'Blocked'}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
      media: selector(state, 'media'),
      script: selector(state, 'script'),

      buttonCaption1: selector(state, 'buttonCaption1'),
      buttonCaption2: selector(state, 'buttonCaption2'),
      buttonCaption3: selector(state, 'buttonCaption3'),
    }),
  )
  (reduxForm({
    form: 'ScriptForm',	// a unique identifier for this form
  })(ScriptForm)
  )
)