import React, { Component } from 'react'
import { Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Accordion, Divider, Segment, Label } from 'semantic-ui-react'
import DismissableMessage from '../common/DismissableMessage'

import { ReactSVG } from 'react-svg'
import stream_logo  from '../../assets/wasap-logo.svg'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import {LabelInputField, InputField, CheckboxField, Checkbox } from '../validation/componentrsui'
import { required, number, minValue, email, account, fullName, phoneNumber, confirmedPassword } from '../validation/validation'

import { connect } from "react-redux"
import { resetPasswd, resetForm } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswd:(objParam) => dispatch(resetPasswd(objParam)),
    resetForm:(objParam) => dispatch(resetForm(objParam)),
  }
}

class AuthenticatePassword extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount() {
    this.props.resetForm("resetPasswd")
  }

  componentDidUpdate() {
    const { reset } = this.props
    const { code, text, progress, data }= this.props.authStore.resetPasswd ? this.props.authStore.resetPasswd : {}
    
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        reset()
        this.props.resetForm("resetPasswd")
        this.props.openMenu('signin')
      }, 3000)
    } else {
      setTimeout(()=>{
        this.props.resetForm("resetPasswd")
      }, 5000)
    }
  }

  submitForm(values) {
    // console.log('RESET SUBMIT VALUES: ', values)
    const { reset } = this.props
    reset()
    this.props.resetPasswd(values)
    
    if (this.props.onSubmit!==undefined) {
      this.props.onSubmit(values)
    }
  }
  
  resetForm() {
    const { reset } = this.props
    reset()
    //this.props.onClose()
    this.props.resetForm("resetPasswd")
  }

  render() {
    //console.log('PROPS SIGN UP FORM: ',this.props)
    const { error, handleSubmit, pristine, reset, submitting, screenWidth, screenHeight } = this.props
    const { code, text, progress, data }= this.props.authStore.resetPasswd ? this.props.authStore.resetPasswd : {}

    return (
      <div className="signin-form" style={{background: '#ebdfcc'}}>
        <div style={{margin: 'auto', padding: '3em', border: '1px solid rgba(100,100,100,.1)', borderRadius: '.5em', background: 'rgba(255,255,255,.3)'}}>
        <div style={{display: 'flex'}}>
            <div style={{width: '15em', margin: '0 2em 0 0'}}>
              <ReactSVG src={stream_logo}/>
            </div>
            <div style={{flex: '1'}}>
              <div style={{margin: '1em 0 1em 0'}}>
                <Header as='h3' color='blue'> 
                  <Header.Content>
                    Reset your password ?
                    <Header.Subheader>Valid email and mobile phone is required for further transaction validation.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                <Form.Group widths='equal'>
                  <Field name='account' component={InputField}
                  icon='mobile alternate'
                  label= 'Handphone'
                  placeholder='No. Handphone'
                  validate={[required, phoneNumber]} />
                  <Field name='email' component={InputField}
                  icon='mail outline'
                  label= 'Email'
                  placeholder='Email'
                  validate={[required, email]} />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='userPasswd' component={InputField}
                  icon='unlock alternate'
                  type='password'
                  validate={[required]}
                  label= 'New Password'
                  placeholder='New Password' />
                  <Field name='confPasswd' component={InputField}
                  icon='unlock alternate'
                  type='password'
                  validate={[required, confirmedPassword]}
                  label= 'Retype Password'
                  placeholder='Retype password for confirmation' />
                </Form.Group>

                {progress==1 ? <DismissableMessage style={{boxShadow: 'none', color: code=="00" ? '#fff' : '#fff', background: code=="00" ? '#0a717c' : '#b32800', textAlign: 'center'}} header={'Reset password ' + (code=="00" ?  'OK' : 'NOK')} content={text} /> : null}
                {progress==2 ? <DismissableMessage style={{boxShadow: 'none', color: '#fff', background: 'rgb(253, 81, 27)', textAlign: 'center'}} header='Error' content='Network connection failures.' /> : null}

                <Divider hidden />
                <Form.Group widths='equal'>
                  <Form.Field style={{textAlign: 'right'}}>
                    {/* <Button type='reset' size='large' content=' Reset' color='grey' disabled={pristine || submitting} onClick={this.resetForm.bind(this)} /> */}
                    <Button color='blue' size='large' id='btReset' icon={progress==0 ? true : false}> {progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(reduxForm({
  form: 'resetPasswdForm',	// a unique identifier for this form
})(AuthenticatePassword))
