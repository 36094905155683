import React, { Component } from 'react';
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label } from 'semantic-ui-react';

import DismissableMessage from '../common/DismissableMessage';
import IconToggle from '../common/IconToggle';
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData';

import { SubmissionError, Field, reduxForm, getFormValues, formValueSelector } from 'redux-form';
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField, LabelSelectField } from '../validation/componentrsui';
import { required, phoneNumberID, number, minValue, email } from '../validation/validation';

import { connect } from "react-redux";
import { resetMessaging, agentMessaging } from "../../actions/stream/messagingAction"
import { listWorkgroup, listAgent } from "../../actions/halo/masterAction"


//CONNECT REDUX STORE
const selector = formValueSelector('TransferForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listAgent:(objParam) => dispatch(listAgent(objParam)),
    agentMessaging:(objParam) => dispatch(agentMessaging(objParam)),
    resetMessaging:(objParam) => dispatch(resetMessaging(objParam)),
  }
}

class TransferForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      progress: null,
      code: '00',
      text: 'OK',
    }
  }

  componentDidMount() {
    this.props.listAgent({search:{groupId: 'Agent'}})
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= this.props.messagingStore.agentMessaging ? this.props.messagingStore.agentMessaging : {};
    if (progress==1 && code=='00') {
      this.props.closeModal();
    }

    this.props.resetMessaging(this.props.mode)
  }

  resetForm() {
    const { reset } = this.props

    this.setState({
      progress: null,
      code: '00',
      text: 'OK',
    })
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    this.props.params && this.props.agentMessaging({...values, messagingId: this.props.params.messagingId, conversationStatus: 0,})
  }

  render() {
    const { progress, code, text } = this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const listAgent= (this.props.masterStore.listAgent!=undefined && this.props.masterStore.listAgent.progress=='1' ? this.props.masterStore.listAgent.data : [])
    

    // console.log(this.props.params)

    return (
      <div style={{padding: '1em 1em'}}>
        <Header as='h4'>
          <Icon name='arrow circle up' style={{fontSize: '1.8em'}} color='pink' />
          <Header.Content>
            <b>Transfer Message</b>
            <Header.Subheader>Transfer message to new available agent.</Header.Subheader>
          </Header.Content>
        </Header>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                <div dangerouslySetInnerHTML={{__html: this.props.params.content.substring(0, 150).replace(/\n/gm,'<br />') +' . . .'}} />
              </Header.Content>
            </Header>
          </Segment>
          <Segment>
            <Header as='h4'>
              <Header.Content>
                <Header.Subheader style={{color: '#0069b7', fontSize: '.9em', fontWeight: 'bold'}}>Previous Agent</Header.Subheader>
                {(this.props.params.agent && this.props.params.agent.name) || 'No Allocated Agent'}    
              </Header.Content>
            </Header>
            
          </Segment>
          <Segment>
            <Form.Group widths='16'>
              <Field name='agentId' component={LabelSelectField}
              width={16}
              options={listAgent}
              caption={<b>New Allocated Agent</b>}
              label='Agent'
              validate={[required]}
              placeholder='New allocated agent for ticket' />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={'Send Quick Chat' + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: ({}),
      formValues: getFormValues('TransferForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'TransferForm',	// a unique identifier for this form
  })(TransferForm)
  )
)
