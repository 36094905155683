import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
  Popup,
  Rating,
} from 'semantic-ui-react'

import {imagePath} from "../../libraries/common/_lib"

import DismissableMessage from '../common/DismissableMessage';
import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, formatDistance, formatRelative, } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { TextAreaField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { checkToken} from "../../actions/auth/authAction"
import { resetMessaging, browseMessaging, statusMessaging, updateMessaging } from "../../actions/stream/messagingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    resetMessaging:(objParam) => dispatch(resetMessaging(objParam)),
    browseMessaging:(objParam) => dispatch(browseMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),
    updateMessaging:(objParam) => dispatch(updateMessaging(objParam)),
  }
}

class ChatviewConversation extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetMessaging(this.props.mode);
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (this.props.messagingStore.updateMessaging!=undefined ? this.props.messagingStore.updateMessaging : {});
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.closeModal()}, 1000 )
    }
  }
  
  setRate(e, v) {
    this.setState({
      lastChange: new Date().getTime(),
      rating: v.rating,
    })
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  submitForm(values) {
    const { search }= this.state
    this.props.params && this.props.updateMessaging({...values, messagingId: this.props.params.messagingId, qaScore: this.state.rating || 3})
  }


  render() {
    const { feedbacks, rating }= this.state
    const token= this.props.authStore.checkToken && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data : {};
    const { screenWidth, screenHeight, params, error, handleSubmit, pristine, reset, submitting, mode }=this.props

    const { progress, code, text }= (this.props.messagingStore.updateMessaging!=undefined ? this.props.messagingStore.updateMessaging : {});

    const feedItems= []
    feedbacks && Object.keys(feedbacks).map(key=>{
      feedItems.push(feedbacks[key])
    })
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', height: '100%'}}>
        <div>
          <div style={{textAlign: 'center'}}>
            <Header as='h5' icon style={{margin: '0'}}>
              <Icon name='comment alternate' circular inverted color='yellow' />
              <Header.Content>
                <b><i>Archive!</i></b> Conversation
                <Header.Subheader>Historical chat / conversation session</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          {/* <Divider horizontal>
            <Icon name='times' color='red' onClick={this.props.closeModal.bind(this)} style={{fontSize: '1.8em', cursor: 'pointer'}}/>
          </Divider> */}
        </div>
        {params && <div style={{margin: '2em 0 .5em 0', padding: '2em 1em 2em 1em', background: '#f0f0f0', borderRadius: '1em', border: '1px solid #ddd'}}>
          <Grid columns='16' divided>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Recipient</b></Header.Subheader>
                    {params.terminating && params.terminating.replace('62', '0')}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Sent</b></Header.Subheader>
                    {params.sentStamp && format(parse(params.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss, dd/MMM yyy')}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Responsed</b></Header.Subheader>
                    {params.receivedStamp && format(parse(params.receivedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss, dd/MMM yyy')}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Gateway / Device</b></Header.Subheader>
                    {params.socketId || params.originating}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Delivered</b></Header.Subheader>
                    {params.deliveredStamp && format(parse(params.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss, dd/MMM yyy')}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Tot. Incoming</b></Header.Subheader>
                    {params.totIncoming || 0}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Status</b></Header.Subheader>
                    <b>{(params.receivedStamp && 'RESPONSED') || (params.readStamp && 'READ') || (params.deliveredStamp && 'DELIVERED') || (params.sentStamp && 'SENT')}</b>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Read</b></Header.Subheader>
                    {(params.readStamp && format(parse(params.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss, dd/MMM yyy')) || '-'}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Tot. Outgoing</b></Header.Subheader>
                    {params.totOutgoing || 0}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>}
        
        {params && <div style={{margin: '.5em 0 1em 0', padding: '2em 1em 2em 1em', background: '#f0f0f0', borderRadius: '1em', border: '1px solid #ddd'}}>
          <Grid columns='16' divided>
            <Grid.Row>
              <Grid.Column width={11}>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Campaign</b></Header.Subheader>
                    {(params.campaignId && params.campaign && params.campaign.name) || 'No Campaign Information'}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>{(params.flow==1 && 'Broadcast') || 'Inbound'} Message / Content</b></Header.Subheader>
                    <b>
                      <p dangerouslySetInnerHTML={{__html: params.content && params.content.replace(/\n/gm,'<div></div>')}} />
                    </b>

                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Ticket Info</b></Header.Subheader>
                    <b>{((params.conversationStatus==0 && 'Open / ') || (params.conversationStatus==-1 && 'Progress / ') || (params.conversationStatus==1 && 'Closed / ') || '').toUpperCase()}</b> {params.ticketNo || 'No Ticket'}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Remark / Status</b></Header.Subheader>
                    {params.remark || 'No Remark'} {'('+ params.progress +'%)'}
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.5em'}}>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b>Agent Info</b></Header.Subheader>
                    {params.agentId || 'No Agent / Handler'}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>}
        
        <div id='wd_chat' className='chat' style={{margin: '.5em 0 0 0', padding: '.5em 0', overflowY: 'auto', height: screenHeight-350, border: '1px solid #ddd', borderRadius: '1em', background: '#fff'}}>
          <div style={{padding: '.2em 0em', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            {
              this.props.params && this.props.params.conversations.map((item, idx)=>{
                return(
                  <div key={idx} style={{margin: '.1em .3em', textAlign: item.sender=='cust' ? 'left' : 'right'}}>
                    <div style={{background: item.sender=='cust' ? '#ffebe9' : '#d7fffe', float: item.sender=='cust' ? 'left' : 'right', padding: item.sender=='cust' ? '.5em 1em .2em .5em' : '.5em .5em .2em 1em', margin: item.sender=='cust' ? '0 2em 0 0' : '0 0 0 2em', borderRadius: item.sender=='cust' ? '1em 2.5em 2.5em 0em' : '2.5em 1em 0em 2.5em'}}>
                      <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                        <Header.Content style={{color: '#444', fontSize: '11pt', overflowWrap: 'anywhere'}}>
                          <Header.Subheader style={{fontSize: '.8em', borderBottom: '1px solid rgba(100, 100, 100, .5)', marginBottom: '.5em'}}>
                            ({idx+1}) <b style={{color: '#900'}}>{item.stamp && format(new Date(item.stamp), 'HH:mm')}</b> {item.stamp && format(new Date(item.stamp), ', dd/MMM-yy')} <br />
                            {item.sender!='agent' ? ((this.props.params.target && this.props.params.target.name) || (this.props.params.customer && this.props.params.customer.name) || (this.props.params.contact && this.props.params.contact.name) || (this.props.params.visitor && this.props.params.visitor.name) || 'Guest') : ((this.props.params.agent && this.props.params.agent.name) || 'Agent')}
                            @{item.sender!='agent' ? this.props.params.terminating : this.props.params.originating}
                          </Header.Subheader>
                          {item.type=='image' && <div style={{width: 120, minHeight: 40, padding: '.3em', background: '#ccc', margin: '.5em 0', cursor: 'pointer'}}>
                            <Image src={imagePath + item.media} onClick={this.props.openModal.bind(this, 'image', {urlImage: imagePath + item.media})}/>
                          </div>} 
                            {item.messageCleaned && <Popup position= 'right center' inverted content={item.message} trigger= {<p dangerouslySetInnerHTML={{__html: (item.messageCleaned || item.message || '').replace(/\n/gm,'<div></div>')}} />} />}
                            {!item.messageCleaned && <p dangerouslySetInnerHTML={{__html: item.message && item.message.replace(/\n/gm,'<div></div>')}} />}
                          {item.sender=='agent' &&
                          <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}>
                            <Icon 
                            name={(this.props.params.status<0 && 'times') || (this.props.params.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'eye') || (this.props.params.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || (this.props.params.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || 'ellipsis horizontal'} 
                            color={(this.props.params.status<0 && 'red') || (this.props.params.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'brown') || (this.props.params.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'blue') || (this.props.params.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'grey') || 'black'}
                            style={{margin: '0', padding: '0'}}/>
                            </Header.Subheader>}
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                )
              })
            }
            <div style={{flex: '1', textAlign: 'right'}} />
          </div>
        </div>
            
        {token.groupId=='Controller' && <Segment>
          <Header as='h4'>
            <Icon name='star' />
            <Header.Content>
              Conversation Scoring
              <Header.Subheader>Better service for all of us.</Header.Subheader>
            </Header.Content>
          </Header>
            
          <div style={{display: 'flex'}}>
            <div style={{background: '#ddd', padding: '1em 1.5em'}}>
              <Label style={{padding: '.7em 2em', borderRadius: '3em', background: 'none'}}>
                <Rating maxRating={5} defaultRating={3} icon='star' size='huge' onRate={this.setRate.bind(this)} style={{fontSize: '2em'}} />
              </Label>
            </div>
            <div style={{flex: 1, background: '#f0f0f0', padding: '1em 1.5em'}}>
                <Header as='h2' style={{margin: '0', fontWeight: 'bold'}} color={(rating==1 && 'red') || (rating==2 && 'pink') || (rating==3 && 'brown') || (rating==4 && 'blue') || (rating==5 && 'violet') || 'brown'}>
                  <Header.Content>
                    <b>
                      {rating==1 && 'Worst !'}
                      {rating==2 && 'Bad'}
                      {(!rating || rating==3) && 'Good'}
                      {rating==4 && 'Great'}
                      {rating==5 && 'Excelent'}
                    </b>
                  </Header.Content>
                </Header>
            </div>
          </div>
          <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            <div style={{paddingTop: '.5em'}}>
                <Form.Group widths='16'>
                  <Field name='qaFeedback' component={TextAreaField}
                  rows={5}
                  width={16} 
                  placeholder= 'Custom feedback types here ...' />
                </Form.Group>
            </div>
            
            <Divider hidden/>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={'Update Conversation Scoring' + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Form>
        </Segment>}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: ({}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'ChatviewConversation',	// a unique identifier for this form
  })(ChatviewConversation)
  )
)