import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Image } from 'semantic-ui-react'

import ScriptBrowse from '../../libraries/whatsapp/ScriptBrowse'
import ScriptForm from '../../libraries/whatsapp/ScriptForm'
import ScriptSearch from '../../libraries/whatsapp/ScriptSearch'


class ImageBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        scriptId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, scriptId: id})
    }

    render() {
      const {mode, scriptId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <div style={{textAlign: 'center', padding: '3em', minHeight: screenHeight /2}}>
          <Image src={this.props.params.urlImage} wrapped style={{display: 'inline-block'}} />
        </div>
      )
    }
}

export default ImageBody
