import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
  Statistic,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import RadialRechart from '../../chart/RadialRechart';
import PieRechart from '../../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketCampaignMonitoring } from "../../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps= (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    socketCampaignMonitoring:(objParam) => dispatch(socketCampaignMonitoring(objParam)),
  }
}


class CampaignStatisticWidget extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        isUnique: false,
        stamp: '',
      };
    }
    
    componentDidUpdate() {
      const { data }= this.props.socketStore.socketCampaignMonitoring!=undefined ? this.props.socketStore.socketCampaignMonitoring: {}

      if (data && data.payload.data.stamp!=this.state.stamp) {
        this.setState({
          stamp: data.payload.data.stamp,
        })
      }
    }

    setUnique() {
      this.setState({
        isUnique: !this.state.isUnique,
      })
    }

    render() {
      const {dark, screenWidth, screenHeight}= this.props
      const {isUnique}= this.state
      const { data }= this.props.socketStore.socketCampaignMonitoring!=undefined ? this.props.socketStore.socketCampaignMonitoring: {}
      // console.log(data);

      // let item, pieAccuDelivery= [], pieUqDelivery= [], pieAccuResponse= [], pieUqResponse= [];
      
      // if (data && data.payload && data.payload.data && data.payload.data.campaign) {
      //   pieAccuDelivery= [
      //     { name: 'Target', value: item.totTarget },
      //     { name: 'Attempt', value: item.totAttempt }, 
      //     { name: 'Delivered', value: item.totDelivered },
      //     { name: 'Contacted', value: item.totEngaged },
      //     { name: 'Achieved', value: item.totAchieved },
      //   ];
      //   pieUqDelivery= [
      //     { name: 'Target', value: item.totTarget },
      //     { name: 'Attempt', value: item.uqAttempt }, 
      //     { name: 'Delivered', value: item.uqDelivered },
      //     { name: 'Contacted', value: item.uqEngaged },
      //     { name: 'Achieved', value: item.uqAchieved },
      //   ];
  
      //   pieAccuResponse= [
      //     { name: 'Paid', value: item.totPaid },
      //     { name: 'Promised', value: item.totPromised },
      //     { name: 'Reschedule', value: item.totRescheduled },
      //     { name: 'Verified', value: item.totVerified },
      //     { name: 'Ordered', value: item.totOrdered },
      //     { name: 'Interested', value: item.totInterested },
          
      //     { name: 'Reject', value: item.totReject },
      //     { name: 'Ignored', value: item.totIgnored }, 
      //     { name: 'Invalid', value: item.totInvalid }, 
      //     { name: 'Mailbox', value: item.totMailbox },
      //   ];
      //   pieUqResponse= [
      //     { name: 'Paid', value: item.uqPaid },
      //     { name: 'Promised', value: item.uqPromised },
      //     { name: 'Reschedule', value: item.uqRescheduled },
      //     { name: 'Verified', value: item.uqVerified },
      //     { name: 'Ordered', value: item.uqOrdered },
      //     { name: 'Interested', value: item.uqInterested },
  
      //     { name: 'Reject', value: item.uqReject },
      //     { name: 'Ignored', value: item.uqIgnored }, 
      //     { name: 'Invalid', value: item.uqInvalid }, 
      //     { name: 'Mailbox', value: item.uqMailbox },
      //   ];
      // }
      const item= (data && data.payload && data.payload.data && data.payload.data.campaign) || {};
        
      if (item) {
        return(<>
          <div style={{display: 'flex'}}>
            <div style={{width: 250, background: '#f8f8f8', minHeight: '100%'}}>
              <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: '100%'}}>
                <div style={{marginBottom: '1.5em'}}>
                  <Header as='h5'>
                    <b><i>Today!</i> Campaign</b>
                  </Header>
                </div>
                <div style={{flex: '1'}}>
                  <Header as='h2' style={{margin: '0'}}>
                    <Icon name='calendar alternate outline' style={{fontSize: '2em'}} color='teal' />
                    <Header.Content>
                      <b>{numeral(item.totCampaign || 0).format('0,0')}</b>
                      <Header.Subheader>
                        <b>Active Campaign</b>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{flex: '1'}}>
                  <Divider style={{margin: '2em 0'}} />
                  <Header as='h2' style={{margin: '0'}}>
                    <Icon name='user circle' style={{fontSize: '2em'}} color='yellow' />
                    <Header.Content>
                      <b>{numeral(item.totTarget || 0).format('0,0')}</b>
                      <Header.Subheader>
                        <b>Tot. Target</b>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
              </div>
            </div>
            <div style={{flex: 1, background: '#f0f0f0', minHeight: '100%', padding: '.5em 0'}}>
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>SENT</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='olive' size='medium' progress='percent' value={(isUnique ? item.uqAttempt : item.totAttempt) || 0} total={item.totTarget || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                </div>
              </div>
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>DELIVERED</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='olive' size='medium' progress='percent' value={item.totDelivered || 0} total={item.totTarget || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                </div>
              </div>
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>READ</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='olive' size='medium' progress='percent' value={item.totEngaged || 0} total={item.totTarget || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                </div>
              </div>
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>RESPONSED</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='olive' size='medium' progress='percent' value={item.totResponsed || 0} total={item.totTarget || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                </div>
              </div>

              <Divider hidden />
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>BLOCKED</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='olive' size='medium' progress='percent' value={item.totBlocked || 0} total={item.totTarget || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                </div>
              </div>
              <div style={{display: 'flex', margin: '.5em 1em'}}>
                <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                  <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>FAILED</b></Label>
                </div>
                <div style={{flex: '1'}}>
                  <Progress color='olive' size='medium' progress='percent' value={item.totFailed || 0} total={item.totTarget || 0} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                </div>
              </div>
            </div>
            <div style={{width: '11em', textAlign:'center', padding: '.5em 0'}}>
              <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                  <b>{!isUnique ? item.totTarget!=0 ? Math.ceil((item.totEngaged || 0)/(item.totTarget || 1) * 100) : 0 : item.totTarget!=0 ? Math.ceil((item.uqEngaged || 0)/(item.totTarget || 1) * 100) : 0}%</b>
                </Statistic.Value>
                <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>View Rate</Statistic.Label>
              </Statistic>
              <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                  <b>{!isUnique ? item.totTarget!=0 ? Math.ceil((item.totDelivered || 0)/(item.totTarget || 1) * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqDelivered/(item.totTarget || 1) * 100) : 0}%</b>
                </Statistic.Value>
                <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>Success Rate</Statistic.Label>
              </Statistic>
              {/* <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                  <b>{!isUnique ? item.totTarget!=0 ? Math.ceil(item.totAchieved/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqAchieved/item.totTarget * 100) : 0}%</b>
                </Statistic.Value>
                <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>Achieved Rate</Statistic.Label>
              </Statistic> */}
              <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                  <b>{!isUnique ? item.totTarget!=0 ? Math.ceil((item.totBlocked || 0)/(item.totTarget || 1) * 100) : 0 : item.totTarget!=0 ? Math.ceil((item.uqBlocked || 0)/(item.totTarget || 1) * 100) : 0}%</b>
                </Statistic.Value>
                <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>Blocking Rate</Statistic.Label>
              </Statistic>
            </div>
          </div>
        </>)
      } else {
        return(<div>
          
        </div>)
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignStatisticWidget)