import React, { Component } from 'react';
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Label } from 'semantic-ui-react';

import DismissableMessage from '../common/DismissableMessage';
import IconToggle from '../common/IconToggle';
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData';

import { SubmissionError, Field, reduxForm, getFormValues, formValueSelector } from 'redux-form';
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField } from '../validation/componentrsui';
import { required, phoneNumberID, number, minValue, email } from '../validation/validation';

import { connect } from "react-redux";

import { socketStatus, socketWhatsappStatistic, socketRequest, socketTransmit, socketWhatsappConnection } from "../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';


//CONNECT REDUX STORE
const selector = formValueSelector('QuickchatForm')

const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketRequest:(objParam) => dispatch(socketRequest(objParam)),
    socketTransmit:(objParam) => dispatch(socketTransmit(objParam)),
  }
}

class QuickchatForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      progress: null,
      code: '00',
      text: 'OK',
    }
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    const { mode } = this.props
  }

  resetForm() {
    const { reset } = this.props

    this.setState({
      progress: null,
      code: '00',
      text: 'OK',
    })
    reset()
  }

  onSubmit(values) {
    const socketStatus= (this.props.socketStore.socketStatus!==undefined ? this.props.socketStore.socketStatus.data : undefined)
    // console.log('!!! SUBMIT: ', values, socketStatus)
    
    if (socketStatus && socketStatus.isConnected) {
      this.props.socketRequest({
        event: 'send://whatsapp:outmessage', 
        payload: {
          messagingId: (this.props.params && this.props.params.messagingId) || this.state.messagingId,
          chat: {
            // stamp_: new Date(),
            stamp: new Date().getTime(),
            sender: 'agent',
            message: values.message,
          },
          data: {
            contactId: this.props.params && this.props.params.contactId,
            visitorId: this.props.params && this.props.params.visitor && this.props.params.visitor.visitorId,
            targetId: this.props.params && this.props.params.target && this.props.params.target.targetId,
            customerId: this.props.params && this.props.params.customer && this.props.params.customer.customerId,
          
            quickChat: 1,
            conversationStatus: 0, //null: no ticket, 0: ticket, 1: closed
          
            name: values.name,
            whatsapp: ((values.whatsapp && values.whatsapp[0]=='0') ? '62'+ values.whatsapp.substring(1) : values.whatsapp),

            messagingId: (this.props.params && this.props.params.messagingId) || this.state.messagingId,
            flow: 1,
            channelType: 'WA',
          }

          // targetId: 0,
          // visitorId: 0,
          // customerId: 0,
          // originating: '-',
          // terminating: '-',
        }, 
        socket: this.context.socket,
      })
      .then(response=>{
        // console.log('SOCKET REQUEST RESPONSE', response)
        if (response && response.value && response.value.data && response.value.data.messagingId && response.value.data.messagingId>0) {
          this.setState({
            messagingId: response.value.data.messagingId,
          })
        }

        this.setState({
          progress: 1,
          code: (response && response.value && response.value.code) ||  '00',
          text: (response && response.value && response.value.code=='00') ? 'OK, Quick chat submitted' : 'Error, contact already assigned to agent !'
        })
        if (response && response.value && response.value.code=='00') {
          setTimeout(()=>{
            this.props.closeModal();
          }, 2000)
        } else {
          setTimeout(()=>{
            this.setState({
              progress: null,
            })
          }, 5000)
        }
      })
      .catch(error=>{
        this.setState({
          progress: 2,
          code: '01',
          text: 'NOK, Connection error !'
        })
        setTimeout(()=>{
          this.setState({
            progress: null,
          })
        }, 2000)
        console.log('SOCKET REQUEST ERROR', error)
      });
    } else {
      this.setState({
        progress: 2,
        code: '01',
        text: 'NOK, Socket disconnected'
      })
      setTimeout(()=>{
        this.setState({
          progress: null,
        })
      }, 2000)
    }
  }

  render() {
    const { progress, code, text } = this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const waConnnections= (this.props.socketStore.socketWhatsappConnection!==undefined ? this.props.socketStore.socketWhatsappConnection.data.payload.data : undefined)
    
    const onlines= [], offlines= [];
    waConnnections && waConnnections.provider.mobiles && waConnnections.provider.mobiles.map(item=>{
      if (item.online=='1') {
        onlines.push(item.mobile)
      } else {
        offlines.push(item.mobile)
      }
    })
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Header as='h4'>
          <Icon name='plus circle' style={{fontSize: '1.8em'}} color='pink' />
          <Header.Content>
            <b>Quick Chat / Message</b>
            <Header.Subheader>Send message then open ticket for current customer.</Header.Subheader>
          </Header.Content>
        </Header>
        {onlines.length>0 && 
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Segment>
              <Form.Group widths='equal'>
                <Field name='message' component={TextAreaField}
                  width='16'
                  rows='8'
                  validate={[required]}
                  placeholder='Type message here ...'
                  label={<b>Type New Message !</b>} />
              </Form.Group>
              <Label.Group>
                <Label style={{fontWeight: '300'}}>_italic_ : <i>italic</i></Label>
                <Label style={{fontWeight: '300'}}>*bold*: <b>bold</b></Label>
                <Label style={{fontWeight: '300'}}>~strikethrough~: <span style={{textDecoration: 'line-through'}}>strikethrough</span></Label>
              </Label.Group>
            </Segment>
            <Segment>
              <Form.Group widths='16'>
                <Field name='whatsapp' component={InputField}
                width={8}
                label={<b>Whatsapp Number</b>}
                validate={[required, phoneNumberID]}
                placeholder='Whatsapp Number' />
                <Field name='name' component={InputField}
                width={8}
                label={<b>Customer Name</b>}
                validate={[required]}
                placeholder='Customer Name' />
              </Form.Group>
            </Segment>

            <Segment>
              {/* <Divider hidden/> */}
              {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={'Send Quick Chat' + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              
              <div style={{textAlign: 'right'}}>
                <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
              </div>
            </Segment>
          </Form>
        }

        {onlines.length<=0 && <div style={{background: '#c31', margin: '3em 0 0 0', padding: '3em 2em 2em 2em', borderRadius: '.5em'}}>
          <Header as='h2' inverted icon>
            <Icon name='warning sign' style={{fontSize: '1.5em'}}/>
            <Header.Content>
              <b>NO ACCOUNT</b>
              <Divider />
              <Header.Subheader style={{fontSize: '.65em', margin: '.5em 0'}}>
                Please, <br />start  Whatsapp Account connection service, to initiate two ways interactive conversations.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </div>}
      </div>
    )
  }
}

QuickchatForm.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: ({}),
      formValues: getFormValues('QuickchatForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'QuickchatForm',	// a unique identifier for this form
  })(QuickchatForm)
  )
)
