import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"
import qrcode from 'qrcode';

import ModalContainer from '../common/ModalContainer';

import { connect } from "react-redux"
import { browseWaserver, qrcodeWaserver, commandWaserver, resetWaserver } from "../../actions/whatsapp/waserverAction"

var qrcodeInterval= null;

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    waserverStore: state.waserverStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetWaserver:(objParam) => dispatch(resetWaserver(objParam)),
    browseWaserver:(objParam) => dispatch(browseWaserver(objParam)),

    qrcodeWaserver:(objParam, urlCustom) => dispatch(qrcodeWaserver(objParam, urlCustom)),
    commandWaserver:(objParam, urlCustom) => dispatch(commandWaserver(objParam, urlCustom)),
  }
};


class WaserverBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
    this.props.browseWaserver(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
    this.props.browseWaserver({search: {...search}, paging: paging})
  }

  componentWillUnmount() {
    if (qrcodeInterval) {
      clearInterval(qrcodeInterval);
    } 
  }

  componentDidUpdate(prevProps, prevState) {
    const { data }= (this.props.waserverStore.qrcodeWaserver!==undefined ? this.props.waserverStore.qrcodeWaserver : {})

    if (
      data && (
      // this.state.qrcodeStamp!=data.qrcodeStamp || 
      this.state.qrcodeData!=data.qrcodeData 
      || this.state.authenticateStamp!=data.authenticateStamp
      )) {
      const parent= this;
      qrcode.toDataURL(data.qrcodeImage, function (err, url) {
        // console.log('QRCODE', data.qrcodeImage)
        // console.log('IMAGE', url);
        parent.setState({
          authenticateStamp: data.authenticateStamp,

          qrcodeStamp: data.qrcodeStamp,
          qrcodeData: data.qrcodeData,
          qrcodeImage: url,
        })
      })
    }
    
    if (this.state.modal) {
      // if (data && data.authenticateStamp) {
      //   setTimeout(()=>{
      //     this.setState({
      //       modal: null
      //     })
      //   }, 10000)
        
      //   this.props.resetWaserver();
      // } else {
        if (!prevState.modal || prevState.modal!=this.state.modal) {
          if (qrcodeInterval) {
            clearInterval(qrcodeInterval);
          }
          this.props.qrcodeWaserver({
            ...this.state.params
          })
          qrcodeInterval= setInterval(()=>{
            this.props.qrcodeWaserver({
              ...this.state.params
            })
          }, 10000)
        }
      // }
    } else {
      if (qrcodeInterval) {
        clearInterval(qrcodeInterval);
      }
    }
  }
  
  openModal(value, params) {
    // console.log(params)
    this.setState({
      modal: value,
      params: params,
    })
  }

  closeModal(params) {
    // console.log(params)
    this.setState({
      modal: null,
      params: params,
    })

    setTimeout(()=>{
      const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
      this.props.browseWaserver({search: {...search}, paging: paging})
    }, 3000)
  }
  
  commandWaserver(cmd, params) {
    this.setState({
      disabled: params.waserverId,
    })
    this.setState({
      loading: params.waserverId,
    })
    setTimeout(()=>{
      this.setState({
        disabled: null,
        loading: null,
      })
    }, cmd!='stop' ? 30000 : 10000)

    this.props.commandWaserver({
      ...params,
      commandType: cmd,
      url:  params.container +'/admin/api/v1/whatsapp/command',
    })

    setTimeout(()=>{
      const { search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
      this.props.browseWaserver({search: {...search}, paging: paging})
    }, cmd!='stop' ? 31000 : 11000)
  }

  viewQRCode() {
    return(
      <div style={{background: '#fff', padding: '4em 0', textAlign: 'center'}}>
        {/* {'QRCODE: '+ this.state.qrcodeImage} */}
        <div style={{
        background: (!this.state.qrcodeImage && !this.state.authenticateStamp) ? '#000' : '#eee', 
        // background: '#eee url("'+ (this.state.params.container +'/static/'+ (data && data.qrcodeImage)) +'") no-repeat fixed center',
        height: '264px', width: '264px', margin: '2em auto 4em auto'}}>
          
          {this.state.qrcodeImage && <Image src={this.state.qrcodeImage} />}

          {this.state.qrcodeImage==null && !this.state.authenticateStamp && <Header as='h2' inverted style={{paddingTop: '30%'}}>
            <Header.Content>
              NO<br />QR CODE!
            </Header.Content>
          </Header>}
          {this.state.qrcodeImage==null && this.state.authenticateStamp && <Header as='h2' color='blue' style={{paddingTop: '30%'}}>
            <Header.Content>
              YOU ARE <br /><b>CONNECTED</b>
            </Header.Content>
          </Header>}
        </div>
        {/* {this.state.qrcodeData} */}
        <Header as='h3' color='blue'>
          <Header.Content>
            SCANNING QR CODE HERE!
            <Header.Subheader>Whatsapp verification and authentication process.</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    )
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.waserverStore.browseWaserver!==undefined ? this.props.waserverStore.browseWaserver : {})
    const { progress }= (this.props.waserverStore.commandWaserver!==undefined ? this.props.waserverStore.commandWaserver : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    WA ACCOUNT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>
                    SERVICE
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>
                    AUTHED
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={5} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.waserverId)}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Icon loading={this.state.disabled==item.waserverId} name='chevron circle up' style={{fontSize: '1.3em'}}
                          color={(item.isQRCode && 'orange') || (item.isOnline && 'blue') || (item.isConnected && 'teal') || (item.isRunning && 'yellow') || 'grey'} />
                          <Header.Content style={{paddingLeft: '.5em', textDecoration: item.isEnabled=='0' || item.isSuspended=='1' ? 'line-through' : 'none'}}>
                            {/* <Header.Subheader>{item.container} / {item.waserverId} 
                            Status: <b>{item.statuscheckResult}</b>
                            </Header.Subheader> */}
                            <p style={{margin: '.05em 0', fontWeight: 'bold'}}>
                              {/* {item.account.substring(0,5).replace('62', '0') +' (x) '+ item.account.substring(item.account.length-4, item.account.length)} */}
                              {item.account.replace('62', '0')}_{item.waserverId}@{item.container}
                            </p>
                            <Header.Subheader>Created: <b>{item.createStamp && format(parse(item.createStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm, dd/MMM yyyy')}</b></Header.Subheader>
                          </Header.Content>
                        </Header>
                        {/* <div style={{paddingLeft: '3em'}}>
                          <Label style={{borderRadius: '1em', color: '#333'}}>
                            <Icon name={(item.ownerId && 'user circle') || 'circle outline'} /> {((item.streams && item.streams.join (', ')) || 'All Direction').toUpperCase()}
                          </Label>
                        </div> */}
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0'}}>
                          <Icon name={(item.isQRCode && 'qrcode') || (item.isOnline && 'check') || (item.isConnected && 'plug') || (item.isRunning && 'sync') || 'times'}
                          color={(item.isQRCode && 'orange') || (item.isOnline && 'blue') || (item.isConnected && 'teal') || (item.isRunning && 'yellow') || 'grey'} />
                          <Header.Content>
                            {(item.isQRCode && 'Scanning QRCode') || (item.isOnline && 'ONLINE/READY') || (item.isConnected && 'Connected') || (item.isRunning && 'Running') || 'STOP'}
                            <Header.Subheader><b>{item.status<0 ? 'NOK' : (item.status=='0' ? 'Progress' : 'OK')}</b> 
                            / {(item.statuscheckStamp && format(parse(item.statuscheckStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss, dd/MMM yyyy'))}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0'}}>
                          <Icon name={(item.isAuthenticated && 'qrcode') || 'qrcode'} color={item.isAuthenticated ? 'blue' : 'grey'}/>
                          <Header.Content>
                            {(item.isAuthenticated && 'Authed') || 'Not Authed'}
                            <Header.Subheader><b>{item.authenticateResult || '-'}</b> / {item.authenticateStamp && format(parse(item.authenticateStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss, dd/MMM yyyy')}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Popup content='Create and start Whatsapp Server as service. (Wait next 30sec to complete)' position= 'left center' inverted
                        trigger={
                          <Icon style={{fontSize: '1.5em', cursor: 'pointer'}} color='teal'  name='play circle' disabled={this.state.disabled==item.waserverId || item.isRunning==1 || item.isEnabled=='0' || item.isSuspended=='1' || false} 
                          onClick={this.commandWaserver.bind(this, 'start', {waserverId: item.waserverId, account: item.account, container: item.container})}
                          />
                        } />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup content='Scan QR Code for Authenticated Whatsapp Server.' position= 'left center' inverted
                        trigger={
                          <Icon style={{fontSize: '1.5em', cursor: 'pointer'}} color='orange' name='qrcode' disabled={this.state.disabled==item.waserverId || item.isQRCode==0 || item.isEnabled=='0' || item.isSuspended=='1' || false} 
                          onClick={this.openModal.bind(this, 'qrcode', {serverId: item.waserverId, account: item.account, container: item.container})} />
                        } />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup content='Stop and terminate Whatsapp Server. (Wait next 10sec to complete.)' position= 'left center' inverted
                        trigger={
                          <Icon style={{fontSize: '1.5em', cursor: 'pointer'}} color='black' name='stop circle' disabled={this.state.disabled==item.waserverId || (item.isRunning==0 && item.isQRCode==0) || item.isEnabled=='0' || item.isSuspended=='1' || false} 
                          onClick={this.commandWaserver.bind(this, 'stop', {waserverId: item.waserverId, account: item.account, container: item.container})} />
                        } />
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }

        {this.state.modal && <ModalContainer size='small' content={this.viewQRCode()} closeModal={this.closeModal.bind(this)} /> }
      
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaserverBrowse)
