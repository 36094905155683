import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Progress, Statistic, Label } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';
import { socketStatus, socketWhatsappStatistic } from "../../../actions/socket/socketAction";

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketWhatsappStatistic:(objParam) => dispatch(socketWhatsappStatistic(objParam)),
  }
}

class CampaignWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
    };
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const {summaryCampaign}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})

    var totTarget= 0, totAttempt= 0, totCampaign= 0;;
    summaryCampaign && summaryCampaign.map((item, i)=>{
      totCampaign++;
      totTarget+= item.totTarget;
      totAttempt+= item.totAttempt;
    })
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1'}}>
            <Header as='h5' style={{margin: '0'}}>
              <Icon name='fire' style={{fontSize: '1em'}} />
              <Header.Content>
                Campaign Summary
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div>
            <Popup  content='Create new campaign through step by step wizard.' position='left center'
              trigger={
                <Icon name='plus' onClick={this.props.openModal.bind(this, 'wizard')}  style={{fontSize: '1.2em', cursor: 'pointer'}} />
              }
              inverted
              on={['hover']}
            />
          </div>
        </div>
        
      </Segment>
      <Segment attached='bottom'>
        <div style={{display: 'flex'}}>
          <div style={{textAlign: 'center', paddingRight: '.1em'}}>
            {/* <Header as='h4' style={{margin: '0'}} color='blue'>
              <Header.Content style={{fontWeight: 'bold', fontSize: '1.5em'}}>
                0
                <Header.Subheader>Campaigns</Header.Subheader>
              </Header.Content>
            </Header> */}
            <Label as='a' color='green' size='large'>
              <Icon name='calendar alternate outline' />
              <Label.Detail>{totCampaign}</Label.Detail>
            </Label>
          </div>
          <div style={{flex: '1'}}>
            <div style={{padding: '.16em .2em.16em .2em', background: '#6ac15b', borderRadius: '.2em'}}>
              <Progress  size='medium' indicating color='olive' percent={Math.round(totAttempt/totTarget * 100)} progress='percent' />
            </div>
          </div>
        </div>
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignWidget)