import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'


import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';

import WhatsappHeader from "./WhatsappHeader"
import WhatsappFooter from "./WhatsappFooter"
import WhatsappBody from "./WhatsappBody"

class Whatsapp extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        screenHeight: 800,
        screenWidth: 1024,
        mode: 'interaction',
        modal: '',
        module: 'inbound', // inbound, outbound, hybrid,

        openDrawer: true,
      }
      this.updateDimension = this.updateDimension.bind(this)
    }
  
    componentDidMount() {
      this.updateDimension()
      window.addEventListener('resize', this.updateDimension)
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension);
      this.context.socket && this.context.socket.close();
    }
    
    updateDimension() {
      this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
    }
  
    openModal(value, params) {
      // console.log(params)
      this.setState({
        modal: value,
        params: params,
      })
    }

    closeModal(params) {
      // console.log(params)
      this.setState({
        modal: null,
        params: params,
      })
    }

    openWindow(mode) {
      this.setState({
        modal: null,
        mode: mode,
        // openDrawer: true
      })
    }

    setModule(module) {
      this.setState({
        module: module,
      })
    }
    
    openDrawer() {
      const { openDrawer }= this.state
      this.setState({
        openDrawer: !openDrawer
      })
    }  

    render() {
      const {screenHeight, screenWidth, mode, module, openDrawer, modal, params}= this.state
      
      return(
        <Container fluid>
          {mode!='authentication' && <WhatsappHeader screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} openModal={this.openModal.bind(this)} mode={mode} openDrawer={this.openDrawer.bind(this)} mini={openDrawer} closeModal={this.closeModal.bind(this)} modal={modal}  params={params} />}
          <WhatsappBody screenHeight={screenHeight} screenWidth={screenWidth} mode={mode} mini={openDrawer} module={module} setModule={this.setModule.bind(this)} openWindow={this.openWindow.bind(this)} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} modal={modal}  params={params} />
          <WhatsappFooter screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.openWindow.bind(this)} />
        </Container>
      )
    }
} 

Whatsapp.contextType= WebSocketContext;
export default Whatsapp
