import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ReportCampaignBrowse from '../../libraries/whatsapp/ReportCampaignBrowse'
import ReportCampaignSearch from '../../libraries/whatsapp/ReportCampaignSearch'


class ReportCampaignBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        messagingId: 0,
      };
    }

    setMode(mode, id, campaignId, clientId) {
      this.setState({mode: mode, messagingId: id, campaignId: campaignId, clientId: clientId})
    }

    render() {
      const {mode, messagingId, campaignId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <ReportCampaignSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} params={this.props.params} />
            <div>
              {mode==1 && <ReportCampaignBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} params={this.props.params} />}
            </div>
        </Container>
      )
    }
}

export default ReportCampaignBody
