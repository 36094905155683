import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { connect } from "react-redux"
import { perfCampaign } from "../../actions/halo/performanceAction"
import RadialRechart from '../chart/RadialRechart';
import PieRechart from '../chart/PieRechart';
import PerformanceCampaignSearch from './PerformanceCampaignSearch';
import CampaignSummary from '../widget/whatsapp/CampaignSummary';


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    performanceStore: state.performanceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfCampaign:(objParam) => dispatch(perfCampaign(objParam)),
  }
};

class PerformanceCampaign extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }
  
    doPaging(e, page) {
      const { search, paging }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
      this.props.perfCampaign(
        {
          paging: {...paging, page: page.activePage},
          search: search
        }
      )
    }
    
    componentDidMount() {
      const { search, paging }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
      this.props.perfCampaign({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search}, paging: {page: 1, ...paging, size: 2}})
    }

    render() {
      const {screenWidth, screenHeight, mode}= this.props
      const { data, search, paging }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
      let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
      
      if (mode==2) {
        return(<>
          <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
            <PerformanceCampaignSearch setMode={this.props.setMode} mode={mode} />
            <div style={{flex: '1'}}>
              { data!=undefined && data.length==0 &&<Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>}
              <Item.Group>
                { 
                  data!=undefined ? data.map((item,key)=>{
                    return(
                      <CampaignSummary seq={++seq} key={key} item={item} screenWidth={screenWidth} />
                    )
                  }) : <Segment><Icon name='circle notch' loading />loading ...</Segment>
                }
              </Item.Group>
            </div>
            {paging!=undefined && paging.count>0 &&
              <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
                <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                  defaultActivePage={paging.page}
                  totalPages={Math.ceil(paging.count/paging.size)}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                  lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                />
              </div>
            }
          </div>
          </>
        )
      } else {
        return(<>
          <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
            <PerformanceCampaignSearch setMode={this.props.setMode} mode={mode} />
            <div style={{flex: '1'}}>
              <Table unstackable selectable columns='16' celled striped basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>CAMPAIGN</Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='3'>
                      <Header as='h5' color='blue'>DELIVERY</Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='5'>
                      <Header as='h5' color='blue'>RESOURCES</Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='4'>
                      <Header as='h5' color='blue'>COMPLETENESS (%)</Header>
                    </Table.HeaderCell>
                    {/* <Table.HeaderCell width='1'>
                      
                    </Table.HeaderCell> */}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                  { data!=undefined ? data.map((item,key)=>{
                    return( 
                      <Table.Row key={++seq}>
                        <Table.Cell rowSpan={1}>{seq}</Table.Cell>
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                          <Popup inverted content='Campaign Information' trigger={
                            <Icon name='edit' onClick={this.props.openModal.bind(this, 'impression', item)} style={{cursor: 'pointer', fontSize: '1.1em'}}/>
                          } />
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' style={{margin: '0'}}>
                            {/* <Icon name={item.isEnabled=='1'? 'calendar check outline' : 'calendar times outline'} color={item.isEnabled=='1'? 'blue' : 'red'} /> */}
                            <Icon 
                              color={(item.isEnabled=='0' && 'grey') || (item.isExpired=='-1' && 'yellow') || (item.isExpired=='1' && 'red') || 'teal'} 
                              name='circle thin' 
                              style={{fontSize: '1.1em'}} />
                            <Header.Content>
                              <Header.Subheader><b>{item.campaignType}</b> {item.dialingMode && <b>{' - ' + item.dialingMode +' Mode'}</b>} <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>) || ''}</b></Header.Subheader>
                              <b>{item.name}</b>
                            </Header.Content>
                          </Header>
                          <Header as='h5' style={{margin: '.5em 0 0 0'}}>
                            <Header.Content>
                              <Header.Subheader>Broadcast Content</Header.Subheader>
                                {((item.scriptExt && item.scriptExt.length>50)? item.scriptExt.substring(0, 50) +' ...' : item.scriptExt) || ''}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' style={{margin: '0'}}>
                            <Header.Content>
                              <Header.Subheader>{item.startTime} - {item.endTime} / {item.channels && <b>{item.channels.join('+')}</b>}</Header.Subheader>
                                {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd/MMM') : ''} - 
                                {item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy') : ''}
                            </Header.Content>
                          </Header>
                          <Header as='h5' style={{margin: '.5em 0 0 0'}}>
                            <Header.Content style={{fontWeight: 'bold'}}>
                              <Header.Subheader>Devices / Gateways</Header.Subheader>
                              {(item.gateways && Array.isArray(item.gateways) && item.gateways.join(', ')) || 'All Devices'}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' color='blue' style={{margin: '0 0 .5em 0'}}>
                            <Header.Content>
                              <Header.Subheader>Supervisor</Header.Subheader>
                              {item.owner}
                            </Header.Content>
                          </Header>
                          <Header as='h5' color='blue' style={{margin: '0'}}>
                            <Header.Content style={{fontWeight: 'bold'}}>
                              <Header.Subheader style={{fontSize: '.9em'}}>Assigned Workgroup</Header.Subheader>
                              {(item.workgroups && item.workgroups.join(', ')) || 'Not Defined'}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Progress indicating size='medium' percent={item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress><b style={{fontSize: '.9em'}}>{item.client || 'posted by Unknown'}</b></Progress>
                          <Grid columns={16} celled style={{margin: '-2em -.5em 0 0'}}>
                            <Grid.Row>
                              <Grid.Column width={8} style={{padding: '0'}}>
                                <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                  {item.totTarget>1000 ? numeral(item.totTarget).format('0.00a') : numeral(item.totTarget).format('0,0')}
                                  <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Trgt</Label.Detail>
                                </Label>
                              </Grid.Column>
                              <Grid.Column width={8} style={{padding: '0'}}>
                                <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                  {item.totAttempt>1000 ? numeral(item.totAttempt).format('0.00a') : numeral(item.totAttempt).format('0,0')}
                                  <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Sent</Label.Detail>
                                </Label>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width={8} style={{padding: '0'}}>
                                <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                  {item.totDelivered>1000 ? numeral(item.totDelivered).format('0.00a') : numeral(item.totDelivered).format('0,0')}
                                  <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Dlvrd</Label.Detail>
                                </Label>
                              </Grid.Column>
                              <Grid.Column width={8} style={{padding: '0'}}>
                                <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                  {item.totEngaged>1000 ? numeral(item.totEngaged).format('0.00a') : numeral(item.totEngaged).format('0,0')}
                                  <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Read</Label.Detail>
                                </Label>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Table.Cell>
                        {/* <Table.Cell style={{whiteSpace: 'nowrap'}}>
                          <Header as='h5' color='blue' style={{cursor: 'pointer'}}>
                            <Label style={{margin: '.2em .5em 0 0'}} color='red'>1</Label>
                            <Header.Content>
                              <Rating defaultRating={3} maxRating={5} />
                              <Header.Subheader>Bad, Fine, Good, Great, Excellent</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell> */}
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
                </Table.Body>
              </Table>
              
              {/* {this.state.campaignId && this.state.showTarget && 
              <ModalContainer size='fullscreen' open={this.state.showTarget}  content={<TargetBody screenHeight={screenHeight} campaignId={this.state.campaignId}  campaign={this.state.campaign}  clientId={this.state.clientId} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.onCloseModal.bind(this)} />
              } */}
            </div>
            {paging!=undefined && paging.count>0 &&
              <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
                <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                  defaultActivePage={paging.page}
                  totalPages={Math.ceil(paging.count/paging.size)}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                  lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                />
              </div>
            }
          </div>
        </>)
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceCampaign)
