import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import MessagingInboundRecent from '../../libraries/whatsapp/MessagingInboundRecent';
import MessagingOutboundRecent from '../../libraries/whatsapp/MessagingOutboundRecent';
import BillingWidget from '../../libraries/widget/whatsapp/BillingWidget';
import CampaignWidget from '../../libraries/widget/whatsapp/CampaignWidget';
import ImpressionWidget from '../../libraries/widget/whatsapp/ImpressionWidget';
import MessagingWidget from '../../libraries/widget/whatsapp/MessagingWidget';
import TrafficWidget from '../../libraries/widget/whatsapp/TrafficWidget';
import CampaignBody from './CampaignBody';


class BroadcastBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', background: '#f8f8f8', padding: '2em 1em 2em 1.5em'}}>
              <TrafficWidget mode='tiny' height={375} />
            </div>
            <div style={{width: 450, background: '#f5f5f5', padding: '2em 1.5em 2em 1em'}}>
              <ImpressionWidget height={375} />
            </div>
          </div>

          <div style={{background: '#fcfcfc', padding: '2em 1.5em'}}>
            <CampaignBody mode={1} openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
          </div>
        </Container>
      )
    }
}

export default BroadcastBody
