import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
  Checkbox
} from 'semantic-ui-react'

import LineChart from '../chart/LineRechart';
import { parse, format, addSeconds } from "date-fns"


import { connect } from "react-redux"

import { browseSyslog } from "../../actions/halo/syslogAction"
import { perfAgent } from "../../actions/halo/performanceAction"

import ModalContainer from '../../libraries/common/ModalContainer';
import PerformanceAgentSearch from './PerformanceAgentSearch';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    syslogStore: state.syslogStore,
    performanceStore: state.performanceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseSyslog:(objParam) => dispatch(browseSyslog(objParam)),
    
    perfAgent:(objParam) => dispatch(perfAgent(objParam)),
  }
};

class PerformanceAgent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
      isShown: false,
      
      viewSimple: false,

      logSession: true,
      logLocking: true,
      logDispatch: false,
    };
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})
    this.props.perfAgent(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})
    this.props.perfAgent({search: {date: format(new Date(), 'yyyy-MM-dd'), period: format(new Date(), 'yyyyMM'), view: 'Today', ...search, groupId: 'Client'}, paging: paging})
  }
  
  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    // return format(date, 'HH:mm:ss');
    return format(date, 'HH:mm');
  }

  switchModal(item) {
    const dateOptions= []
    const { isShown }= this.state
    // console.log(item.period) //202101

    if (item.statTimers) {
      const dates= [];
      var startDate = parse(item.period + '01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
      var endDate= parse(item.period + '01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
      endDate.setMonth(endDate.getMonth()+1)
      
      while (startDate<endDate) {
        dates.push(format(startDate, 'dd'))
        dateOptions.push({
          key: format(startDate, 'dd'),
          value: format(startDate, 'yyyy-MM-dd'),
          text: format(startDate, 'dd/MMM, yyyy'),
        })

        startDate.setDate(startDate.getDate() + 1)
      }
      // console.log(startDate, endDate, dates)
      const chartLegend = [
        {id: 'totSignin', name: 'Signin'}, 
        {id: 'totEngaged', name: 'Contacted'}, 
        {id: 'totAchieved', name: 'Achieved'}, 
        {id: 'totLocked', name: 'Locked'}, 
      ]
      const chartData= [];
      dates.map(date=>{
        return(
          chartData.push({
            name: date,
            totSignin: (item.statPerformances[date.substring(0,2)] && item.statPerformances[date.substring(0,2)].totSignin) || 0,
            totEngaged: (item.statPerformances[date.substring(0,2)] && item.statPerformances[date.substring(0,2)].totEngaged) || 0,
            totAchieved: (item.statPerformances[date.substring(0,2)] && item.statPerformances[date.substring(0,2)].totAchieved) || 0,
            totLocked: (item.statPerformances[date.substring(0,2)] && item.statPerformances[date.substring(0,2)].totLocked) || 0,
          })
        )
      })
      item['chart']= {
        data: chartData,
        legend: chartLegend,
      }
    }

    //SHOWN AND GET DETAIL SYSLOGS
    this.props.browseSyslog({search: {userId: item.agentId, period: item.period ? format(new Date(parse(item.period + format(new Date(), 'dd'), 'yyyyMMdd', new Date())), 'yyyy-MM-dd') : null}});
    this.setState({
      dateActivity: item.period ? format(new Date(parse(item.period + format(new Date(), 'dd'), 'yyyyMMdd', new Date())), 'yyyy-MM-dd') : null,
      isShown: !isShown,
      item: item,
      dateOptions: dateOptions,
    })
  }

  simpleView(item) {
    const actions= [];

    item && item.activities.map(log=>{
      if (log.action.match(/signing/i)) { //signing action
        if (log.action.match(/ in/i)) {
          actions.map(item=>{
            if (item.action.match(/signing/i) && !item.stampOut) {
              item.stampOut= item.stampIn;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
            }
          })
          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Out',
            reason: log.reason,
          })
        } else if (log.action.match(/ out/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/signing/i) && !item.stampOut && log.stamp>=item.stampIn) {
              item.stampOut= log.stamp;
              item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      } else if (log.action.match(/session/i)) {
        if (log.action.match(/start/i)) {
          actions.map(item=>{
            if (item.action.match(/session/i) && !item.stampOut) {
              item.stampOut= item.stampIn;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
            }
          })
          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Exiting',
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/session/i) && !item.stampOut && log.stamp>item.stampIn) {
              item.stampOut= log.stamp;
              item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      } else if (log.action.match(/locked/i)) {
        if (log.action.match(/enter/i)) {
          actions.map(item=>{
            if (item.action.match(/locked/i) && !item.stampOut) {
              item.stampOut= item.stampIn;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
            }
          })

          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Exiting',
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/locked/i) && !item.stampOut && log.stamp>=item.stampIn) {
              item.stampOut= log.stamp;
              item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      } else if (log.action.match(/dispatch/i)) {
        if (log.action.match(/start/i)) {
          actions.map(item=>{
            if (item.action.match(/dispatch/i) && !item.stampOut) {
              item.stampOut= item.stampIn;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
            }
          })
          
          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Exiting',
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/dispatch/i) && !item.stampOut && log.stamp>=item.stampIn) {
              item.stampOut= log.stamp;
              // item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      }
    })

    return(actions);
  }

  calculateDuration(item) {
    const actions= [];
    
    item && item.activities.map(log=>{
      if (log.action.match(/signing/i)) { //signing action
        if (log.action.match(/ in/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
          })
        } else if (log.action.match(/ out/i)) {
          let found= false;
          let stampIn;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/signing/i) && item.action.match(/ in/i)) {
              stampIn= item.stamp;
            }
            if (!found && item.action.match(/signing/i) && item.action.match(/ out/i) && item.duration==undefined && stampIn<=item.stamp) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date())-parse(stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            } else if (!found && item.action.match(/signing/i) && item.action.match(/ out/i) && item.duration!=undefined) {
              stampIn= null;
            }
          })
        }
      } else if (log.action.match(/session/i)) {
        if (log.action.match(/starting/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
          })
        } else if (log.action.match(/exiting/i)) {
          let found= false;
          let stampIn;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/session/i) && item.action.match(/starting/i)) {
              stampIn= item.stamp;
            }
            if (!found && item.action.match(/session/i) && item.action.match(/exiting/i) && item.duration==undefined && stampIn<=item.stamp) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date()) - parse(stampIn, 'HH:mm:ss', new Date())) / 1000);
              found= true;
            } else if (!found && item.action.match(/session/i) && item.action.match(/exiting/i) && item.duration!=undefined!=undefined) {
              stampIn= null;
            }
          })
        }
      } 
      else if (log.action.match(/locked/i)) {
        if (log.action.match(/enter/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          let stampIn, reason;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/locked/i) && item.action.match(/enter/i)) {
              stampIn= item.stamp;
              reason= item.reason;
            }
            if (!found && item.action.match(/locked/i) && item.action.match(/exit/i) && item.duration==undefined && stampIn<=item.stamp) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date())-parse(stampIn, 'HH:mm:ss', new Date()))/1000);
              item.reason= reason;
              found= true;
            } else if (!found && item.action.match(/locked/i) && item.action.match(/exit/i) && item.duration!=undefined) {
              stampIn= null;
            }
          })
        }
      } else if (log.action.match(/dispatch/i)) {
        if (log.action.match(/start/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          let stampIn;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/dispatch/i) && item.action.match(/start/i)) {
              stampIn= item.stamp;
            }
            if (!found && item.action.match(/dispatch/i) && item.action.match(/exit/i) && item.duration==undefined && stampIn<=item.stamp) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date())-parse(stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            } else if (!found && item.action.match(/dispatch/i) && item.action.match(/exit/i) && item.duration!=undefined) {
              stampIn= null;
            }
          })
        }
      }
    })

    return(actions);
  }

  onCheckbox(k, e, v) {
    const state= {};
    state[k]= v.checked;

    this.setState({
      ...state,
    })
  }

  onChangeSelect(e, v) {
    this.state.item && this.props.browseSyslog({search: {userId: this.state.item.agentId, period: v.value}});

    this.setState({
      dateActivity: v.value
    })
  }

  contentModal() {
    const { isShown, item, dateOptions, dateActivity, viewSimple, logSession, logLocking, logDispatch }= this.state;
    const { data }= (this.props.syslogStore.browseSyslog!==undefined ? this.props.syslogStore.browseSyslog : {})

    const regex= (logSession || logLocking || logDispatch) ? new RegExp(((logSession && 'signin|session|') || 'xyz|') + ((logLocking && 'locked|') || 'xyz|') + ((logDispatch && 'dispatch') || 'xyz'), 'i') : new RegExp(/ /, 'i');

    let dataExts;

    let seq= 0;

    if (viewSimple && data && data.length>0) {
      dataExts= this.simpleView(data[0]);
    } else if (data && data.length>0) {
      dataExts= this.calculateDuration(data[0]);
    }
    
    if (isShown) {
      return(
        <div style={{padding: '1em'}}>
          <div style={{display: 'flex'}}>
            {item.statTimers && 
            <div style={{paddingRight: '2em', borderRight: '1px solid #666'}}>
              <Header as='h3' color='red'>
                <Header.Content>
                  {format(new Date(parse(item.period + format(new Date(), 'dd'), 'yyyyMMdd', new Date())), 'dd/MMM, yyyy')}
                  <Header.Subheader>To Date Performance</Header.Subheader>
                </Header.Content>
              </Header>
            </div>}
            <div style={{flex: '1', paddingLeft: '2em'}}>
              <Header as='h3'>
                <Header.Content>
                  {item && item.name}
                  <Header.Subheader>{item && item.client}</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
          <Divider />
          <Segment>
            {!item.statTimers && 
              <Header as='h3' color='red'>
                <Header.Content>
                  <Icon name='warning sign' />No data is available !
                </Header.Content>
              </Header>
            }
            {/* data={barSubscriptions} legend={legends} */}
            {item.statTimers && 
            <LineChart data={item.chart.data} legend={item.chart.legend} mode='simple' height={300} yAxis={false} dashed={true} label={true}/>}
          </Segment>

          <Divider />
          <Segment>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: '2em', paddingTop: '.4em'}}>
                <Checkbox checked={logSession} label={<label><b>Session Log</b></label>} onClick={this.onCheckbox.bind(this, 'logSession')}/>
              </div>
              <div style={{marginRight: '2em', paddingTop: '.4em'}}>
                <Checkbox checked={logLocking} label={<label><b>Locking Log</b></label>} onClick={this.onCheckbox.bind(this, 'logLocking')}/>
              </div>
              <div style={{marginRight: '2em', paddingTop: '.4em'}}>
                <Checkbox checked={logDispatch} label={<label><b>Dispatch Log</b></label>} onClick={this.onCheckbox.bind(this, 'logDispatch')}/>
              </div>
              <div style={{flex: '1', marginRight: '2em', paddingTop: '.4em'}}>
                <Checkbox checked={viewSimple} label={<label><b>Simple View</b></label>} onClick={this.onCheckbox.bind(this, 'viewSimple')}/>
              </div>
              <div>
                <Dropdown style={{fontWeight: 'bold', color: '#c31', fontSize: '1.2em'}}  onChange={this.onChangeSelect.bind(this)}
                  value= {dateActivity}
                  placeholder='Date of Activity'
                  search
                  selection
                  options={dateOptions}
                />
              </div>
            </div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      TIME
                      <Header.Subheader>hh:mm</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                  {viewSimple && <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      TIME
                      <Header.Subheader>hh:mm</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>}
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell width='5'>
                    <Header as='h5' color='blue'>
                      ACTIVITY
                      <Header.Subheader>Action Logger</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={viewSimple ? 7 : 8}>
                    <Header as='h5' color='blue'>
                      REASON
                      <Header.Subheader>Action Reason</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      DURATION
                      <Header.Subheader>hh:mm</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!viewSimple && dataExts && dataExts.map(log=>{
                  if (log.action.match(regex)) {
                    return(
                      <Table.Row key={++seq}>
                        <Table.Cell>{seq}</Table.Cell>
                        <Table.Cell>
                          <Header as='h5' 
                            // color={
                            // (log.action.match(/signing/i) && log.action.match(/ in| out/i) && 'red') ||
                            // (log.action.match(/session/i) && log.action.match(/starting|exit/i) && 'blue') ||
                            // (log.action.match(/locked/i) && log.action.match(/enter|exit/i) && 'grey') ||
                            // (log.action.match(/dispatch/i) && log.action.match(/receiving|releasing/i) && 'brown')
                            // || 'black'}
                            >
                            <Header.Content>
                              {/* <Icon name={log.action.match(/out|exit|releasing/i) ? 'long arrow alternate left' : 'long arrow alternate right'} color={
                              (log.action.match(/signing/i) && 'red') ||
                              (log.action.match(/session/i) && 'blue') ||
                              (log.action.match(/locked/i)  && 'grey') ||
                              (log.action.match(/dispatch/i) && 'brown')
                              || 'black'} 
                              /> */}
                              {/* {log.stamp && log.stamp.substr(0,5)} */}
                              {log.stamp && log.stamp}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                          <Header as='h5' 
                            // color={
                            // (log.action.match(/signing/i) && log.action.match(/ in| out/i) && 'red') ||
                            // (log.action.match(/session/i) && log.action.match(/starting|exit/i) && 'blue') ||
                            // (log.action.match(/locked/i) && log.action.match(/enter|exit/i) && 'grey') ||
                            // (log.action.match(/dispatch/i) && log.action.match(/receiving|releasing/i) && 'brown')
                            // || 'black'}
                            >
                            <Header.Content>
                              {log.action}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {log.reason}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                            <Header.Content>
                              {!isNaN(log.duration) && log.duration!='-' ? this.formatSeconds(log.duration||0).replace(/00/g,'--') : ''}
                              {/* {log.duration} */}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>  
                    )
                  }
                })}
                {viewSimple && dataExts && dataExts.map(log=>{
                  if (log.action.match(regex)) {
                    return(
                      <Table.Row key={++seq}>
                        <Table.Cell>{seq}</Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {log.stampIn && log.stampIn.substr(0,5)}
                              {/* {log.stampIn && log.stampIn} */}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {log.stampOut && log.stampOut.substr(0,5)}
                              {/* {log.stampOut && log.stampOut} */}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {log.action}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {log.reason}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                            <Header.Content>
                              {!isNaN(log.duration) && log.duration!='-' ? this.formatSeconds(log.duration||0).replace(/00/g,'--') : ''}
                              {/* {log.duration} */}
                              <Header.Subheader></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>  
                    )
                  }
                })}
              </Table.Body>
            </Table>
          </Segment>
        </div>
      )
    } else {
      return(<div></div>)
    }
  }

  render() {
    const { isShown }= this.state
    const {screenWidth, screenHeight}= this.props
    const { data, search, paging }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    const content= this.contentModal();
    return(
      <>
        <ModalContainer size='large' open={isShown} content={content} closeModal={this.switchModal.bind(this, {})} />

        <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
          <div>
            <PerformanceAgentSearch />
          </div>
          <div className='noscroll' style={{flex: '1', width: '100%', overflowX: 'scroll'}}>
            <div style={{minWidth: '1800px'}}>
              <Table unstackable selectable columns='16' celled striped basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell width='3'>
                      <Header as='h5' color='blue'>
                        <b>AGENT</b>
                        <Header.Subheader><b>Click <i>chart</i> for Logger</b></Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue' style={{width: '120px'}}>
                        PERIOD
                        <Header.Subheader>{search && search.view=='Monthly' ? 'Month' : 'Date'}</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        CONTACTED
                        <Header.Subheader>Quantity</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        ACHIEVED
                        <Header.Subheader>Quantity</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue' style={{width: '100px'}}>
                        IN/OUT
                        <Header.Subheader>{search && search.view=='Monthly' ? 'Frequency' : 'hh:mm'}</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        ONLINE
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        LOCKED
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        OCCUPIED
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        BRIEFING
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        PRAYING
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        TOILET
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        <b>FU</b>
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        <b>ACW</b>
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                    <Table.HeaderCell width='1' textAlign='center'>
                      <Header as='h5' color='blue'>
                        <b>AUX</b>
                        <Header.Subheader>Duration</Header.Subheader>
                      </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                  { data!==undefined ? data.map((item,key)=>{
                      const timers= {
                        signin: 0,
                        signout: 0,
                        
                        online: 0, 
                        locked: 0,
                        occupied: 0,

                        briefing: 0,
                        praying: 0,
                        toilet: 0,
                        fu: 0,
                        acw: 0,
                        aux: 0,
                      };
                      const performs= {
                        dropped: 0,
                        engaged: 0,
                        achieved: 0,
                      };

                      item && item.statTimers && Object.entries(item.statTimers).forEach(([key, value]) => {
                        if ((search.view=='Today' && key==format(new Date(), 'dd')) || (search.view=='Daily' && key==format(parse(search.date.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd'))) {
                          timers['day']= key;
                          timers['signin']= value.signinStamp && value.signinStamp.substring(11,16);
                          timers['signout']= value.signoutStamp && value.signoutStamp.substring(11,16);

                          timers['online']= parseInt(value.secondOnline || '0');
                          timers['locked']= parseInt(value.secondLocked || '0');
                          timers['occupied']= parseInt(value.secondOncall || '0');

                          timers['briefing']= parseInt(value.secondBriefing || '0');
                          timers['praying']= parseInt(value.secondPraying || '0');
                          timers['toilet']= parseInt(value.secondToilet || '0');
                          timers['fu']= parseInt(value.secondFU || '0');
                          timers['acw']= parseInt(value.secondACW || '0');
                          timers['aux']= parseInt(value.secondAUX || '0');
                        } else if (search.view=='Monthly') {
                          timers['signin']+=  value.signinStamp ? 1 : 0;
                          timers['signout']+=  value.signoutStamp ? 1 : 0;
                          
                          timers['online']+= parseInt(value.secondOnline || '0');
                          timers['locked']+= parseInt(value.secondLocked || '0');
                          timers['occupied']+= parseInt(value.secondOncall || '0');

                          timers['briefing']+= parseInt(value.secondBriefing || '0');
                          timers['praying']+= parseInt(value.secondPraying || '0');
                          timers['toilet']+= parseInt(value.secondToilet || '0');
                          timers['fu']+= parseInt(value.secondFU || '0');
                          timers['acw']+= parseInt(value.secondACW || '0');
                          timers['aux']+= parseInt(value.secondAUX || '0');
                        }
                      })
                      item && item.statPerformances && Object.entries(item.statPerformances).forEach(([key, value]) => {
                        if ((search.view=='Today' && key==format(new Date(), 'dd')) || (search.view=='Daily' && key==format(parse(search.date.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd'))) {
                          performs['day']= key;
                          
                          performs['dropped']= value.totDropped;
                          performs['engaged']= value.totEngaged;
                          performs['achieved']= value.totAchieved;
                        } else if (search.view=='Monthly') {
                          performs['dropped']+= parseInt(value.totDropped || '0');
                          performs['engaged']+= parseInt(value.totEngaged || '0');
                          performs['achieved']+= parseInt(value.totAchieved || '0');
                        }
                      })
                      
                      if (timers.day && performs.day) {
                        // var date = parse(item.period + (timers['day'] || performs['day']), 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
                        // console.log(format(new Date(parse(item.period + (timers['day'] || performs['day']), 'yyyyMMdd', new Date())), 'dd/MMM, yyyy'))
                      }
                      
                      return(
                        <Table.Row key={++seq}>
                          <Table.Cell>{seq}</Table.Cell>
                          <Table.Cell>
                            <Button size='big' fluid icon style={{padding: '0 .2em', margin: '0', border: 'none', background: 'none'}} onClick={this.switchModal.bind(this, item)}>
                              <Icon name='chart area' style={{fontSize: '1em'}}/>
                            </Button>
                          </Table.Cell>
                          <Table.Cell>
                            <Header as='h5' style={{margin: '0'}} color={((item.isEnabled=='0' || item.isDeleted=='1') && 'brown') || 'black'}>
                              <Header.Content>
                                <span style={(item.isEnabled!='1' || item.isSuspended=='1' || item.isDeleted=='1') ? {fontSize:'1.15em', textDecoration: 'line-through'} : {fontSize: '1.1em'}}>{item.name}</span> <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isSuspended=='1' && <i> (Suspended)</i>) || (item.isDeleted=='1' && <i> (Deleted)</i>)}</b>
                                {/* <Header.Subheader style={{paddingTop: '.3em'}}>{item.voipAccount && item.voipAccount+'@'+item.voipHost+':'+item.voipPort}</Header.Subheader> */}
                                <Header.Subheader style={{fontSize: '.9em', paddingBottom: '.3em'}}>
                                  {/* {(item.period && (timers['day'] || performs['day']) && format(new Date(parse(item.period + (timers['day'] || performs['day']), 'yyyyMMdd', new Date())), 'dd/MMM')) || <b><i>No Today's Activity</i></b>} {timers.signin && '(in:'+ (timers.signin || '-') +' - out:'+ (timers.signout || '-') +') '} /  */}
                                  {/* {item.client || 'Posted by nobody'} */}
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{margin: '0'}}>
                              <Header.Content>
                                {/* {(item.period && (item.period.substr(4,2) +'/'+ item.period.substr(0,4))) || '-'} */}
                                {search && search.view=='Monthly' && item.period && format(parse(item.period, 'yyyyMM', new Date()), 'yyyy / MMM')}
                                {search && search.view=='Today' && timers.signin!=0 && item.period && format(parse(item.period+format(new Date(), 'dd'), 'yyyyMMdd', new Date()), 'dd/MMM')}
                                {search && search.view=='Daily' && timers.signin!=0 && format(parse(search.date.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd/MMM yyyy')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && performs.engaged}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && performs.achieved}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5'>
                              <Header.Content>
                                { (search.view=='Today' || search.view=='Daily') && timers.signin && <>
                                  {timers.signin} {timers.signout && '/'} {timers.signout}
                                </> || ''}
                                { search.view=='Monthly' && timers.signin && <>
                                  {timers.signin} / {timers.signout}
                                </> || ''}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.online).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.locked).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.occupied).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.briefing).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.praying).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.toilet).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.fu).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.acw).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                              <Header.Content>
                                {item.period && (((search.view=='Today' || search.view=='Daily') && timers['signin']) || search.view=='Monthly') && this.formatSeconds(timers.aux).replace(/00/g,'--')}
                              </Header.Content>
                            </Header>
                          </Table.Cell>
                        </Table.Row>
                      )
                    }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                  }
                </Table.Body>
              </Table>
            </div>
          </div>
          {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
        </div>
      </> 
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAgent)
