import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ConversationBrowse from '../../libraries/whatsapp/ConversationBrowse'
// import ConversationForm from '../../libraries/whatsapp/ConversationForm'
import ConversationSearch from '../../libraries/whatsapp/ConversationSearch'


class ConversationBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        messagingId: 0,
      };
    }

    setMode(mode, id, campaignId, clientId) {
      this.setState({mode: mode, messagingId: id, campaignId: campaignId, clientId: clientId})
    }

    render() {
      const {mode, messagingId, campaignId, clientId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
            <ConversationSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <ConversationBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} />}
              {/* {mode==2 && <ConversationForm mode={mode} messagingId={messagingId} setMode={this.setMode.bind(this)} campaignId={campaignId} clientId={clientId} />}
              {mode==3 && <ConversationForm mode={mode} messagingId={0} setMode={this.setMode.bind(this)} />} */}
            </div>
        </Container>
      )
    }
}

export default ConversationBody
