import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral";
import { format, parse } from "date-fns";

import ModalContainer from '../common/ModalContainer';
import PieRechart from '../chart/PieRechart';
import RadialRechart from '../chart/RadialRechart';

import { connect } from "react-redux"
import { browseCampaign, statusCampaign } from "../../actions/stream/campaignAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseCampaign:(objParam) => dispatch(browseCampaign(objParam)),
    statusCampaign:(objParam) => dispatch(statusCampaign(objParam)),
  }
};


class CampaignBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    this.props.browseCampaign({search: {eventDate: format(new Date(), 'yyyy-MM-dd'),showAuto: '1',  ...search}, paging: paging || {page: 1, size: 8}})
  }

  onChangeStatus(id, status) {
    this.props.statusCampaign({campaignId:id, isEnabled: status})
  }

  showTarget(campaignId, clientId, campaign) {
    this.setState({
      showTarget: true,
      clientId: clientId,
      campaignId: campaignId,
      campaign: campaign,
    })
  }

  onCloseModal() {
    this.setState({
      showTarget: false,
    })
  }

  viewStartCampaign() {
    return(<div style={{padding: '.5em 0 3em 0'}}>
      <Header as='h1' color='blue' icon style={{display: 'block', textAlign: 'center'}}>
        {/* <Icon name='hand point up outline' style={{fontSize: '1.5em', margin: '-1.2em 0 .5em 0'}}/> */}
        <Header.Content>
          <b>Start Campaign Now !</b>
          <Header.Subheader style={{marginTop: '1.5em', fontWeight: 200, color: '#333'}}>
            Your campaign is not available yet, please add several campaigns to broadcast your whatsapp message for <b>discount</b> and <b>voucher</b> promotion.
            <Divider style={{margin: '2em 0'}}/>
            Click wizard <Icon name='plus square' color='red' style={{margin: '0', display:'inline', fontSize: '1em'}}/> button to start your new campaign.
          </Header.Subheader>
        </Header.Content>
      </Header>
    </div>)
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.campaignStore.browseCampaign!==undefined ? this.props.campaignStore.browseCampaign : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    const legendDelivery = [
      {id: 'value', name: 'Progress'}, 
    ];
    
    return (
      <div style={{display: 'flex', padding: '1em 0 0 0', flexDirection: 'column', minHeight: '55em'}}>
        <div style={{flex: '1'}}>
          { data!=undefined && data.length==0 && this.viewStartCampaign()}

          {this.props.view=='sheet' && 
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  {this.props.setMode!=undefined &&
                  <Table.HeaderCell></Table.HeaderCell>
                  }
                  <Table.HeaderCell width='5'>
                    <Header as='h5' color='blue'>CAMPAIGN</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>DELIVERY</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell />
                  <Table.HeaderCell width='5'>
                    <Header as='h5' color='blue'>TARGETS</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Header as='h5' color='blue'></Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>COMPLETENESS (%)</Header>
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell width='1'>
                  </Table.HeaderCell> */}
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                { data!=undefined ? data.map((item,key)=>{
                  return( 
                    <Table.Row key={++seq}>
                      <Table.Cell rowSpan={1}>{seq}</Table.Cell>
                      {this.props.setMode!=undefined &&
                      <Table.Cell rowSpan={1} textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.openModal.bind(this, 'impression', item)} />
                      </Table.Cell>
                      }
                      <Table.Cell>
                        <Header as='h5'>
                          {/* <Icon name={item.isEnabled=='1'? 'calendar check outline' : 'calendar times outline'} color={item.isEnabled=='1'? 'blue' : 'red'} /> */}
                          <Icon 
                            color={(item.isEnabled=='0' && 'grey') || (item.isExpired=='-1' && 'yellow') || (item.isExpired=='1' && 'red') || 'teal'} 
                            name='circle thin' 
                            style={{fontSize: '1.1em'}} />
                          <Header.Content>
                            <Header.Subheader><b>{item.campaignType}</b> {item.dialingMode && <b>{' - ' + item.dialingMode +' Mode'}</b>} <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>) || ''}</b></Header.Subheader>
                            <b>{item.name}</b>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.startTime} - {item.endTime} / {item.channels && <b>{item.channels.join('+')}</b>}</Header.Subheader>
                              {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd/MMM') : ''} - 
                              {item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy') : ''}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell rowSpan={1} textAlign='center'>
                        <Icon style={{cursor: 'pointer', margin: '0'}} name='add user' color='blue' onClick={this.props.openModal.bind(this, 'target', item)} circular inverted />
                      </Table.Cell>
                      <Table.Cell>
                        <Grid columns={16} celled style={{margin: '0'}}>
                          <Grid.Row>
                            <Grid.Column width={4} style={{padding: '0'}}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totTarget>1000 ? numeral(item.totTarget).format('0.00a') : numeral(item.totTarget).format('0,0')}
                                <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Trgt</Label.Detail>
                              </Label>
                            </Grid.Column>
                            <Grid.Column width={4} style={{padding: '0'}}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totAttempt>1000 ? numeral(item.totAttempt).format('0.00a') : numeral(item.totAttempt).format('0,0')}
                                <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Sent</Label.Detail>
                              </Label>
                            </Grid.Column>
                            <Grid.Column width={4} style={{padding: '0'}}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totDelivered>1000 ? numeral(item.totDelivered).format('0.00a') : numeral(item.totDelivered).format('0,0')}
                                <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Dlvrd</Label.Detail>
                              </Label>
                            </Grid.Column>
                            <Grid.Column width={4} style={{padding: '0'}}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totEngaged>1000 ? numeral(item.totEngaged).format('0.00a') : numeral(item.totEngaged).format('0,0')}
                                <Label.Detail style={{paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Read</Label.Detail>
                              </Label>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                      <Table.Cell>
                        {/* <Form.Checkbox name={'isEnabled_'+ item.campaignId} 
                        toggle
                        defaultChecked={item.isEnabled=='1' ? true : false}
                        onChange={this.onChangeStatus.bind(this, item.campaignId,(item.isEnabled=='1' ? '0' : '1'))} /> */}
                        <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} color={item.isEnabled ? 'teal' : 'grey'} style={{fontSize: '1.5em'}}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Progress indicating size='medium' percent={item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress><b style={{fontSize: '.9em'}}>{item.client || 'posted by Unknown'}</b></Progress>
                      </Table.Cell>
                      {/* <Table.Cell style={{whiteSpace: 'nowrap'}}>
                        <Header as='h5' color='blue' style={{cursor: 'pointer'}}>
                          <Label style={{margin: '.2em .5em 0 0'}} color='red'>1</Label>
                          <Header.Content>
                            <Rating defaultRating={3} maxRating={5} />
                            <Header.Subheader>Bad, Fine, Good, Great, Excellent</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell> */}
                      <Table.Cell rowSpan={1} textAlign='center'>
                        <Icon style={{cursor: 'pointer', margin: '0'}} name='file alternate' color='red' onClick={this.props.openModal.bind(this, 'report_campaign', item)} circular inverted />
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
              </Table.Body>
            </Table>
          }

          {this.props.view!='sheet' && 
            <Card.Group itemsPerRow={screenWidth>=1900 ? 6 : 4} stackable doubling>
              { data!=undefined && data.map((item,idx)=>{
                const pieDelivery = [
                  { name: 'Target', value: parseInt(item.totTarget || 0) },
                  { name: 'Sent', value: parseInt(item.totAttempt || 0) },
                  { name: 'Dlvrd', value: parseInt(item.totDelivered || 0) }, 
                  { name: 'Read', value: parseInt(item.totEngaged || 0) },
                  {name: 'Resp', value: parseInt(item.totResponsed || 0), fill: '#fb3001'},
                  // { name: 'Clicked', value: 3 },
                ];
                const radialDelivery = [
                  {name: 'Target', value: parseInt(item.totTarget || 0), fill: '#bbbbbb'},
                  {name: 'Sent', value: parseInt(item.totAttempt || 0), fill: '#b5cc18'},
                  {name: 'Dlvrd', value: parseInt(item.totDelivered || 0), fill: '#21ba45'},
                  {name: 'Read', value: parseInt(item.totEngaged || 0), fill: '#fbbd08'},
                  {name: 'Resp', value: parseInt(item.totResponsed || 0), fill: '#fb3001'},
                  // {name: 'Clicked', value: 5, fill: '#f2711c'},
                ];

                return(
                  <Card key={idx} as='a' onClick={this.props.openModal.bind(this, 'impression', item)} style={{cursor: 'pointer', opacity: (item.isEnabled!='1' && .8) || (item.isExpired=='1' &&.8) || 1}}>
                    <Card.Content style={{padding: '1em'}}>
                      <Card.Meta><b>{item.campaignType}</b> <b>{(item.isEnabled!='1' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>)}</b></Card.Meta>
                      <Card.Header style={{margin: '.2em 0 .2em 0', color: item.isEnabled=='1' ? '#0065c1' : '#444',  fontWeight: 'bold', fontSize: '1.6em'}}>{item.name}</Card.Header>
                      <Card.Meta>{item.startTime} - {item.endTime}, {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd/MMM') : ''} - {item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy') : ''}</Card.Meta>
                    </Card.Content>
                    {/* <Card.Content extra>
                      <Card.Description className='noscroll' style={{margin: '.5em', height: '10em', overflow: 'auto'}}>
                        {item.policies.scriptExt ? <pre style={{marginTop: '-.1em', fontFamily: 'Arial', fontSize: '11pt', fontWeight: 'bold', color: '#333'}}>{item.policies.scriptExt}</pre> : <i style={{fontSize: '12pt'}}>Content is manageable through API integration.</i>}
                      </Card.Description>
                    </Card.Content> */}
                    <Card.Content extra style={{background: '#eeeeee'}}>
                      PROGRESS (<b><i>{numeral(item.totAttempt || 0).format('0,0')} of {numeral(item.totTarget || 0).format('0,0')}</i></b>) 
                      <Progress indicating size='small' percent={item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress />
                    </Card.Content>
                    <Card.Content extra style={{background: '#f5f5f5'}}>
                      <div style={{height: '200'}}>
                        IMPRESSION
                        {/* <PieRechart data={pieDelivery} height={200} fill='#555'/> */}
                        <RadialRechart data={radialDelivery} legend={legendDelivery} height={250}/>
                      </div>
                    </Card.Content>
                    <Card.Content extra style={{background: '#fafafa'}}>
                      <div>
                        TICKET
                        <Grid columns={16} celled stretched style={{marginTop: '.3em'}}>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totOpen>1000 ? numeral(item.totOpen).format('0.00a') : numeral(item.totOpen).format('0,0')}
                                <Label.Detail style={{width: '6em', paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Open</Label.Detail>
                              </Label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totClosed>1000 ? numeral(item.totClosed).format('0.00a') : numeral(item.totClosed).format('0,0')}
                                <Label.Detail style={{width: '6em', paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Closed</Label.Detail>
                              </Label>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totProgress>1000 ? numeral(item.totProgress).format('0.00a') : numeral(item.totProgress).format('0,0')}
                                <Label.Detail style={{width: '6em', paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Progress</Label.Detail>
                              </Label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Label style={{display: 'block', color: '#333', fontWeight: 'bold', fontSize: '1em'}}>
                                {item.totNoRemark>1000 ? numeral(item.totNoRemark).format('0.00a') : numeral(item.totNoRemark).format('0,0')}
                                <Label.Detail style={{width: '6em', paddingLeft: '.5em', borderLeft: '1px solid #ccc', textAlign: 'right', fontSize: '.9em'}}>Remark</Label.Detail>
                              </Label>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </Card.Content>
                  </Card>)
              })}
            </Card.Group>
          }
          
          {/* {this.state.campaignId && this.state.showTarget && 
          <ModalContainer size='fullscreen' open={this.state.showTarget}  content={<TargetBody screenHeight={screenHeight} campaignId={this.state.campaignId} campaign={this.state.campaign} />} closeModal={this.onCloseModal.bind(this)} />
          } */}
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'center'}}>
            <div style={{display:'inline-block', borderRadius: '3em', padding: '.5em', background: '#f8f8f8', border: '1px solid rgba(100, 100, 100, .3)'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignBrowse)
