import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Button } from 'semantic-ui-react'

import TargetBrowse from '../../libraries/whatsapp/TargetBrowse'
import TargetForm from '../../libraries/whatsapp/TargetForm'
import TargetSearch from '../../libraries/whatsapp/TargetSearch'


class TargetBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        targetId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, targetId: id})
    }

    render() {
      const {mode, targetId}= this.state
      const {screenWidth, screenHeight, campaign}= this.props
      
      return(
        <Container fluid>
          <div style={{marginTop: '-3em', height: '3em'}}>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'campaign', this.props.params)}><Icon name='edit' />Campaign</Button>
            <Button color='blue' onClick={this.props.openModal.bind(this, 'target', this.props.params)}><Icon name='user circle' />Target</Button>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'messaging', this.props.params)}><Icon name='check square outline' />Delivery</Button>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'impression', this.props.params)}><Icon name='chart line' />Impression</Button>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'summary', this.props.params)}><Icon name='file alternate outline' />Summary</Button>
          </div>
          
          <TargetSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} campaignId={this.props.params.campaignId} />
          <div>
            {mode==1 && <TargetBrowse mode={mode} campaignId={this.props.params.campaignId} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} campaign={this.props.params} />}
            {mode==2 && <TargetForm mode={mode} targetId={targetId} setMode={this.setMode.bind(this)} campaignId={this.props.params.campaignId} campaign={this.props.params} />}
            {mode==3 && <TargetForm mode={mode} targetId={0} setMode={this.setMode.bind(this)}  campaignId={this.props.params.campaignId} campaign={this.props.params} />}
          </div>
        </Container>
      )
    }
}

export default TargetBody
