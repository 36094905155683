import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import RadialRechart from '../../chart/RadialRechart';
import PieRechart from '../../chart/PieRechart';


class CampaignSummary extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isUnique: false,
      };
    }
    
    setUnique() {
      this.setState({
        isUnique: !this.state.isUnique,
      })
    }

    render() {
      const {screenWidth, screenHeight, item}= this.props
      const {isUnique}= this.state

      const pieAccuDelivery = [
        { name: 'Target', value: item.totTarget },
        { name: 'Sent', value: item.totAttempt }, 
        { name: 'Delivered', value: item.totDelivered },
        { name: 'Read', value: item.totEngaged },
        { name: 'Responsed', value: item.totResponsed },
        { name: 'Achieved', value: item.totAchieved },
      ];
      const pieUqDelivery = [
        { name: 'Target', value: item.totTarget },
        { name: 'Sent', value: item.uqAttempt }, 
        { name: 'Delivered', value: item.uqDelivered },
        { name: 'Read', value: item.uqEngaged },
        { name: 'Responsed', value: item.uqResponsed },
        { name: 'Achieved', value: item.uqAchieved },
      ];
      const radialDelivery = [
        {name: 'Target', value: item.totTarget, fill: '#82ca9d'},
        {name: 'Sent', value: item.totAttempt, fill: '#a4de6c'},
        {name: 'Delivered', value: item.totDelivered, fill: '#d0ed57'},
        {name: 'Read', value: item.totEngaged, fill: '#ffc658'},
        {name: 'Responsed', value: item.totResponsed, fill: '#d0ed57'},
        {name: 'Achieved', value: item.totAchieved, fill: '#ffc658'},
      ];
      const legendDelivery = [
        {id: 'value', name: 'Progress'}, 
      ];
      const pieAccuResponse = [
        { name: 'Paid', value: item.totPaid },
        { name: 'Promised', value: item.totPromised },
        { name: 'Reschedule', value: item.totRescheduled },
        { name: 'Verified', value: item.totVerified },
        { name: 'Ordered', value: item.totOrdered },
        { name: 'Interested', value: item.totInterested },
        
        { name: 'Reject', value: item.totReject },
        { name: 'Ignored', value: item.totIgnored }, 
        { name: 'Invalid', value: item.totInvalid }, 
        { name: 'Mailbox', value: item.totMailbox },
      ];
      const pieUqResponse = [
        { name: 'Paid', value: item.uqPaid },
        { name: 'Promised', value: item.uqPromised },
        { name: 'Reschedule', value: item.uqRescheduled },
        { name: 'Verified', value: item.uqVerified },
        { name: 'Ordered', value: item.uqOrdered },
        { name: 'Interested', value: item.uqInterested },

        { name: 'Reject', value: item.uqReject },
        { name: 'Ignored', value: item.uqIgnored }, 
        { name: 'Invalid', value: item.uqInvalid }, 
        { name: 'Mailbox', value: item.uqMailbox },
      ];
      
      return(
        <Item style={{margin: '1em 0 2em 0'}}>
          <Item.Content style={{background: '#fff', border: '1px dashed rgba(71, 185, 255, .8)', padding: '1.5em', }}>
            <div style={{display: 'flex'}}>
              <div style={{flex: '4', paddingRight: '1.5em'}}>
                <Item.Header>
                  <Header as='h2' color={((item.isEnabled=='0' || item.isExpired!='0') && 'brown') || 'black'}>
                    {/* {this.props.seq && <Label style={{margin: '.01em .5em 0 0', padding: '.5em', minWidth: '2.5em', textAlign: 'center'}}>{this.props.seq}</Label>} */}
                    <Header.Content>
                      <Header.Subheader>{item.campaignType}  {item.client ? ' / '+ item.client : ''} <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>)}</b></Header.Subheader>
                      <span style={{textDecoration: (item.isEnabled=='0' || item.isExpired!='0') ? 'line-through' : 'none'}}>{item.name}</span> {item.dialingMode && <b>{' - ' + item.dialingMode +' Mode'}</b>}
                    </Header.Content>
                  </Header>
                </Item.Header>
              </div>
              <div style={{background: '#fafafa', padding: '1em'}}>
                <Header as='h4'>
                  <Label color='grey' style={{fontSize: '1.5em', padding: '.9em .5em', margin: '-.45em .5em -.5em -.5em'}}>{Math.round(item.totTarget>0 && item.totComplete>0 ? ((item.totComplete / item.totTarget)>1 ? 1 : (item.totComplete / item.totTarget)) * 100 : 0)} <sup>%</sup></Label>
                  <Header.Content>
                    {item.startTime} - {item.endTime}<br />
                    {item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd/MMM') : ''} - <b><i>{item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy') : ''}</i></b>
                  </Header.Content>
                </Header>
              </div>
            </div>
            <Divider />
            <Item.Extra style={{background: '#fafafa', padding: '.3em 0'}}>
              <div style={{display: 'flex'}}>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {numeral(item.totTarget).format('0,0')}
                      <Header.Subheader><sup>TARGET</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqAttempt).format('0,0')}{!isUnique && numeral(item.totAttempt).format('0,0')}
                      <Header.Subheader><sup>SENT</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqDelivered).format('0,0')}{!isUnique && numeral(item.totDelivered).format('0,0')}
                      <Header.Subheader><sup>DLVRD</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqEngaged).format('0,0')}{!isUnique && numeral(item.totEngaged).format('0,0')}
                      <Header.Subheader><sup>READ</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 1em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqResponsed).format('0,0')}{!isUnique && numeral(item.totResponsed).format('0,0')}
                      <Header.Subheader><sup>RESP</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{padding: '.7em 2em'}}>
                  <Header as='h4' style={{fontWeight: 'bold'}} color='red'>
                    <Header.Content>
                      {isUnique && numeral(item.uqAchieved).format('0,0')}{!isUnique && numeral(item.totAchieved).format('0,0')}
                      <Header.Subheader><sup>ACHVD</sup></Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                {screenWidth>800 && <>
                  <div style={{padding: '10px 1em 0 0', marginRight: '-1.3em'}}>
                    <Label size='medium' color='orange' style={{borderRadius: '1em 0 0 1em', height: '27px'}}>COMPLETENESS ({item.totComplete<1000 ? item.totComplete : numeral(item.totComplete).format('0.0a')})</Label>
                  </div>
                  <div style={{flex: '1', padding: '.8em 0 0 0'}}>
                    <Progress color='blue' size='medium' percent={Math.round(item.totTarget>0 ? ((item.totComplete / item.totTarget)>1 ? 1 : (item.totComplete / item.totTarget)) * 100 : 0)} progress style={{margin: '0'}}/>
                  </div>
                </>}
              </div>
            </Item.Extra>
            <Divider style={{margin: '1em 0'}}/>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '.7em 0'}}>
                  <Label color='yellow' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget ? Math.ceil(item.totAttempt/item.totTarget * 100) : 0 : item.totTarget ? Math.ceil(item.uqAttempt/item.totTarget * 100) : 0}%
                  </Label>
                  <Header.Content>
                    SENT
                    <Header.Subheader>Message sent.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.7em 0'}}>
                  <Label color='olive' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totDelivered/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqDelivered/item.totTarget * 100) : 0}%
                    </Label>
                  <Header.Content>
                    DELIVERED
                    <Header.Subheader>Delivered to target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.7em 0'}}>
                  <Label color='olive' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totEngaged/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqEngaged/item.totTarget * 100) : 0}%
                    </Label>
                  <Header.Content>
                    READ
                    <Header.Subheader>Read by target.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.7em 0'}}>
                  <Label color='green' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totResponsed/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqResponsed/item.totTarget * 100) : 0}%
                  </Label>
                  <Header.Content>
                    RESPONSED
                    <Header.Subheader>Engagement / conversation.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Header as='h5' style={{margin: '.7em 0'}}>
                  <Label color='blue' style={{marginTop: '.2em', width: '4em', marginRight: '1em'}}>
                    {!isUnique ? item.totTarget!=0 ? Math.ceil(item.totAchieved/item.totTarget * 100) : 0 : item.totTarget!=0 ? Math.ceil(item.uqAchieved/item.totTarget * 100) : 0}%
                  </Label>
                  <Header.Content>
                    ACHIEVED
                    <Header.Subheader>Complete 100%.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              {screenWidth>800 && <div style={{flex: '1', textAlign: 'center'}}>
                <PieRechart data={isUnique ? pieUqDelivery : pieAccuDelivery} width={300} height={230} fill='#555'/>
                {/* <RadialRechart data={radialDelivery} legend={legendDelivery} width={300} height={250}/> */}
                <Header as='h5' color='red' style={{margin: '0'}}>
                  <Header.Content>
                    Campaign Delivery
                  </Header.Content>
                </Header>
              </div>}
              <div style={{flex: '1', textAlign: 'center'}}>
                <PieRechart data={isUnique ? pieUqResponse : pieAccuResponse} width={300} height={230} mode='active' fill='#555'/>
                <Header as='h5' color='red' style={{margin: '0'}}>
                  <Header.Content>
                    Customer Response
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Item.Content>
        </Item>
      )
    }
}

export default CampaignSummary;
