import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Statistic, Icon, Label, Table, Progress } from 'semantic-ui-react'

import numeral from "numeral";
import { format, parse } from "date-fns";

import PieRechart from '../chart/PieRechart';
import RadialRechart from '../chart/RadialRechart';
import BarRechart from '../chart/BarRechart';

import DismissableMessage from '../common/DismissableMessage'
import {hourOptions, yesnoOptions, redistOptions, delimiterOptions, fieldTargetOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, NumberField, TextAreaField, DatePickerField, DataUploadField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { summaryCampaign } from "../../actions/stream/campaignAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ScriptForm')

const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    summaryCampaign:(objParam) => dispatch(summaryCampaign(objParam)),
  }
}

class CampaignPerformance extends Component {
  constructor(props) {
    super(props)
    this.state= {
      view: 0,
    }
  }

  componentDidMount(){
    this.props.item && this.props.summaryCampaign({campaignId: this.props.item.campaignId})
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    // const { item }= this.props;
    const { data, search, paging }= (this.props.campaignStore.summaryCampaign!==undefined ? this.props.campaignStore.summaryCampaign : {})
    const item= data ? data : {};
    const radialDelivery = [
      {name: 'CUST', value: item && parseInt(item.totTarget || 0), fill: '#61A5E0'},
      {name: 'SENT', value: item && parseInt(item.totAttempt || 0), fill: '#b5cc18'},
      {name: 'DLVRD', value: item && parseInt(item.totDelivered || 0), fill: '#21ba45'},
      {name: 'READ', value: item && parseInt(item.totEngaged || 0), fill: '#fbbd08'},
      {name: 'RESP', value: item && parseInt(item.totResponsed || 0), fill: '#fb3008'},
    ];
    const legendDelivery = [
      {id: 'value', name: 'Progress'}, 
    ];
    
    if (!this.props.summary) {
      return (<>
        <div style={{padding: '1em 1em'}}>
          <Header as='h4'>
            <Header.Content>
              Progress (<b><i>{item.totComplete} / {item.totTarget}</i></b>)
              <Header.Subheader>Content delivery progress.</Header.Subheader>
            </Header.Content>
          </Header>
          <div>
            <Statistic.Group size='tiny' widths={5}>
              <Statistic>
                <Statistic.Value>{item.totTarget}</Statistic.Value>
                <Statistic.Label>Cust</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{item.totAttempt}</Statistic.Value>
                <Statistic.Label>Sent</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{item.totDelivered}</Statistic.Value>
                <Statistic.Label>Dlvrd</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{item.totEngaged}</Statistic.Value>
                <Statistic.Label>Read</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{item.totResponsed}</Statistic.Value>
                <Statistic.Label>Resp</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </div>

          <Progress indicating size='medium' percent={item && item.totTarget>0 ? Math.round((item.totComplete / item.totTarget) * 100) : 0} progress />
          <Header as='h4'>
            <Header.Content>
              Impression
              <Header.Subheader>Customer's impression sent, delivered, read and clicked*.</Header.Subheader>
            </Header.Content>
          </Header>

          {/* <PieRechart data={pieDelivery} height={200} fill='#555'/> */}
          {/* <RadialRechart data={radialDelivery} legend={legendDelivery} height={250}/> */}
          <BarRechart mode='shape' height={450} yAxis={false} dashed={true} label={true} data={radialDelivery} legend={legendDelivery}/>
        </div>
      </>)
    } else {
      return (<>
        <div style={{padding: '.5em 1em'}}>
          <Grid columns={16} style={{margin: '1em -.5em'}}>
            <Grid.Row style={{margin: '.5em 0'}}>
              <Grid.Column width={10} style={{paddingRight: '2em'}}>
                <Header as='h4' color='blue'>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b><u>Campaign Name</u></b></Header.Subheader>
                    <span style={{fontSize: '1.3em'}}>{item.name}</span>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h4' color='blue'>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b><u>Running Schedule</u></b></Header.Subheader>
                    {item.startTime} - {item.endTime} <br />{item.startDate!=null ? format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd/MMM') : ''} - {item.endDate!=null ? format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd/MMM, yyyy') : ''}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />
          <Grid columns={16} style={{margin: '.5em -.5em'}}>
            <Grid.Row style={{margin: '.5em 0'}}>
              <Grid.Column width={10} style={{paddingRight: '2em'}}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b><u>Broadcast Content</u></b></Header.Subheader>
                    <div dangerouslySetInnerHTML={{__html: (item.scriptExt && item.scriptExt.replace(/\n/gm,'<br />')) || '-'}} />
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b><u>Supervisor</u></b></Header.Subheader>
                    {item.owner}
                  </Header.Content>
                </Header>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b><u>Assigned Workgroup</u></b></Header.Subheader>
                    {(item.workgroups && item.workgroups.join(', ')) || 'Not Defined'}
                  </Header.Content>
                </Header>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader style={{fontSize: '.9em'}}><b><u>Assigned Gateways</u></b></Header.Subheader>
                    {(item.gateways && Array.isArray(item.gateways) && item.gateways.join(', ')) || 'All Devices'}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />
          <Grid divided columns={16} style={{margin: '2em 0em', fontSize: '12pt'}} celled>
            <Grid.Row>
              <Grid.Column width={8} style={{background: '#eff6e7'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totTarget>1000 ? numeral(item.totTarget).format('0.00a') : numeral(item.totTarget).format('0,0')}
                    <Header.Subheader><b>Tot. Target</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={8} style={{background: '#f7e7e2'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totTicket>1000 ? numeral(item.totTicket).format('0.00a') : numeral(item.totTicket).format('0,0')}
                    <Header.Subheader><b>Tot. Ticket</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} style={{background: '#eff6e7'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totAttempt>1000 ? numeral(item.totAttempt).format('0.00a') : numeral(item.totAttempt).format('0,0')}
                    <Header.Subheader><b>Tot. Sent</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} style={{background: '#eff6e7'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totDelivered>1000 ? numeral(item.totDelivered).format('0.00a') : numeral(item.totDelivered).format('0,0')}
                    <Header.Subheader><b>Tot. Delivered</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} style={{background: '#f7e7e2'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totOpen>1000 ? numeral(item.totOpen).format('0.00a') : numeral(item.totOpen).format('0,0')}
                    <Header.Subheader><b>Tot. Open</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} style={{background: '#f7e7e2'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totProgres>1000 ? numeral(item.totProgres).format('0.00a') : numeral(item.totProgres).format('0,0')}
                    <Header.Subheader><b>Tot. Progress</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} style={{background: '#eff6e7'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totEngaged>1000 ? numeral(item.totEngaged).format('0.00a') : numeral(item.totEngaged).format('0,0')}
                    <Header.Subheader><b>Tot. Read</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} style={{background: '#eff6e7'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totResponsed>1000 ? numeral(item.totResponsed).format('0.00a') : numeral(item.totResponsed).format('0,0')}
                    <Header.Subheader><b>Tot. Responsed</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} style={{background: '#f7e7e2'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totClosed>1000 ? numeral(item.totClosed).format('0.00a') : numeral(item.totClosed).format('0,0')}
                    <Header.Subheader><b>Tot. Closed</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} style={{background: '#f7e7e2'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totWithRemark>1000 ? numeral(item.totWithRemark).format('0.00a') : numeral(item.totWithRemark).format('0,0')}
                    <Header.Subheader><b>Tot. Remarked</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} style={{background: '#eaeee6'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totFailed>1000 ? numeral(item.totFailed).format('0.00a') : numeral(item.totFailed).format('0,0')}
                    <Header.Subheader><b>Tot. Failed</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={8} style={{background: '#efe0e0'}}>
                <Header as='h5'>
                  <Header.Content>
                    {item.totNoRemark>1000 ? numeral(item.totNoRemark).format('0.00a') : numeral(item.totNoRemark).format('0,0')}
                    <Header.Subheader><b>Tot. No Remark</b></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPerformance)
