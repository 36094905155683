import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import WaserverBrowse from '../../libraries/whatsapp/WaserverBrowse'
import WaserverForm from '../../libraries/whatsapp/WaserverForm'
import WaserverSearch from '../../libraries/whatsapp/WaserverSearch'


class WaserverBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        waserverId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, waserverId: id})
    }

    render() {
      const {mode, waserverId}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div>
            <WaserverSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <WaserverBrowse  setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight}/>}
              {mode==2 && <WaserverForm mode={mode} waserverId={waserverId} setMode={this.setMode.bind(this)} />}
              {mode==3 && <WaserverForm mode={mode} waserverId={0} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default WaserverBody
