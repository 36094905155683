import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetWacontact(objParam) {
  return {
      type: 'WACONTACT_RESET',
      payload: objParam
  }
}

export function getWacontact(objParam) {
  return {
      type: 'WACONTACT_GET',
      payload:
        axios.post(
          urlAPI + 'wacontact/get',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function statusWacontact(objParam) {
  return {
      type: 'WACONTACT_STATUS',
      payload:
        axios.post(
          urlAPI + 'wacontact/status',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function browseWacontact(objParam) {
  return {
      type: 'WACONTACT_BROWSE',
      payload:
        axios.post(
          urlAPI + 'wacontact/browse',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function createWacontact(objParam) {
  return {
      type: 'WACONTACT_CREATE',
      payload:
        axios.post(
          urlAPI + 'wacontact/create',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function updateWacontact(objParam) {
  return {
      type: 'WACONTACT_UPDATE',
      payload:
        axios.post(
          urlAPI + 'wacontact/update',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function removeWacontact(objParam) {
  return {
      type: 'WACONTACT_REMOVE',
      payload:
        axios.post(
          urlAPI + 'wacontact/remove',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
