import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

import ScriptBrowse from '../../libraries/whatsapp/ScriptBrowse'
import ScriptForm from '../../libraries/whatsapp/ScriptForm'
import ScriptSearch from '../../libraries/whatsapp/ScriptSearch'


class ScriptBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        scriptId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, scriptId: id})
    }

    render() {
      const {mode, scriptId}= this.state
      const {screenWidth}= this.props
      
      return(
        <Container fluid>
          <div>
            <ScriptSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
            <div>
              {mode==1 && <ScriptBrowse setMode={this.setMode.bind(this)} screenWidth={screenWidth} />}
              {mode==2 && <ScriptForm mode={mode} scriptId={scriptId} screenWidth={screenWidth} setMode={this.setMode.bind(this)} />}
              {mode==3 && <ScriptForm mode={mode} scriptId={0} screenWidth={screenWidth} setMode={this.setMode.bind(this)} />}
            </div>
          </div>
        </Container>
      )
    }
}

export default ScriptBody
