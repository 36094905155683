import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  Workgroup,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseWorkgroup } from "../../../actions/halo/workgroupAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    workgroupStore: state.workgroupStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseWorkgroup:(objParam) => dispatch(browseWorkgroup(objParam)),
  }
};


class WorkgroupBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  componentWillMount() {
    this.props.browseWorkgroup()
  }

  render() {
    const { screenWidth }= this.props
    const data= (this.props.workgroupStore.browseWorkgroup!==undefined ? this.props.workgroupStore.browseWorkgroup.data : undefined)
    let seq= 0
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Card.Group itemsPerRow={4} stackable doubling>
          { data!=undefined && data.length==0 &&<Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>}
          { data!=undefined ? data.map(item=>{
          return(
          <Card key={seq++} as='a' onClick={this.props.setMode.bind(this,2, item.workgroupId)}>
            <Card.Content style={{textAlign: 'center'}}>
              <Header as='h4' icon color={(item.isEnabled=='0' && 'brown') || 'black'}>
                <Icon name='id badge outline' style={{fontSize: '2em', margin: '.5em 0'}} color='grey'/>
                {item.name} {(item.isEnabled=='0' && <i> (Disabled)</i>)}
                <Header.Subheader>{item.client || 'Nobody'}</Header.Subheader>
              </Header>
            </Card.Content>
            <Card.Content style={{textAlign: 'center'}}>
              <Header as='h4'>
                <Label color='red'>{item.tagger || 'No tagger'}</Label>
              </Header>
              {item.leaders}
            </Card.Content>
            <Card.Content extra style={{textAlign: 'center', padding: '1em'}}>
              <Header as='h3'>
                {item.agents.length}
                <Header.Subheader><Label style={{padding: '.3em .5em'}}>Allocated Agent</Label></Header.Subheader>
              </Header>
            </Card.Content>
          </Card>
          )}) : <div style={{padding: '1em'}}><Icon name='circle notch' loading /></div>
          }
        </Card.Group>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkgroupBrowse)
