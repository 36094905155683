import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown, Select } from 'semantic-ui-react'

import { parse, format, endOfMonth } from "date-fns"

import LineRechart from '../../chart/LineRechart';

import { connect } from "react-redux"
import { statConversation } from "../../../actions/halo/statisticAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statConversation:(objParam) => dispatch(statConversation(objParam)),
  }
}

class TrafficWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      mode: 'message', //ticket, messsage, flow
      stamp: '',
      dayData: null,

      barData: [
        // {name: '01', totSent: 1, totDelivered: 5, totRead: 15, totResponsed: 5}, 
        // {name: '02', totSent: 2, totDelivered: 6, totRead: 9, totResponsed: 5}, 
        // {name: '03', totSent: 3, totDelivered: 7, totRead: 5, totResponsed: 5}, 
        // {name: '04', totSent: 4, totDelivered: 8, totRead: 7, totResponsed: 5}, 
      ],
      barLegend: [
        // {id: 'totSent', name: 'Sent'},
        // {id: 'totDelivered', name: 'Delivered'},
        // {id: 'totRead', name: 'Read'},
        // {id: 'totResponsed', name: 'Responsed'},
      ]
    };
  }

  componentDidMount() {
    this.props.statConversation({search: {
      period: format(new Date(), 'yyyyMM'),
      grouping: 'Daily',
      groupingDate: format(new Date(), 'yyyy-MM-01'),
    }})
  }
  
  componentDidUpdate() {
    const { barData, stamp,mode}= this.state
    const { data, search }= (this.props.statisticStore.statConversation!==undefined ? this.props.statisticStore.statConversation : {})
    // console.log('!!! DATA', data, search)

    if (data) {
      const eom= search && search.groupingDate ? format(endOfMonth(parse(search.groupingDate.substr(0,11), 'yyyy-MM-dd', new Date())), 'd') : null;
      const _stamp= (search && search.groupingDate && (search.grouping=='Daily' ? search.grouping +'-'+ search.groupingDate.substr(0,7) : search.grouping +'-'+ search.groupingDate.substr(5,2))) || '';

      // console.log('!!! DATA', data, eom, _stamp)
      const dayData= new Map();
      if (this.state.stamp!=_stamp) {
        if (eom>0) {
          // console.log('!!! DATA', data, search, eom)
          data && data.map((item,key)=>{
            dayData.set(item.period.substring(8), {
              totSent: parseInt(item.totSent || 0), 
              totDelivered: parseInt(item.totDelivered || 0), 
              totRead: parseInt(item.totRead || 0), 
              totPlayed: parseInt(item.totPlayed || 0), 
              totResponsed: parseInt(item.totResponsed || 0), 

              totOpen: parseInt(item.totOpen || 0), 
              totClosed: parseInt(item.totClosed || 0), 
              totQuickChat: parseInt(item.totQuickChat || 0), 

              totInbound: parseInt(item.totInbound || 0), 
              totOutbound: parseInt(item.totOutbound || 0), 
            })
          })
          // console.log('!!! DAY DATA', dayData)
        
          const bardata= new Array(eom);
          for (var i=0; i<eom; i++) {
            const dd= i<9 ? '0'+ (i+1) : (i+1);
            bardata[i]= {
              name: dd, 
              ...(mode=='message' ? {totSent: (dayData && dayData.get(dd) && dayData.get(dd).totSent) || 0, 
                totDelivered: (dayData && dayData.get(dd) && dayData.get(dd).totDelivered) || 0, 
                totRead: (dayData && dayData.get(dd) && dayData.get(dd).totRead) || 0, 
                totResponsed: (dayData && dayData.get(dd) && dayData.get(dd).totResponsed) || 0} : {}),
              ...(mode=='ticket' ? {totOpen: (dayData && dayData.get(dd) && dayData.get(dd).totOpen) || 0,
                totClosed: (dayData && dayData.get(dd) && dayData.get(dd).totClosed) || 0,
                totQuickChat: (dayData && dayData.get(dd) && dayData.get(dd).totQuickChat) || 0} : {}),
              ...(mode=='flow' ? {totInbound: (dayData && dayData.get(dd) && dayData.get(dd).totInbound) || 0,
                totOutbound: (dayData && dayData.get(dd) && dayData.get(dd).totOutbound) || 0} : {}),
            }
          }
          
          this.setState({
            stamp: _stamp,
            barData: [
              ...bardata,
            ],
            dayData: dayData,

            barLegend: [
              ...(mode=='message' ? [{id: 'totSent', name: 'Sent'},
                {id: 'totDelivered', name: 'Delivered'},
                {id: 'totRead', name: 'Read'},
                {id: 'totResponsed', name: 'Responsed'}] : []),
              ...(mode=='ticket' ? [{id: 'totOpen', name: 'Open'},
                {id: 'totClosed', name: 'Closed'},
                {id: 'totQuickChat', name: 'Quick Chat'}] : []),
              ...(mode=='flow' ? [{id: 'totInbound', name: 'Inbound'},
                {id: 'totOutbound', name: 'Outbound'}] : []),
            ]
          });
        }
      }
    }
  }

  composeData(v) {
    const {dayData}= this.state
    const dates= [];
    const barData= []

    var startDate = parse(format(new Date(), 'yyyyMM') +'01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
    var endDate= parse(format(new Date(), 'yyyyMM') + '01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
    endDate.setMonth(endDate.getMonth()+1)

    while (startDate<endDate) {
      dates.push(format(startDate, 'dd'))
      startDate.setDate(startDate.getDate() + 1)
    }
    // console.log('!!! DATES', dates)

    dates.map(dd=>{
      barData.push(
        {
          name: dd, 
          ...(v=='message' ? {totSent: (dayData && dayData.get(dd) && dayData.get(dd).totSent) || 0, 
            totDelivered: (dayData && dayData.get(dd) && dayData.get(dd).totDelivered) || 0, 
            totRead: (dayData && dayData.get(dd) && dayData.get(dd).totRead) || 0, 
            totResponsed: (dayData && dayData.get(dd) && dayData.get(dd).totResponsed) || 0} : {}),
          ...(v=='ticket' ? {totOpen: (dayData && dayData.get(dd) && dayData.get(dd).totOpen) || 0,
            totClosed: (dayData && dayData.get(dd) && dayData.get(dd).totClosed) || 0,
            totQuickChat: (dayData && dayData.get(dd) && dayData.get(dd).totQuickChat) || 0} : {}),
          ...(v=='flow' ? {totInbound: (dayData && dayData.get(dd) && dayData.get(dd).totInbound) || 0,
            totOutbound: (dayData && dayData.get(dd) && dayData.get(dd).totOutbound) || 0} : {}),
        },
      )
    })
    // console.log('!!! BAR DATA', barData);

    this.setState({
      barData: barData,

      barLegend: [
        ...(v=='message' ? [{id: 'totSent', name: 'Sent'},
          {id: 'totDelivered', name: 'Delivered'},
          {id: 'totRead', name: 'Read'},
          {id: 'totResponsed', name: 'Responsed'}] : []),
        ...(v=='ticket' ? [{id: 'totOpen', name: 'Open'},
          {id: 'totClosed', name: 'Closed'},
          {id: 'totQuickChat', name: 'Quick Chat'}] : []),
        ...(v=='flow' ? [{id: 'totInbound', name: 'Inbound'},
          {id: 'totOutbound', name: 'Outbound'}] : []),
      ]
    })
  }

  onChangeSelect(k,v,x) {
    // console.log(v.value)
    this.composeData(v.value)
  }
  
  render() {
    const { screenWidth,  screenHeight, height}= this.props
    const { barData, barLegend}= this.state
    const { data, search }= (this.props.statisticStore.statConversation!==undefined ? this.props.statisticStore.statConversation : {})
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}>
            <Header as='h5'>
              <Icon name='chart bar' style={{fontSize: '1em'}} />
              <Header.Content>
                Traffic Statistic / <b>{this.state.stamp}</b>
                <Header.Subheader>Traffic delivery on time and daily basis.</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div style={{width: '15em'}}>
          <Select onChange={this.onChangeSelect.bind(this)} style={{margin: 0, padding: '.5em .8em .3em .8em', minHeight: '2.4em'}}
            placeholder='Data Stream'
            fluid
            selection
            defaultValue= 'message'
            options={[
              {key: 0, value: 'ticket', text: 'Traffic of Ticket', icon: 'tag'},
              {key: 1, value: 'message', text: 'Traffic of Message', icon: 'sticky note'},
              {key: 2, value: 'flow', text: 'Traffic Flow/Direction', icon: 'rss square'},
            ]}
          />
          </div>
        </div>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', minHeight: height}}>
        {data &&
          <LineRechart mode='simple' height={(height-55) || 250} yAxis={false} dashed={true} label={true} data={barData} legend={barLegend}/>
        }
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrafficWidget)