import React, { Component } from 'react'
import { Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Accordion, Divider, Segment, Label } from 'semantic-ui-react'
import DismissableMessage from '../common/DismissableMessage'

import { ReactSVG } from 'react-svg'
import stream_logo  from '../../assets/wasap-logo.svg'

import { packageOptions }  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, formValueSelector } from 'redux-form'
import {LabelInputField, InputField, CheckboxField, Checkbox, SelectField } from '../validation/componentrsui'
import { required, number, minValue, email, account, fullName, phoneNumber, confirmedPassword } from '../validation/validation'

import { connect } from "react-redux"
import { signUp, resetForm } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const selector = formValueSelector('AuthenticateSignup')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp:(objParam) => dispatch(signUp(objParam)),
    resetForm:(objParam) => dispatch(resetForm(objParam)),
  }
}

//TERM OF CONDITIONS
class AccordionTermsConditions extends Component {
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
          Silahkan baca <b style={{color: '#f00'}}>Term & Conditions</b><Icon name='dropdown' /> dengan cermat dan teliti, untuk memastikan bahwa hak dan kewajiban Anda telah dipenuhi.
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Segment attached>
            <Header as='h5'>TERMS & CONDITIONS</Header>
            This only restricted for verified user only
            <br />
            Halo Pagi! Digital Communication 2.3.01
          </Segment>
        </Accordion.Content>
      </Accordion>
    )
  }
}

class AuthenticateSignup extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount() {
    this.props.resetForm("signUp")
  }

  componentDidUpdate(prevProps) {
    const { reset } = this.props
    const { code, text, progress, data }= this.props.authStore.signUp ? this.props.authStore.signUp : {}
    
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        reset()
        this.props.resetForm("signUp")
        this.props.openMenu('signin')
      }, 5000)
    } else {
      setTimeout(()=>{
        this.props.resetForm("signUp")
      }, 5000)
    }
    if (this.props.package=='Enterprise' && prevProps.package!='Enterprise') {
      this.props.openModal('enterprise')
    }
    if (this.props.package=='Personal' && prevProps.package!='Personal') {
      this.props.openModal('personal')
    }
  }

  submitForm(values) {
    // console.log('SIGN UP SUBMIT VALUES: ', values)
    this.props.signUp({...values, isWhatsapp: '1'})
    if (this.props.onSubmit!==undefined) {
      this.props.onSubmit({...values, isWhatsapp: '1'})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
    //this.props.onClose()
    this.props.resetForm("signUp")
  }
  
  onChangeSelect(e, v) {
    this.setState({
      package: v,
    })
  }

  render() {
    //console.log('PROPS SIGN UP FORM: ',this.props)
    const { error, handleSubmit, pristine, reset, submitting, screenWidth, screenHeight } = this.props
    const { code, text, progress, data }= this.props.authStore.signUp ? this.props.authStore.signUp : {}

    return (
      <div className="signin-form" style={{background: '#d6e4e0'}}>
        <div style={{margin: 'auto', padding: '3em', border: '1px solid rgba(100,100,100,.1)', borderRadius: '.5em', background: 'rgba(255,255,255,.3)'}}>
          <div style={{display: 'flex'}}>
            <div style={{width: '15em', margin: '0 2em 0 0'}}>
              <ReactSVG src={stream_logo}/>
            </div>
            <div style={{flex: '1'}}>
              <div style={{margin: '1em 0 1em 0'}}>
                <Header as='h3' color='blue'> 
                  <Header.Content>
                    {this.props.package} Sign up here !
                    <Header.Subheader>Valid email and mobile phone is required for further transaction validation.</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                <Form.Group widths='16'>
                  <Field name='package' component={SelectField}
                  width={8}
                  options={packageOptions}
                  label='Service Package'
                  placeholder='Package Personal / Enterprise'
                  validate={[required]} />
                  {this.props.package &&<Field name='pin' component={InputField}
                  width={8}
                  label='Ref. Code'
                  maxLength={6}
                  icon='adjust'
                  placeholder={'Code for '+ this.props.package +' package'}
                  validate={[required, number]} />}
                </Form.Group>
                <Divider />
                <Form.Group widths='equal'>
                  <Field name='account' component={InputField}
                  label='Handphone'
                  icon='mobile alternate'
                  placeholder='No. Handphone'
                  validate={[required, phoneNumber]} />
                  {/* <Field name='voucher' component={InputField}
                  label='Reference Code'
                  icon='tag'
                  placeholder='Reference Code'
                  validate={[required]} /> */}
                  <Field name='email' component={InputField}
                  label='Email'
                  icon='mail outline'
                  placeholder='Email'
                  validate={[required, email]} />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='userPasswd' component={InputField}
                  type='password'
                  label='Password'
                  icon='unlock alternate'
                  placeholder='Password'
                  validate={[required]} />
                  <Field name='confPasswd' component={InputField}
                  type='password'
                  label='Retype Password'
                  icon='unlock alternate'
                  placeholder='Retype password for confirmation'
                  validate={[required,confirmedPassword]} />
                </Form.Group>
                <Divider hidden />
                <div style={{display: 'flex'}}>
                  <div style={{flex: 1, marginRight: '2em'}}>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Field name='agree' component={Checkbox}
                        label={<b>Check here, to state that you have read and agreed to "Terms and Conditions"</b>} 
                        validate={required}/>
                        {/* <AccordionTermsConditions /> */}
                      </Form.Field>
                    </Form.Group>
                  </div>
                  <div>
                    {/* <Form.Group widths='equal'>
                      <Form.Field style={{textAlign: 'right'}}>
                        <Button color='blue' size='large' id='btnSignUp' icon={progress==0 ? true : false}> {progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
                      </Form.Field>
                    </Form.Group> */}
                  </div>
                </div>
                
                
                {progress==1 ? <DismissableMessage style={{boxShadow: 'none', color: code=="00" ? '#fff' : '#fff', background: code=="00" ? '#0a717c' : '#b32800', textAlign: 'center'}} header={'Signup ' + (code=="00" ?  'OK' : 'NOK')} content={text} /> : null}
                {progress==2 ? <DismissableMessage style={{boxShadow: 'none', color: '#fff', background: 'rgb(253, 81, 27)', textAlign: 'center'}} header='Error' content='Network connection failures.' /> : null}

                <Divider hidden />
                <Form.Group widths='equal'>
                  <Form.Field style={{textAlign: 'right'}}>
                    <Button color='blue' size='large' id='btnSignUp' icon={progress==0 ? true : false}> {progress==0 ? <Icon name='notch circle' loading /> : ''} Sign Up</Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      package: selector(state, 'package'),
    }),
  )
  (reduxForm({
    form: 'AuthenticateSignup',	// a unique identifier for this form
  })
  (AuthenticateSignup)
  )
)
