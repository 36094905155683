import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Button, Popup, Divider } from 'semantic-ui-react'

import CampaignSearch from '../../libraries/whatsapp/CampaignSearch'
import CampaignBrowse from '../../libraries/whatsapp/CampaignBrowse';

import CampaignForm from '../../libraries/whatsapp/CampaignForm'

class CampaignBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        campaignId: 0,
        view: 'sheet', 
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }
    setView(v) {
      // console.log(v)
      this.setState({view: v})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      // console.log(this.props.params)
      return(
        <Container fluid>
          {this.props.mode==1 && <Segment>
            {/* <div style={{textAlign: 'center', background: '#ff0', margin: '-1.5em', padding: '.5em'}}>
              <Popup position= 'bottom center' 
                trigger={
                  <Button onClick={this.props.openModal.bind(this,'wizard')}  color='red' size='large' icon labelPosition='left'>
                    <Icon name='plus' />
                    Create
                  </Button>
                }
                content='Simply create your campaign through step by step form wizard.'
                inverted
                on={['hover']}
              />
            </div> */}
            <CampaignSearch mode={mode} setMode={this.setMode.bind(this)} openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} setView={this.setView.bind(this)} view={this.state.view} />
            <CampaignBrowse setMode={this.setMode.bind(this)} openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} view={this.state.view} />
          </Segment>}
          {this.props.mode==2 && <CampaignForm  mode={2} campaignId={(this.props.params && this.props.params.campaignId) || campaignId} setMode={this.setMode.bind(this)} openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />}
        </Container>
      )
    }
}

export default CampaignBody
