import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import {genderOptions, maritalOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField, LabelNumberField, LabelDatePickerField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetContact, getContact,updateContact } from "../../actions/halo/contactAction"
import { contactOmniphone,remarkOmniphone, resetOmniphone } from "../../actions/stream/omniphoneAction"
import { listRemark, listProduct } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ChatboxContactForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    contactStore: state.contactStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),

    resetContact:(objParam) => dispatch(resetContact(objParam)),
    getContact:(objParam) => dispatch(getContact(objParam)),
    updateContact:(objParam) => dispatch(updateContact(objParam)),

    resetOmniphone:(objParam) => dispatch(resetOmniphone(objParam)),
    remarkOmniphone:(objParam) => dispatch(remarkOmniphone(objParam)),
  }
}

class ChatboxContactForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      isRemark: true,
      lastContact: null,
    }
  }

  componentDidMount(){
    const { status, conversation }= this.props;
    // console.log('!!! CONVERSATION', conversation)

    this.props.resetContact(this.props.mode)
    this.props.getContact({contactId: conversation.contactId})

    if (conversation) {
      // console.log('DIDMOUNT', status, 'visitorId: ', conversation.visitorId, 'targetId: ', conversation.targetId);

      // this.props.listRemark({campaignId: conversation.campaignId});
      this.props.listRemark({remarkType: 'visitor'});
      this.props.listProduct();

      this.props.dispatch(change('ChatboxContactForm', 'remark', conversation.remark));
      this.props.dispatch(change('ChatboxContactForm', 'progress', conversation.progress));

      if (conversation.remarks) {
        this.props.dispatch(change('ChatboxContactForm', 'note', conversation.remarks.note));
        this.props.dispatch(change('ChatboxContactForm', 'promisedDate', conversation.remarks.promisedDate));
        this.props.dispatch(change('ChatboxContactForm', 'paidDate', conversation.remarks.paidDate));
        this.props.dispatch(change('ChatboxContactForm', 'paymentChannel', conversation.remarks.paymentChannel));
        this.props.dispatch(change('ChatboxContactForm', 'adjustmentAmount', conversation.remarks.adjustmentAmount));
        this.props.dispatch(change('ChatboxContactForm', 'adjustmentDuration', conversation.remarks.adjustmentDuration));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { status, conversation }= this.props;
    const { progress, code, text }= this.props.contactStore.updateContact!=undefined ? this.props.contactStore.updateContact : {};
    
    if (prevProps.conversation && this.props.conversation && prevProps.conversation.contactId!=this.props.conversation.contactId) {
      this.props.resetContact(this.props.mode)
      this.props.getContact({contactId: conversation.contactId})
    }

    if (progress==1 && code=='00') {
      setTimeout(()=>{
        // this.props.resetOmniphone();
        if (this.props.closeChatbox) {
          this.props.closeChatbox();
        } else if (this.props.closeModal()) {
          this.props.closeModal();
        }
      }, 1000)
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { status, conversation }= this.props;
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.updateContact({
      contactId: values.contactId,
      name: values.name,
      email: values.email,
      gender: values.gender,
      birthDate: values.birthDate,
      keyword: values.keyword,
      address: values.address,
      city: values.city,
      province: values.province,})

    this.props.remarkOmniphone({
      contactId: values.contactId,
      messagingId: conversation && conversation.messagingId,
      campaignType: conversation && conversation.campaign && conversation.campaign.campaignType,

      remark: values.remark,
      progress: listRemark.progress[values.remark],
      note: values.note || (conversation && conversation.remarks && conversation.remarks.note),

      promisedDate: values.promisedDate || (conversation && conversation.remarks && conversation.remarks.promisedDate),
      paidDate: values.paidDate || (conversation && conversation.remarks && conversation.remarks.paidDate),
      paymentChannel: values.paymentChannel || (conversation && conversation.remarks && conversation.remarks.paymentChannel),
      adjustmentAmount: values.adjustmentAmount || (conversation && conversation.remarks && conversation.remarks.adjustmentAmount),
      adjustmentDuration: values.adjustmentDuration || (conversation && conversation.remarks && conversation.remarks.adjustmentDuration),
      product: values.product || (conversation && conversation.remarks && conversation.remarks.product),
      value: values.value || (conversation && conversation.remarks && conversation.remarks.value),

      voices: null, appointments: null })

    // this.props.endConversation(media, 
    //   (media=='Chat' && {
    //     command: 'end_by_agent',
    //     contactId: this.props.contactId,
    //     agentId: this.props.agentId,
    //     clientId: this.props.clientId,
    //   })
    // )
  }

  setMode() {
    this.setState({isRemark: !this.state.isRemark})
  }

  render() {
    const { tab }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.contactStore.updateContact!=undefined ? this.props.contactStore.updateContact : {}) : (this.props.contactStore.createContact!=undefined ? this.props.contactStore.createContact : {}))
    const { data }= this.props.contactStore.getContact!=undefined ? this.props.contactStore.getContact : {}

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    const listProduct= (this.props.masterStore.listProduct!=undefined && this.props.masterStore.listProduct.progress=='1' ? this.props.masterStore.listProduct.data : [])
    
    return (
      <div style={{margin: '0 0em', height: '100%'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} style={{height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{...(!this.state.isRemark ? {flex: 1} : {overflow: 'hidden'}), padding: '1em 1em'}}>
              {/* <div style={{textAlign: 'center'}}>
                <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png' size='small' circular style={{display: 'inline-block'}}/>
                <Icon name='user circle' color='yellow' style={{fontSize: '8em', margin: '0'}} />
                <Header as='h4'>
                  <Header.Content>
                    {this.props.params && ((this.props.params.target && this.props.params.target.name) || (this.props.params.customer && this.props.params.customer.name) || (this.props.params.contact && this.props.params.contact.name) || 'Guest / Contact')}
                    <Header.Subheader>{this.props.params && ((this.props.params.target && 'Campaign target contact') || (this.props.params.customer && 'Customer data') || (this.props.params.contact && 'Public contact'))}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div> */}
              <div style={{textAlign: 'center', marginBottom: '2em'}}>
                <Header as='h3' icon>
                  <Icon name='address book' style={{fontSize: '.8em'}} circular inverted color='brown' />
                  <Header.Content>
                    Saved Contact
                    <Header.Subheader>Personal data and remark/feedback update</Header.Subheader>
                  </Header.Content>
                </Header>
                <Divider style={{margin: '.3em 0'}}/>
                <span>Ingestion is available from <b>Secured Local Relay API</b> for enhance security level, contact your Admin to connect.</span>
              </div>

              <Form.Group widths='equal'>
                <Field name='name' component={InputField}
                // label='Name'
                validate={required}
                placeholder='Full Name' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='email' component={InputField}
                // label='Email'
                validate={email}
                placeholder='Email (Optional)' />
              </Form.Group>
              {/* <Form.Group widths='equal'>
                <Field name='whatsapp' component={InputField}
                label='Whatsapp'
                validate={[required, phoneNumberID]}
                placeholder='Whatsapp' />
              </Form.Group> */}
              
              {!this.state.isRemark && <>
                <Header as='h5'>
                  <Icon name='id card outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Personal</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='gender' component={SelectField}
                  options={genderOptions}
                  // label='Gender'
                  placeholder='L/P (Optional)' />
                  <Field name='birthDate' component={DatePickerField}
                  // label='Birth Date'
                  placeholder='(Optional)' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='keyword' component={TextAreaField}
                  // label='Description'
                  rows={2}
                  placeholder='Short description (Optional)' />
                </Form.Group>
              
                <Header as='h5'>
                  <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
                  <Header.Content>Address</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='address' component={InputField}
                  placeholder='Address (Optional)' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='city' component={InputField}
                  placeholder='City (Optional)' />
                  <Field name='province' component={InputField}
                  placeholder='Province (Optional)' />
                </Form.Group>
              </>}
              {/* <Form.Group widths='equal'>
                <Field name='country' component={InputField}
                placeholder='Country (Optional)' />
              </Form.Group> */}
            </div>
            <div style={{background: '#eeebea', padding: '0 1em 1em 1em', ...(this.state.isRemark==1 ? {flex: 1} : {height: '10em', overflow: 'hidden'})}}>
              <Divider horizontal><Icon name={this.state.isRemark==1 ? 'arrow circle down' : 'arrow circle up'} color='red' style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setMode.bind(this)} /></Divider>
              <Header as='h5' style={{marginTop: '0'}}>
                <Icon name='clone outline' style={{fontSize: '1em'}}/>
                <Header.Content><b>Feedbacks & Remarks</b></Header.Content>
              </Header>
              {this.state.isRemark && <>
                <Form.Group widths='equal'>
                  <Field name='remark' component={SelectField}
                  options={listRemark.options||[]}
                  validate={[required]}
                  label='Remark'
                  placeholder='Remarks' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='product' component={SelectField}
                  options={listProduct||[]}
                  label='Ref. Product'
                  placeholder='Service / product preference' />
                </Form.Group>
                {/* <Form.Group widths='equal'>
                  <Field name='result' component={LabelNumberField}
                  label='Rp.'
                  caption= 'Opportunity Value'
                  placeholder='Result / Value' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='expired' component={LabelDatePickerField}
                  label='Expired'
                  // caption='Valid Until'
                  placeholder='Valid Date' />
                </Form.Group> */}
                <Form.Group widths='equal'>
                  <Field name='note' component={TextAreaField}
                  label='Note / Information'
                  placeholder='Additional information' />
                </Form.Group>
              </>}
            </div>
            <div style={{background: '#f1f1f1', padding: '1em 1em', textAlign: 'right'}}>
              {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Contact' : 'Insert Contact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              
              <Button type='reset' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.contactStore.getContact!=undefined && state.contactStore.getContact.progress==1 ? state.contactStore.getContact.data : {}),
      formValues: getFormValues('ChatboxContactForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      name: selector(state, 'name'),
      mobile: selector(state, 'mobile'),
      email: selector(state, 'email'),
    }),
    { load: getContact }
  )
  (reduxForm({
    form: 'ChatboxContactForm',	// a unique identifier for this form
  })(ChatboxContactForm)
  )
)
