import axios from "axios"
import { urlAdmin as urlAPI } from "../_api"

export function resetWacommand(objParam) {
  return {
      type: 'WACOMMAND_RESET',
      payload: objParam
  }
}

export function sendmessageWacommand(objParam) {
  return {
      type: 'WACOMMAND_SENDMESSAGE',
      payload:
        axios.post(
          urlAPI + 'wacommand/sendmessage',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function replymessageWacommand(objParam) {
  return {
      type: 'WACOMMAND_REPLYMESSAGE',
      payload:
        axios.post(
          urlAPI + 'wacommand/replymessage',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function synchcontactWacommand(objParam) {
  return {
      type: 'WACOMMAND_SYNCHCONTACT',
      payload:
        axios.post(
          urlAPI + 'wacommand/synchcontact',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function synchgroupWacommand(objParam) {
  return {
      type: 'WACOMMAND_SYNCHGROUP',
      payload:
        axios.post(
          urlAPI + 'wacommand/synchgroup',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}

export function newchatWacommand(objParam) {
  return {
      type: 'WACOMMAND_NEWCHAT',
      payload:
        axios.post(
          urlAPI + 'wacommand/newchat',
          JSON.stringify(objParam),
          {
            headers: {
              "Authorization": localStorage.getItem('tokenAuth'),
              "Content-Type": "application/json",
              "Accept": "*/*",
            }
          }
      ).catch(function(error){ //handle network error, return axios always 1
        // console.log('ERROR: ', error)
      })
  }
}
