import React, { Component } from 'react';
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react';

import DismissableMessage from '../common/DismissableMessage';
import IconToggle from '../common/IconToggle';
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData';

import { SubmissionError, Field, reduxForm, getFormValues, formValueSelector } from 'redux-form';
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField } from '../validation/componentrsui';
import { required, phoneNumberID, number, minValue, email } from '../validation/validation';

import { connect } from "react-redux";
import { resetWacontact, getWacontact,updateWacontact, createWacontact } from "../../actions/whatsapp/wacontactAction"

//CONNECT REDUX STORE
const selector = formValueSelector('WacontactForm')

const mapStateToProps = (state, props) => {
  return {
    wacontactStore: state.wacontactStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetWacontact:(objParam) => dispatch(resetWacontact(objParam)),
    getWacontact:(objParam) => dispatch(getWacontact(objParam)),
    createWacontact:(objParam) => dispatch(createWacontact(objParam)),
    updateWacontact:(objParam) => dispatch(updateWacontact(objParam)),
  }
}

class WacontactForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetWacontact(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getWacontact({contactId: this.props.contactId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.wacontactStore.updateWacontact!=undefined ? this.props.wacontactStore.updateWacontact : {}) : (this.props.wacontactStore.createWacontact!=undefined ? this.props.wacontactStore.createWacontact : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateWacontact({...values}) : this.props.createWacontact({...values})
    
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.wacontactStore.updateWacontact!=undefined ? this.props.wacontactStore.updateWacontact : {}) : (this.props.wacontactStore.createWacontact!=undefined ? this.props.wacontactStore.createWacontact : {}))
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              caption='Enable Now !'
              label='Enabled / Disabled'
              placeholder='Status enable/disabled' />
              <Field name='sentVCard' component={CheckboxField}
              toggle
              caption='Sent VCard'
              label='Yes / No'
              placeholder='Sent VCard' />
            </Form.Group>
            <Form.Group widths='equal' style={{marginTop: '1.5em'}}>
              <Field name='isInvalid' component={CheckboxField}
              toggle
              caption='Invalid Contact !'
              label='Invalid / Valid'
              placeholder='Status Invalid/Valid' />
              <Field name='isBlocked' component={CheckboxField}
              toggle
              caption='Blocked Contact !'
              label='Blocked / Accept'
              placeholder='Status Blocked/Accept' />
            </Form.Group>
            
            <Divider hidden />
            <Form.Group widths='16'>
              <Field name='whatsapp' component={InputField}
              width={8}
              label='Whatsapp'
              validate={[required, phoneNumberID]}
              placeholder='Whatsapp' />
              <Field name='email' component={InputField}
              width={8}
              label='Email'
              validate={email}
              placeholder='Email (Optional)' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='id card outline' style={{fontSize: '1em'}}/>
              <Header.Content>Personal</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              width={8}
              label='Name'
              validate={required}
              placeholder='Full Name' />
              <Field name='gender' component={SelectField}
              width={4}
              options={genderOptions}
              label='Gender'
              placeholder='L/P (Optional)' />
              <Field name='birthDate' component={DatePickerField}
              width={4}
              label='Birth Date'
              placeholder='(Optional)' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='keyword' component={InputField}
              label='Description'
              placeholder='Short description (Optional)' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Address</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              placeholder='Address (Optional)' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='city' component={InputField}
              placeholder='City (Optional)' />
              <Field name='province' component={InputField}
              placeholder='Province (Optional)' />
              <Field name='country' component={InputField}
              placeholder='Country (Optional)' />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Contact' : 'Insert Contact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.wacontactStore.getWacontact!=undefined && state.wacontactStore.getWacontact.progress==1 ? state.wacontactStore.getWacontact.data : {isEnabled: '1'}),
      formValues: getFormValues('WacontactForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getWacontact }
  )
  (reduxForm({
    form: 'WacontactForm',	// a unique identifier for this form
  })(WacontactForm)
  )
)
