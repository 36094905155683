import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { Accordion, Tab, Input, Card, Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button, Icon, Table, Popup, Progress } from 'semantic-ui-react'

import ModalContainer from '../common/ModalContainer';

import { whatsappOptions, resultOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, AudioUploadField, InputField, LabelInputField, CheckboxField, SelectField, RadioField, TextAreaField, WysiwygField, NumberField, SliderField } from '../validation/componentrsui'
import { required, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { senderEnergy, senderQRCode } from "../../actions/halo/settingAction"
import { qrcodeWaserver } from "../../actions/whatsapp/waserverAction"

var qrcodeInterval= null;

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    settingStore: state.settingStore,
    waserverStore: state.waserverStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    senderEnergy:(objParam) => dispatch(senderEnergy(objParam)),
    senderQRCode:(objParam, urlCustom) => dispatch(senderQRCode(objParam)),

    qrcodeWaserver:(objParam, urlCustom) => dispatch(qrcodeWaserver(objParam, urlCustom)),
  }
};


class SenderBrowse extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  componentDidMount() {
    this.props.senderEnergy({settingId: this.props.settingId})
  }

  componentDidUpdate(prevProps, prevState) {
    const { data }= (this.props.waserverStore.qrcodeWaserver!==undefined ? this.props.waserverStore.qrcodeWaserver : {})

    if (data && (this.state.qrcodeData!=data.qrcodeData || this.state.authenticateStamp!=data.authenticateStamp)) {
      this.setState({
        authenticateStamp: data.authenticateStamp,

        qrcodeStamp: data.qrcodeStamp,
        qrcodeData: data.qrcodeData,
        qrcodeImage: data.qrcodeImage,
      })
    }
    
    if (this.state.modal) {
      if (data && data.authenticateStamp) {
        setTimeout(()=>{
          this.setState({
            modal: null
          })
        }, 10000)
        
        this.props.resetWaserver();
      } else {
        if (!prevState.modal || prevState.modal!=this.state.modal) {
          if (qrcodeInterval) {
            clearInterval(qrcodeInterval);
          }
          this.props.qrcodeWaserver({
            ...this.state.params
          }, this.state.params.container +'/admin/api/v1/whatsapp/qrcode')
          qrcodeInterval= setInterval(()=>{
            this.props.qrcodeWaserver({
              ...this.state.params
            }, this.state.params.container +'/admin/api/v1/whatsapp/qrcode')
          }, 1000)
        }
      }
    } else {
      if (qrcodeInterval) {
        clearInterval(qrcodeInterval);
      }
    }
  }
  
  openModal(value, params) {
    // console.log(params)
    this.setState({
      modal: value,
      params: params,
    })
  }

  closeModal(params) {
    // console.log(params)
    this.setState({
      modal: null,
      params: params,
    })

    setTimeout(()=>{
      const { search, paging }= (this.props.settingStore.senderEnergy!==undefined ? this.props.settingStore.senderEnergy : {})
      this.props.senderEnergy({search: {...search}, paging: paging})
    }, 3000)
  }

  viewQRCode() {
    return(
      <div style={{background: '#fff', padding: '4em 0', textAlign: 'center'}}>
        <div style={{
        background: (!this.state.qrcodeImage && !this.state.authenticateStamp) ? '#000' : '#eee', 
        // background: '#eee url("'+ (this.state.params.container +'/static/'+ (data && data.qrcodeImage)) +'") no-repeat fixed center',
        height: '264px', width: '264px', margin: '2em auto 4em auto'}}>
          {this.state.qrcodeImage && <Image src={this.state.params.container +'/static/'+ this.state.qrcodeImage} />}
          {this.state.qrcodeImage==null && !this.state.authenticateStamp && <Header as='h2' style={{paddingTop: '30%'}} inverted>
            <Header.Content>
              NOT <br />VERIFIED
              <Header.Subheader>QR CODE NOT AVAILABLE</Header.Subheader>
            </Header.Content>
          </Header>}
          {this.state.qrcodeImage==null && this.state.authenticateStamp && <Header as='h2' color='blue' style={{paddingTop: '30%'}}>
            <Header.Content>
              YOU ARE <br /><b>CONNECTED</b>
            </Header.Content>
          </Header>}
        </div>
        {/* {this.state.qrcodeData} */}
        <Header as='h3' color='blue'>
          <Header.Content>
            SCAN QR CODE HERE!
            <Header.Subheader>Whatsapp verification and authentication process.</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    )
  }

  render() {
    const { data, search, paging }= (this.props.settingStore.senderEnergy!==undefined ? this.props.settingStore.senderEnergy : {})

    return(
      <div>
        <Table unstackable selectable columns='16' celled striped basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell width='6'><Header color='blue' as='h5'>MOBILE NUMBER</Header></Table.HeaderCell>
              <Table.HeaderCell width='10'><Header color='blue' as='h5'>ENERGY LEVEL</Header></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { data && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
            { data ? data.map((item,key)=>{
                return(
                  <Table.Row key={++key}>
                    <Table.Cell>{key}</Table.Cell>
                    <Table.Cell>
                      <Header as='h4'>
                        {item.mobile.replace('62', '0') +'_'+ item.serverId +'@'+ item.container}
                        <Header.Subheader><b><i>{item.params && item.params.scope}</i></b> - <b>{(item.levelEnergy==-1 && 'Disconnected') || 'Connected'}</b> / <b>{item.params && item.params.gateway}</b></Header.Subheader>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{display: 'flex'}}>
                        <div style={{flex: '1'}}>
                          {item.levelEnergy>=0 && <Progress color={(item.levelEnergy<=30 && 'red') || (item.levelEnergy<=50 && 'yellow') || (item.levelEnergy<=75 && 'green') || 'blue'} size='large' percent={item.levelEnergy} progress />}
                          {item.levelEnergy<0 && <Progress color={(item.levelEnergy<=30 && 'red') || (item.levelEnergy<=50 && 'yellow') || (item.levelEnergy<=75 && 'green') || 'blue'} disabled size='large' value='' progress='value' />}
                        </div>
                        <div>
                          {item.levelEnergy<0 && item.params && item.params.waserver &&
                            <Button size='big' color='green' icon style={{marginLeft: '.5em', padding: '.6em .5em'}} onClick={this.openModal.bind(this, 'qrcode', {serverId: item.params.waserver.id, account: item.params.waserver.account, container: item.params.waserver.container})}><Icon name='qrcode' /></Button>
                          }
                        </div>
                      </div>
                      
                    </Table.Cell>
                  </Table.Row>
                )
              }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='3' style={{fontSize: '1em'}}>
                <span style={{marginRight: '1em'}}><Icon name='warning sign' color='grey' style={{fontSize: '1.2em'}}/> Lower energy level indicates your number currently has higher blocking rates. It requires number replacement as soon as possible.</span>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        {this.state.modal && <ModalContainer size='small' content={this.viewQRCode()} closeModal={this.closeModal.bind(this)} /> }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SenderBrowse)
