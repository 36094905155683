import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { reportMessaging } from "../../actions/stream/campaignAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reportMessaging:(objParam) => dispatch(reportMessaging(objParam)),
  }
}

class reportMessagingBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remark: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.campaignStore.reportMessaging!==undefined ? this.props.campaignStore.reportMessaging : {})
    this.props.reportMessaging(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.campaignStore.reportMessaging!==undefined ? this.props.campaignStore.reportMessaging : {})
    this.props.reportMessaging({search: {
      flow: null,
      channelType: 'WA',
      campaignId: this.props.params && this.props.params.campaignId,
      keyword: null,
      transactDate: format(new Date(), 'yyyy-MM-01 00:00:00'),
    }, paging: paging})
  }

  render() {
    //console.log(this.props.campaignStore.reportMessaging)
    const { expand, remark }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.campaignStore.reportMessaging!==undefined ? this.props.campaignStore.reportMessaging : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div className='noscroll' style={{flex: '1', padding: '0', maxWidth: '100%', overflowX: 'scroll'}}>
          <div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '15em'}}>
                    <Header as='h5' color='blue'>RECIPIENT</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '10em'}}>
                    <Header as='h5' color='blue'>WA NUMBER</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '10em'}}>
                    <Header as='h5' color='blue'>SENDER</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '10em'}}>
                    <Header as='h5' color='blue'>FLOW</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>SENT</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>DLVRD</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>READ</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>REPLIED</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>INCOMING</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>OUTGOING</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>TICKET</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>OPENED</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '8em'}}>
                    <Header as='h5' color='blue'>CLOSED</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '15em'}}>
                    <Header as='h5' color='blue'>REMARK</Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{minWidth: '15em'}}>
                    <Header as='h5' color='blue'>AGENT</Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={7} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                { data!==undefined ? data.map((item,key)=>{
                    return(
                      <Table.Row key={++seq}>
                        <Table.Cell>{seq}</Table.Cell>
                        <Table.Cell>{(item.target && item.target.name) || (item.customer && item.customer.name) || (item.visitor && item.visitor.name) || (item.contact && item.contact.name) || 'Guest/Visitor'}</Table.Cell>
                        <Table.Cell>{item.terminating && item.terminating.replace('62', '0')}</Table.Cell>
                        <Table.Cell>{item.originating && item.originating.replace('62', '0')}</Table.Cell>
                        <Table.Cell>{item.flow==1 ? (item.scriptId!=0 ? 'Outbound' : 'Quick Chat') : 'Inbound'}</Table.Cell>
                        <Table.Cell>{item.sentStamp!=null && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</Table.Cell>
                        <Table.Cell>{item.deliveredStamp!=null && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</Table.Cell>
                        <Table.Cell>{item.readStamp!=null && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</Table.Cell>
                        <Table.Cell>{item.receivedStamp!=null && format(parse(item.receivedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</Table.Cell>

                        <Table.Cell>{item.totIncoming}</Table.Cell>
                        <Table.Cell>{item.totOutgoing}</Table.Cell>

                        <Table.Cell>{item.ticketNo} / {item.ticketStatus}</Table.Cell>
                        <Table.Cell>{item.openStamp!=null && format(parse(item.openStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</Table.Cell>
                        <Table.Cell>{item.closedStamp!=null && format(parse(item.closedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</Table.Cell>

                        <Table.Cell>{item.remark}</Table.Cell>
                        <Table.Cell>{item.agent && item.agent.name}</Table.Cell>
                        
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
              </Table.Body>
            </Table>
          </div>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reportMessagingBrowse)
