import React from 'react';
import { Container, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Accordion, Divider, Segment, Label, Menu, List } from 'semantic-ui-react'

import ModalMessage from '../../libraries/common/ModalMessage'
import ModalContainer from '../../libraries/common/ModalContainer';
import InformationBody from '../../pages/whatsapp/InformationBody';

import AuthenticateMenu from '../../libraries/whatsapp/AuthenticateMenu'
import AuthenticateSignin from '../../libraries/whatsapp/AuthenticateSignin'
import AuthenticateSignup from '../../libraries/whatsapp/AuthenticateSignup';
import AuthenticatePassword from '../../libraries/whatsapp/AuthenticatePassword';

class AuthenticateBody extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      key: 'signin'
    }
  }

  openMenu(key) {
    this.setState({
      key: key
    })
  }
  openModal(modal) {
    this.setState({
      modal: modal
    })
  }

  closeModal() {
    this.setState({
      modal: ''
    })
  }
  
  render() {
    const { key, modal }= this.state
    const { screenWidth, screenHeight } = this.props

    return(<>
      <div style={{minHeight: this.props.screenHeight}}>
        <div style={{margin: '0 auto', maxWidth: 650}}>
          {/* <Container style={{border: 'none'}}>
            <div style={{textAlign: 'center'}}>
              <AuthenticateMenu openMenu={this.openMenu.bind(this)} menu= {this.state.key}/>
            </div>
          </Container> */}

          <Container>
            <div style={{marginTop: screenHeight/8, padding: '1em .2em'}}>
              <Header as='h1' style={{fontSize: '3em', display: 'block', textAlign: 'left', color: '#005179'}}>
                <Header.Content>
                <b>Interactive</b> Experience
                </Header.Content>
              </Header>
              <Header as='h3' style={{fontWeight: '400', display: 'block', margin: '1em 0 0 0', textAlign: 'left'}}>
                <Header.Content>
                Simplify content management with readable real time impression, through the most popular messaging platform, Whatsapp Business Account.
                </Header.Content>
              </Header>
              {/* <Header as='h4' style={{fontWeight: '400', display: 'block', margin: '.5em 0 0 0', textAlign: 'left'}}>
                <Header.Content>
                  Start <Label onClick={this.openMenu.bind(this,'signin')} as='a' style={{fontSize: '1em', padding: '.2em .5em .3em .5em'}} color='blue'>Sign In</Label> if you had account, or register and <Label onClick={this.openMenu.bind(this,'signup')} style={{fontSize: '1em', padding: '.2em .5em .3em .5em'}} as='a' color='green'>Sign Up</Label> to start new experience messaging campaign.
                </Header.Content>
              </Header> */}
            </div>
            
            {key==='signin' && <AuthenticateSignin screenHeight={this.props.screenHeight} screenWidth={this.props.screenWidth} openMenu={this.openMenu.bind(this)} />}
            {key==='signup' && <AuthenticateSignup screenHeight={this.props.screenHeight} screenWidth={this.props.screenWidth} openMenu={this.openMenu.bind(this)} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)}/>}
            {key==='password' && <AuthenticatePassword screenHeight={this.props.screenHeight} screenWidth={this.props.screenWidth} openMenu={this.openMenu.bind(this)} />}
          </Container>
          {/* <div style={{textAlign: 'center', width: '450px', margin: 'auto'}}>
            <Divider hidden/>
            <Header as='h5'>
              <b>Halo Pagi!</b> Digital Communication 2.3.01
              <Header.Subheader>
                Trademarks and Copyrights by @Halo Pagi! 2023
              </Header.Subheader>
            </Header>
          </div> */}
        </div>
      </div>


      <div style={{display: 'flex', background: '#fafafa', width: '100%', height: 60, padding: '1em 2em'}}>
        <div style={{flex: '1'}}>
          {false &&
          <Container fluid style={{padding:'2em 1em', background: 'rgba(100,100,100,0)'}}>
            <List horizontal divided link inverted>
              <List.Item as='a' onClick={this.openModal.bind(this,'contactUs')}>
                Contact Us
              </List.Item>
              <List.Item as='a' onClick={this.openModal.bind(this,'termCondition')}>
                Terms and Conditions
              </List.Item>
              {false &&
              <List.Item as='a' onClick={this.openModal.bind(this,'privacyPolicy')}>
                Privacy Policy
              </List.Item>}
            </List>
          </Container>}
        </div>
        <div>
          {/* <CredentialWidget /> */}
          <Header as='h5'>
            <Header.Content>
              <b><i>Wasap!</i></b> Interactive Conversation 2.3.01
              <Header.Subheader><b>Halo Pagi!</b> - Digital Communication Platform 2023</Header.Subheader>
            </Header.Content>
          </Header>
        </div>
      </div>

      {modal==='personal' && <ModalContainer size='small' content={<InformationBody mode='personal' screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
      {modal==='enterprise' && <ModalContainer size='small' content={<InformationBody mode='enterprise' screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} /> }
      </>
    );
  }
} export default AuthenticateBody
