
import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
  Popup,
} from 'semantic-ui-react'

import incomingtone from '../../sound/incoming.mp3'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, formatDistance, formatRelative, } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"

import { connect } from "react-redux"
import { checkToken} from "../../actions/auth/authAction"
import { inboundMessaging, statusMessaging } from "../../actions/stream/messagingAction"
import { socketStatus, socketWhatsappStatistic } from "../../actions/socket/socketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    
    socketStore: state.socketStore,
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    inboundMessaging:(objParam) => dispatch(inboundMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),

    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketWhatsappStatistic:(objParam) => dispatch(socketWhatsappStatistic(objParam)),
  }
}

const incoming= new Audio(incomingtone);
class IncomingTone {
  play() {
    incoming.loop= false;
    incoming.muted= false;
    incoming.play();  
  }
  
  stop() {
    incoming.pause();
  }

  mute() {
    incoming.muted= true;
  }
}

class MessagingBlendedRecent extends Component {
  constructor(props) {
    super(props)
    this.state= {
      content: false,
      expand: false,
      messagingId: 0,
      recent: null,
      messages: new Map(),
      size: 50,
    }

    this.incoming= new IncomingTone();
  }

  componentDidUpdate(prevProps) {
    const {messages}= this.state;
    const {blendedMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})
    // var play= false;
    // this.incoming.play()

    if (blendedMessage && (!this.state.recent || this.state.recent!=blendedMessage.recent)) {
      blendedMessage && blendedMessage.messages && blendedMessage.messages.map(item=>{
        messages.get(item.messagingId) && messages.delete(item.messagingId);
        messages.set(item.messagingId, item);
      })

      if (messages.size>this.state.size) {
        [...messages.keys()].map((key,i)=>{
          if (i<(messages.size-this.state.size)) {
            messages.get(key) && messages.delete(key);
          }
        })
      }
      this.setState({
        recent: blendedMessage.recent,
        messages: messages,
      })
    }
    // console.log(blendedMessage)
  }

  render() {
    //console.log(this.props.messagingStore.inboundMessaging)
    const token= this.props.authStore.checkToken && this.props.authStore.checkToken.data ? this.props.authStore.checkToken.data : {};
    const { expand, content, recent, messages }= this.state;
    const { screenWidth, screenHeight }= this.props;

    var statusMesg= 0;

    return (
      <div style={{display: 'flex', padding: '0 0 0 0', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Feed>
              { messages && messages.size==0 && <div style={{padding: '.5em 0'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></div>}
              { messages ? [...messages.keys()].map((key, i)=>{
                  const item= messages.get(key);

                  if (item.conversations[item.conversations.length-1] && item.conversations[item.conversations.length-1].message) {
                    const mesg= item.conversations[item.conversations.length-1];
                    if (mesg.sender=='agent') {
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.sentStamp_) {
                        statusMesg= 1; //sent
                      }
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.deliveredStamp_) {
                        statusMesg= 2; //delivered
                      }
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.readStamp_) {
                        statusMesg= 3; //read
                      }
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.clickStamp_) {
                        statusMesg= 4; //clicked
                      }
                    } else {
                      if (item.receivedStamp_) {
                        statusMesg= 5; //unread
                      }
                    }
                  }

                  if (token && (token.groupId!='Agent' || (item.agentId  && item.agentId==token.userId) || !item.agentId)) {
                    return(
                      <Feed.Event key={i} style={{padding: '1em .5em', borderRadius: '.3em', background: i%2==0 ? '#f8f8f8' : '#efefef', borderTop:i!=0 ? '1px solid #fff' : 'none', borderBottom:i!=(messages.size-1) ? '1px solid rgba(100, 100, 100, .1)' : 'none'}}>
                        <Feed.Content>
                          <Feed.Summary style={{marginBottom: '.5em'}}>
                            <div style={{display: 'flex'}}>
                              <div style={{width: '3em', height: '3em', background: '#ccc', borderRadius: '2em', marginRight: '.5em', overflow: 'hidden'}}>
                                {/* <Feed.Label style={{marginTop: '1em'}} image='https://www.kindpng.com/picc/m/576-5768791_avatar-png-image-with-transparent-background-ninja-avatar.png' /> */}
                                <Image src='https://www.kindpng.com/picc/m/576-5768791_avatar-png-image-with-transparent-background-ninja-avatar.png' style={{width: '100%', height: '100%'}}/>
                              </div>
                              <div style={{flex: '1'}}>
                                <Label as='a' style={{textTransform: 'capitalize', fontSize: '1.1em', padding: '.3em .5em', borderRadius: '1em', color: '#b900b4'}} onClick={this.props.openChatbox ?  this.props.openChatbox.bind(this, item) : this.props.openModal.bind(this, 'chatbox-blended', item)}>
                                  {(item.target && item.target.name) || (item.customer && item.customer.name) || (item.visitor && item.visitor.name) || (item.contact && item.contact.name) || (item.terminating && (item.terminating.substring(0,5).replace('62', '0') +'XXXX'+ item.terminating.substring(item.terminating.length-4,item.terminating.length))) || 'Guest / Visitor'}
                                </Label>
                                <div style={{fontSize: '.8em', paddingLeft: '.7em', fontWeight: '400'}}>
                                  {(item.target && 'Campaign Target') || (item.customer && 'Customer Information') || (item.visitor && 'Public Visitor') || (item.contact && 'Contact Address') || 'No Contact'}
                                  <span style={{marginLeft: '1em', textTransform: 'capitalize', color: statusMesg==5 ? '#c31' : '#555', fontWeight: 'bold', fontSize: '1.1em'}}>{
                                    item.receivedStamp && formatDistance(parse(item.receivedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())
                                    // item.receivedStamp && formatRelative(parse(item.receivedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())
                                  }</span>
                                </div>
                              </div>
                              <div>
                                {token && token.groupId=='Client' && <Dropdown icon='ellipsis vertical' inline pointing='right'>
                                  <Dropdown.Menu style={{zIndex: 999999}}>
                                    {/* <Dropdown.Item text='Reply' style={{fontSize: '.9em', fontWeight: 'bold'}} /> */}
                                    <Dropdown.Item text='Transfer' style={{fontSize: '.9em', fontWeight: 'bold'}} onClick={this.props.openModal.bind(this, 'transfer', item)}/>
                                    {/* <Dropdown.Item text='History' style={{fontSize: '.9em', fontWeight: 'bold'}} /> */}
                                    <Divider style={{margin: '.2em 0'}}/>
                                    <Dropdown.Item disabled text='Block Number !' style={{fontSize: '.9em', fontWeight: 'bold'}} onClick={this.props.openModal.bind(this, 'blocking', item)}/>
                                  </Dropdown.Menu>
                                </Dropdown>}
                              </div>
                            </div>
                          </Feed.Summary>
                          <Feed.Extra>
                            <div style={{fontWeight: statusMesg==5 && item.conversations.length==1 ? 'bold' : 'normal'}}>
                              {item.conversations && item.conversations[0] && item.conversations[0].sender=='agent' ? <Icon color='blue' name='angle double left' /> : <Icon color='red' name='angle double right' />} 
                              <span>{(item.conversations && item.conversations[0] && (item.conversations[0].message && item.conversations[0].message.length>40 ? item.conversations[0].message.substr(0,40)+ '...' : item.conversations[0].message)) || (item.content && item.content.length>40 ? item.content.substr(0,40)+ '...' : item.content)}</span>
                            </div>
                            {item.conversations.length>1 && <>
                              <Divider style={{margin: '.3em 0'}} horizontal><Icon name='ellipsis horizontal' color='teal' style={{cursor: 'pointer', margin: '0'}} onClick={this.props.openChatbox ?  this.props.openChatbox.bind(this, item) : this.props.openModal.bind(this, 'chatbox-blended', item)}/></Divider>
                              {item.conversations && item.conversations[item.conversations.length-1] && item.conversations[item.conversations.length-1].sender=='agent' ? <Icon color='blue' name='angle double left' /> : <Icon color='red' name='angle double right' />}
                              <span><b>{(item.conversations && item.conversations[item.conversations.length-1] && (item.conversations[item.conversations.length-1].message && item.conversations[item.conversations.length-1].message.length>40 ? item.conversations[item.conversations.length-1].message.substr(0,40)+ '...' : item.conversations[item.conversations.length-1].message))}</b></span>
                            </>}
                          </Feed.Extra>
                          <div style={{margin: '.5em 0 -.5em 0', padding: '.5em', background: '#f5f5f566'}}>
                            <Feed.Extra>
                              <div style={{display: 'flex'}}>
                                <div style={{flex: 1, paddingRight: '.5em'}}>
                                  <Feed.Date style={{fontWeight: 'bold', fontSize: '.8em', color: '#666'}}>
                                    {(item.clickStamp && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM yy')) || (item.readStamp && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM yy')) || (item.receivedStamp && format(parse(item.receivedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM yy')) || (item.deliveredStamp && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM yy')) || (item.sentStamp && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM yy'))}
                                  </Feed.Date>
                                </div>
                                <div style={{flex: 1, paddingLeft: '.5em'}}>
                                  <Feed.Date style={{fontWeight: 'bold', fontSize: '.8em', color: '#666'}}>
                                    {item.ticketNo || 'No Ticket'}
                                  </Feed.Date>
                                </div>
                                <div style={{width: '1.5em'}} />
                              </div>
                            </Feed.Extra>
                            <Feed.Extra>
                              <div style={{display: 'flex'}}>
                                <div style={{flex: 1, paddingRight: '.5em'}}>
                                  <Feed.Date style={{fontWeight: 'bold', fontSize: '.8em', color: '#0065ac'}}>
                                    By <b>{(item.agent && item.agent.name) || 'No Agent'}</b>
                                  </Feed.Date>
                                </div>
                                <div style={{flex: 1, paddingLeft: '.5em'}}>
                                  <Feed.Date style={{fontWeight: 'bold', fontSize: '.8em', color: '#666'}}>
                                    {<span style={{fontWeight: 'bold', color: '#c31'}}>{(item.conversationStatus==0 && 'Open / ') || (item.conversationStatus==-1 && 'Progress / ') || (item.conversationStatus==1 && 'Closed / ') || ''}</span>} {<span style={{fontWeight: 'bold', color: '#c31'}}>{(item.remark && (item.remark + '-'+ item.progress +'%')) || 'No Status'}</span>}
                                  </Feed.Date>
                                </div>
                                <div style={{width: '1.5em', margin: '-1em 0 0 0'}}>
                                  <Feed.Date>
                                    <Popup content={(statusMesg==5 && 'Reply Now !') || (statusMesg==3 && 'Read') || (statusMesg==2 && 'Delivered') || 'Sent'} position='left center' inverted trigger={
                                      <Icon className={(statusMesg==5 && 'blinkfast') || ''} style={{margin: '0', fontSize: statusMesg==5 ? '1.3em': '1.1em', cursor: 'pointer'}} onClick={this.props.openChatbox ?  this.props.openChatbox.bind(this, item) : this.props.openModal.bind(this, 'chatbox-blended', item)}
                                        name={(statusMesg==5 && 'alarm') || (statusMesg==3 && 'eye') || (statusMesg<=2 && 'check square') || 'times'} 
                                        color={(statusMesg==5 && 'red') || (statusMesg==3 && 'brown') || (statusMesg==2 && 'blue') || 'grey'} />
                                    } />
                                  </Feed.Date>
                                </div>
                              </div>
                            </Feed.Extra>
                          </div>
                        </Feed.Content>
                      </Feed.Event>
                    )
                  } else {
                    return(<></>)
                  }
                }) : <div><Icon name='circle notch' loading />loading ...</div>
              }
          </Feed>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingBlendedRecent)
