import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
  Statistic,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import RadialRechart from '../../chart/RadialRechart';
import PieRechart from '../../chart/PieRechart';

import { connect } from "react-redux"
import { socketStatus, socketRequest, socketTransmit, socketBroadcast, socketAgentStatus, socketSystemHeartbeat, socketSystemOccupancy, socketWhatsappStatistic } from "../../../actions/socket/socketAction"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';

//CONNECT REDUX STORE
const mapStateToProps= (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    socketWhatsappStatistic:(objParam) => dispatch(socketWhatsappStatistic(objParam)),
  }
}


class TicketStatisticWidget extends React.Component {
    constructor(props) {
      super(props)
      this.state= {
        isUnique: false,
        stamp: '',
      };
    }
    
    componentDidUpdate() {
      const {statisticMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})

      if (statisticMessage && statisticMessage.stamp!=this.state.stamp) {
        this.setState({
          stamp: statisticMessage.stamp,
        })
      }
    }

    setUnique() {
      this.setState({
        isUnique: !this.state.isUnique,
      })
    }

    render() {
      const {dark, screenWidth, screenHeight}= this.props
      const {isUnique}= this.state
      const {statisticMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})
      
      return(<>
        <div style={{display: 'flex', minHeight: '100%'}}>
          <div style={{width: 250, background: '#f7f7f7', minHeight: '100%'}}>
            <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: '100%'}}>
              <div style={{marginBottom: '1.5em'}}>
                <Header as='h5'>
                  <b><i>Today!</i> Feedback / Remark</b>
                </Header>
              </div>
              <div style={{flex: '1'}}>
                <Header as='h2' style={{margin: '0'}}>
                  <Icon name='tag' style={{fontSize: '2em', transform: 'rotate(90deg)'}} />
                  <Header.Content>
                    <b>{numeral((statisticMessage && statisticMessage.totTicket) || 0).format('0,0')}</b>
                    <Header.Subheader>
                      Tot. Ticket  
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1'}}>
              </div>
            </div>
          </div>
          <div style={{flex: 1, background: '#f0f0f0', minHeight: '100%', padding: '.5em 0'}}>
            <div style={{display: 'flex', margin: '.5em 1em'}}>
              <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='teal'><b>REMARKED</b></Label>
              </div>
              <div style={{flex: '1'}}>
                <Progress color='teal' size='medium' progress='value' value={(statisticMessage && statisticMessage.totWithRemark) || 0} total={((statisticMessage && statisticMessage.totTicket) || 0)} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
              </div>
            </div>
            <div style={{display: 'flex', margin: '.5em 1em'}}>
              <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='brown'><b>NO REMARK</b></Label>
              </div>
              <div style={{flex: '1'}}>
                <Progress color='brown' size='medium' progress='value' value={(statisticMessage && statisticMessage.totNoRemark) || 0} total={((statisticMessage && statisticMessage.totTicket) || 0)} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
              </div>
            </div>

            <Divider />
            <div style={{display: 'flex', margin: '.5em 1em'}}>
              <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>PROMISED</b></Label>
              </div>
              <div style={{flex: '1'}}>
                <Progress color='olive' size='medium' progress='value' value={(statisticMessage && statisticMessage.totPromised)} total={((statisticMessage && statisticMessage.totTicket || 0))} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
              </div>
            </div>
            <div style={{display: 'flex', margin: '.5em 1em'}}>
              <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>PAID</b></Label>
              </div>
              <div style={{flex: '1'}}>
                <Progress color='olive' size='medium' progress='value' value={(statisticMessage && statisticMessage.totPaid)} total={((statisticMessage && statisticMessage.totTicket) || 0)} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
              </div>
            </div>
          </div>
          <div style={{width: '11em', textAlign:'center', padding: '.5em 0'}}>
            <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
              <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                <b>{(statisticMessage && statisticMessage.totTicket!=0) ? Math.ceil((statisticMessage.totWithRemark || 0)/(statisticMessage.totTicket || 1) * 100) : 0}%</b>
              </Statistic.Value>
              <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>WITH REMARK</Statistic.Label>
            </Statistic>
            <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
              <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                <b>{(statisticMessage && statisticMessage.totTicket!=0) ? Math.ceil((statisticMessage.totNoRemark || 0)/(statisticMessage.totTicket || 1) * 100) : 0}%</b>
              </Statistic.Value>
              <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>NO REMARK</Statistic.Label>
            </Statistic>
          </div>
        </div>
      </>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketStatisticWidget)