import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'
import ChatviewConversation from '../../libraries/whatsapp/ChatviewConversation';

// import ApiForm from '../../libraries/whatsapp/ApiForm'


class ChatviewBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    render() {
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{margin: '1em 1em 1em 1em'}}>
            <ChatviewConversation params={this.props.params} mode={this.props.mode} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.props.openModal}  closeModal={this.props.closeModal} />
          </div>
        </Container>
      )
    }
}

export default ChatviewBody
