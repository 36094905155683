import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Label, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import { channelOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, WysiwygField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetScript, getScript,updateScript, createScript } from "../../actions/halo/scriptAction"
import { listTemplate } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ScriptForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,
    scriptStore: state.scriptStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetScript:(objParam) => dispatch(resetScript(objParam)),
    getScript:(objParam) => dispatch(getScript(objParam)),
    createScript:(objParam) => dispatch(createScript(objParam)),
    updateScript:(objParam) => dispatch(updateScript(objParam)),

    listTemplate:(objParam) => dispatch(listTemplate(objParam)),
  }
}

class ScriptForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetScript(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getScript({scriptId: this.props.scriptId})
    }
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.scriptStore.updateScript!=undefined ? this.props.scriptStore.updateScript : {}) : (this.props.scriptStore.createScript!=undefined ? this.props.scriptStore.createScript : {}))
    if (!modal && progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (modal && progress==1 && code=='00') {
      this.props.closeModal('hide');
      this.props.listTemplate({search: {clientId: this.props.clientId || null}})
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateScript({...values}) : this.props.createScript({...values, ...{clientId: this.props.clientId}})
  }
  
  insertVariable(v) {
    // console.log(v)
    if (this.props.media!='Email') {
      this.props.dispatch(change('ScriptForm', 'script', (this.props.script||'') +' '+ v +' '));
    } else {
      navigator.clipboard.writeText(v)
    }
    // e.target.focus()
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, modal, screenWidth } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.scriptStore.updateScript!=undefined ? this.props.scriptStore.updateScript : {}) : (this.props.scriptStore.createScript!=undefined ? this.props.scriptStore.createScript : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              {this.props.mode==2 &&
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />}
              {this.props.mode!=2 && <Form.Field width={8} />}
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              validate={[required]}
              label='Name'
              placeholder='Script Name' />
              <Field name='media' component={SelectField}
              validate={[required]}
              options={channelOptions()}
              label='Media'
              placeholder='Media Container' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='descript' component={InputField}
              label='Description'
              placeholder='Description' />
            </Form.Group> */}
          </Segment>
          <Segment>
            <Header color='blue' as='h5'>
              <Icon name='code' style={{fontSize: '1em'}}/>
              <Header.Content>Script / Code Snippet</Header.Content>
            </Header>
            <div style={{display: (modal && 'block') ||'flex'}}>
              <div className='variable' style={{width: (modal && 'auto') || '250px', marginTop: '1.6em', marginRight: !modal && '1.3em', padding: '1em 1.5em', background: '#f5f5f5', border: '1px solid #ddd'}}>
                <Header as='h3' color='red'>
                  <Header.Content>
                    ! Dynamic Variable
                    <Header.Subheader>List of target's info variable which you can apply to script.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Label.Group>
                  <Popup header='{stampId}' content='Unique Stamp Id' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{stampId}')} style={{minWidth: '7em'}}>Stamp Id</Label> 
                  } />
                  <Popup header='{custTitle}' content='Customer or target title' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custTitle}')} style={{minWidth: '7em'}}>Cust. Title</Label> 
                  } />
                  <Popup header='{custName}' content='Customer or target name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custName}')} style={{minWidth: '7em'}}>Cust. Name</Label> 
                  } />
                  <Popup header='{billAmount}' content='Bill amount which must paid' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{billAmount}')} style={{minWidth: '7em'}}>Bill Amount</Label>
                  } />
                  <Popup header='{overdueAmount}' content='Penalty or fine amount' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{overdueAmount}')} style={{minWidth: '7em'}}>Penalty Amt.</Label>
                  } />
                  <Popup header='{overdueDay}' content='Payment was late in days' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{overdueDay}')} style={{minWidth: '7em'}}>Late In Days</Label> 
                  } />
                  <Popup header='{billDate}' content='Date when billing generated' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{billDate}')} style={{minWidth: '7em'}}>Billing Date</Label>
                  } />
                  <Popup header='{dueDate}' content='Due date when bill should be paid ' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{dueDate}')} style={{minWidth: '7em'}}>Due Date</Label>
                  } />

                  <Popup header='{service}' content='Subscription product or service information' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{service}')} style={{minWidth: '7em'}}>Subsc. Info</Label>
                  } />
                  <Popup header='{custRef}' content='Customer ref. identity' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{custRef}')} style={{minWidth: '7em'}}>Cust. Ref. Id</Label>
                  } />
                  <Popup header='{contractRef}' content='Contract ref. for customer' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{contractRef}')} style={{minWidth: '7em'}}>Contract Ref.</Label>
                  } />
                  <Popup header='{city}' content='City where customer stays' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{city}')} style={{minWidth: '7em'}}>City</Label>
                  } />
                  
                  <Popup header='{merchantName}' content='Merchant information / name' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{merchantName}')} style={{minWidth: '7em'}}>Merchant</Label>
                  } />

                  <Popup header='{information}' content='General information which important for customers' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{information}')} style={{minWidth: '7em'}}>Free Info</Label>
                  } />
                  
                  <Popup header='{vaBNI}' content='VA BNI' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBNI}')} style={{minWidth: '7em'}}>VA BNI</Label>
                  } />
                  <Popup header='{vaBRI}' content='VA BRI' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBRI}')} style={{minWidth: '7em'}}>VA BRI</Label>
                  } />
                  <Popup header='{vaBSI}' content='VA BSI' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBSI}')} style={{minWidth: '7em'}}>VA BSI</Label>
                  } />
                  <Popup header='{vaBTN}' content='VA BTN' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBTN}')} style={{minWidth: '7em'}}>VA BTN</Label>
                  } />
                  <Popup header='{vaBCA}' content='VA BCA' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaBCA}')} style={{minWidth: '7em'}}>VA BCA</Label>
                  } />
                  <Popup header='{vaMandiri}' content='VA Mandiri' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaMandiri}')} style={{minWidth: '7em'}}>VA Mandiri</Label>
                  } />
                  <Popup header='{vaPermata}' content='VA Permata' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaPermata}')} style={{minWidth: '7em'}}>VA Permata</Label>
                  } />
                  <Popup header='{vaPanin}' content='VA Panin' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaPanin}')} style={{minWidth: '7em'}}>VA Panin</Label>
                  } />
                  <Popup header='{vaNiaga}' content='VA Niaga' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaNiaga}')} style={{minWidth: '7em'}}>VA Niaga</Label>
                  } />
                  <Popup header='{vaMuamalat}' content='VA Muamalat' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaMuamalat}')} style={{minWidth: '7em'}}>VA Muamalat</Label>
                  } />
                  <Popup header='{vaIndomaret}' content='VA Indomaret' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaIndomaret}')} style={{minWidth: '7em'}}>VA Indomaret</Label>
                  } />
                  <Popup header='{vaAlfamart}' content='VA Alfamart' trigger={
                    <Label as='a' onClick={this.insertVariable.bind(this, '{vaAlfamart}')} style={{minWidth: '7em'}}>VA Alfamart</Label>
                  } />
                </Label.Group>
                <Divider style={{margin: '.5em 0'}}/>
                <Header as='h6' style={{margin: '0'}}>
                  <Header.Content>
                    Click to copied and paste your selected variable if not added automaticaly.
                  </Header.Content>
                </Header>
              </div>
              <div style={{flex: '1', padding: '1em 0 .5em 0'}}>
                <Form.Group widths='16'>
                  {this.props.media!='Email' && 
                  <Field name='script' component={TextAreaField}
                  width='16'
                  rows='8'
                  validate={[required]}
                  label='Content Script' /> }

                  {this.props.media=='Email' && 
                  <Field name='script' component={WysiwygField} 
                  modal={modal}
                  screenWidth={screenWidth}
                  width='16'
                  rows='8'
                  validate={[required]}
                  label='Content Script' />}
                </Form.Group>
                {(this.props.media=='Email' || this.props.media=='Whatsapp' || this.props.media=='Telegram' || this.props.media=='Facebook' || this.props.media=='Twitter' || this.props.media=='Instagram') && <>
                <Divider hidden/>
                <Form.Group widths='equal' style={{marginTop: '2em'}}>
                  <Form.Field>
                    <Field name='image' component={ImageUploadField}
                    // width={420} 
                    height={420} 
                    aspectRatio= '1:1' />
                  </Form.Field>
                </Form.Group>
                </>}
                {/* <Form.Group widths='16'>
                  <Field name='video' component={TextAreaField}
                  width='16'
                  label='Video Attachment' />
                </Form.Group> */}
              </div>
            </div>
          </Segment>
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Script' : 'Insert Script') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.scriptStore.getScript!=undefined && state.scriptStore.getScript.progress==1 ? state.scriptStore.getScript.data : {isEnabled: 1}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      media: selector(state, 'media'),
      script: selector(state, 'script'),
    }),
  )
  (reduxForm({
    form: 'ScriptForm',	// a unique identifier for this form
  })(ScriptForm)
  )
)