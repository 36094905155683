import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import PerformanceCampaign from '../../libraries/whatsapp/PerformanceCampaign';
import PerformanceAgent from '../../libraries/whatsapp/PerformanceAgent';
import PerformanceMessaging from '../../libraries/whatsapp/PerformanceMessaging';
import PerformanceTicket from '../../libraries/whatsapp/PerformanceTicket';
import PerformanceMessagingByDate from '../../libraries/whatsapp/PerformanceMessagingByDate';
import PerformanceTicketByDate from '../../libraries/whatsapp/PerformanceTicketByDate';

class PerformanceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          {this.props.campaign && <PerformanceCampaign screenWidth={screenWidth} screenHeight={screenHeight} mode={mode} setMode={this.setMode.bind(this)} openModal={this.props.openModal} />}
          {this.props.agent && <PerformanceAgent screenWidth={screenWidth} screenHeight={screenHeight} mode={mode} setMode={this.setMode.bind(this)} openModal={this.props.openModal} />}
          {this.props.messaging && mode==1 && <PerformanceMessaging screenWidth={screenWidth} screenHeight={screenHeight} mode={mode} setMode={this.setMode.bind(this)} openModal={this.props.openModal} />}
          {this.props.messaging && mode==2 && <PerformanceMessagingByDate screenWidth={screenWidth} screenHeight={screenHeight} mode={mode} setMode={this.setMode.bind(this)} openModal={this.props.openModal} />}
          {this.props.ticket && mode==1 && <PerformanceTicket screenWidth={screenWidth} screenHeight={screenHeight} mode={mode} setMode={this.setMode.bind(this)} openModal={this.props.openModal} />}
          {this.props.ticket && mode==2 && <PerformanceTicketByDate screenWidth={screenWidth} screenHeight={screenHeight} mode={mode} setMode={this.setMode.bind(this)} openModal={this.props.openModal} />}
        </Container>
      )
    }
}

export default PerformanceBody
