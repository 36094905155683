import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { targetStatus } from "../../libraries/common/StaticMasterData"

import { connect } from "react-redux"
import { browseWacontact, statusWacontact, removeWacontact } from "../../actions/whatsapp/wacontactAction"
import { newchatWacommand } from "../../actions/whatsapp/wacommandAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    wacontactStore: state.wacontactStore,
    wacommandStore: state.wacommandStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseWacontact:(objParam) => dispatch(browseWacontact(objParam)),
    statusWacontact:(objParam) => dispatch(statusWacontact(objParam)),
    removeWacontact:(objParam) => dispatch(removeWacontact(objParam)),

    newchatWacommand:(objParam) => dispatch(newchatWacommand(objParam)),
  }
};


class TargetBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {
      remark: false,
    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.wacontactStore.browseWacontact!==undefined ? this.props.wacontactStore.browseWacontact : {})
    this.props.browseWacontact(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.wacontactStore.browseWacontact!==undefined ? this.props.wacontactStore.browseWacontact : {})
    this.props.campaignId && this.props.browseWacontact({search: {...(this.props.campaignId ? {campaignId: this.props.campaignId} : {})}})
    !this.props.campaignId && this.props.browseWacontact({search: {...search, ...(this.props.campaignId ? {campaignId: this.props.campaignId} : {campaignId: undefined})}, paging: paging})
  }

  onChangeStatus(id, status) {
    this.props.statusWacontact({contactId:id, isEnabled: status})
  }

  removeWacontact(id) {
    const { search, paging }= (this.props.wacontactStore.browseWacontact!==undefined ? this.props.wacontactStore.browseWacontact : {})

    this.props.removeWacontact({contactId: id})
    setTimeout(()=>{
      this.props.browseWacontact(
      {
        paging: {...paging},
        search: search
      }
    )}, 1000);
  }

  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { remark }= this.state

    const { data, search, paging }= (this.props.wacontactStore.browseWacontact!==undefined ? this.props.wacontactStore.browseWacontact : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', overflow: 'auto'}}>
          <div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell width='6'><Header color='blue' as='h5'>CONTACT</Header></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell width='10'><Header color='blue' as='h5'>DESCRIPT</Header></Table.HeaderCell>
                  {/* <Table.HeaderCell><Header color='blue' as='h5'></Header></Table.HeaderCell> */}
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                { data ? data.map((item,key)=>{
                    return(
                      <Table.Row key={++seq}>
                        <Table.Cell>{seq}</Table.Cell>
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                          <Popup inverted content='Remove' trigger={
                            <Icon name='remove' color='red' onClick={this.removeWacontact.bind(this, item.contactId)} style={{cursor: 'pointer', fontSize: '1.2em'}} />
                          } />
                        </Table.Cell>
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                          <Popup inverted content='Edit' trigger={
                            <Icon name='edit' onClick={this.props.setMode.bind(this, '2',item.contactId)} style={{cursor: 'pointer'}}/>
                          } />
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              <b style={(item.isEnabled=='1' || item.isInvalid=='1') ? {} : {color: '#666', textDecoration: 'line-through'}}>{item.name}</b>
                              <Header.Subheader>Contact Type: <b>{item.contactType}</b></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                          <Popup inverted content='Enabled' position='top left' trigger={
                            <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} onClick={this.props.setMode.bind(this, '2',item.contactId)} style={{cursor: 'pointer'}}/>
                          } />
                        </Table.Cell>
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                          <Popup inverted content='Invalid' position='top left' trigger={
                            <Icon name={item.isInvalid ? 'toggle on' : 'toggle off'} onClick={this.props.setMode.bind(this, '2',item.contactId)} style={{cursor: 'pointer'}}/>
                          } />
                        </Table.Cell>
                        <Table.Cell>
                        {(item.relativePhone || item.emergencyPhone) && <Label as='a' style={{padding: '.5em .4em', display: 'block'}} color='olive' onClick={this.props.openModal.bind(this, 'chatbox-outbound', item)}>
                            <Header as='h5'>
                              <Icon name='mobile alternate' />
                              <Header.Content>
                                {item.relativePhone || item.emergencyPhone}
                                <Header.Subheader>{(item.relativePhone && 'Relatives') || (item.emergencyPhone && 'Emergency')}</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </Label>}
                        </Table.Cell>
                        {/* <Table.Cell>
                          {(item.whatsapp || item.mobile) && <Label circular as='a' style={{padding: '.6em .5em'}} color='green' onClick={this.props.openModal.bind(this, 'chatbox-outbound', item)}>
                            <Popup inverted content='New Chat Session' position='right center' trigger={
                              <Icon name='comments' style={{fontSize: '1.2em', margin: '0'}} circular inverted color='yellow' />
                            } />
                          </Label>}
                        </Table.Cell> */}
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                          <Popup inverted content='Blocked' position='top right' trigger={
                            <Icon name={item.isUnsub ? 'toggle on' : 'toggle off'} onClick={this.props.setMode.bind(this, '2',item.contactId)} style={{cursor: 'pointer'}}/>
                          } />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
              </Table.Body>
            </Table>
          </div>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetBrowse)
