import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup, 
} from 'semantic-ui-react'
import { saveAs } from 'file-saver';

import { accountOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, DatePickerField, MaskedField, SelectField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { monthlyOptions, viewOptions } from '../../libraries/common/StaticMasterData';

import { connect } from "react-redux"
import { perfClient } from "../../actions/halo/performanceAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    performanceStore: state.performanceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfClient:(objParam) => dispatch(perfClient(objParam)),
  }
};


class PerformanceMessagingSearchByDate extends Component {
  constructor(props) {
    super(props);
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }
  
  submitForm(values) {
    const { search }= this.state
    this.props.perfClient({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeInput(e, v) {
    // console.log(e, v, e.target.name, v.substr(3,7)+v.substr(0,2))
    const { search }= (this.props.performanceStore.perfClient!==undefined ? this.props.performanceStore.perfClient : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    if (e.target.name=='period' && !isNaN(v.substr(3,7)) && !isNaN(v.substr(0,2))) {
      setTimeout(()=>{
        if (new Date().getTime()-parent.state.lastChange>=1000) {
          parent.props.perfClient({search: {...search, period: v.substr(3,7)+v.substr(0,2),month: v}})
        }
      }, 1000)
    } else if (e.target.name=='keyword') {
      setTimeout(()=>{
        if (new Date().getTime()-parent.state.lastChange>=1000) {
          parent.props.perfClient({search: {...search, keyword: v}})
        }
      }, 1000)
    }
  }

  onChangeDate(key, v) {
    const { search }= (this.props.performanceStore.perfClient!==undefined ? this.props.performanceStore.perfClient : {})
    var values= []
    values[key]= v.substr(0,8).replace(/-/g,'');
    values['date']= v;
    this.props.perfClient({search: {...search, ...values}})
  }
  onChangeSelect(e, v) {
    const { search }= (this.props.performanceStore.perfClient!==undefined ? this.props.performanceStore.perfClient : {})

    if (e=='availability') {
      this.props.perfClient({search: {...search, availability: v}})
    } else if (e=='period') {
      this.props.perfClient({search: {...search, period: v}})
    } else {
      this.props.perfClient({search: {...search, view: v}})
    }
  }
  
  render() {
    // console.log(this.props)
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props

    const { search }= (this.props.performanceStore.perfClient!==undefined ? this.props.performanceStore.perfClient : {})
    
    return (
      <div>
        <div style={{padding: '0', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='sticky note' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Agent Messaging Perf.
                </Header.Content>
              </Header>
            </div>
            <div>
              <Popup position= 'bottom right' inverted content='By Agent'
                trigger= {
                  <Button type='button' icon color={mode==1 ? 'teal' : null} onClick={this.props.setMode.bind(this, 1)}><Icon name='user circle' /></Button>
                } 
              />
              <Popup position= 'bottom right' inverted content='By Date'
                trigger= {
                  <Button type='button' icon color={mode==2 ? 'teal' : null} onClick={this.props.setMode.bind(this, 2)}><Icon name='calendar alternate' /></Button>
                } 
              />
            </div>
          </div>
          <div style={{display: 'flex', margin: '1em 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <div style={{flex: '1'}}>
              <Form onSubmit={handleSubmit(this.submitForm.bind(this))} >
                <Form.Group>
                  <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                  width={10} 
                  placeholder= 'Search agent ... <ENTER>' />
                  {/* <Field name='period' component={MaskedField} onChange= {this.onChangeInput.bind(this)}
                  width={4} 
                  mask="99/2099" 
                  // maskChar=" "
                  placeholder= 'Monthly Period (mm/yyyy' /> */}
                  {/* <Field name='availability' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'availability')}
                  options= {accountOptions()}
                  width={3} 
                  placeholder= 'Availability' /> */}
                  {search && search.view!='Daily' && 
                    <Field name='period' component={SelectField} onChange= {this.onChangeSelect.bind(this, 'period')}
                    width={3} 
                    options={monthlyOptions()}
                    placeholder= 'Period' />
                  }
                  {search && search.view=='Daily' && 
                    <Field name='date' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'period')}
                    width={3} 
                    options={monthlyOptions()}
                    placeholder= 'Period' />
                  }
                  <Field name='view' component={SelectField} onChange= {this.onChangeSelect.bind(this)}
                  width={3} 
                  options={viewOptions}
                  placeholder= 'Daily / Monthly' />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.performanceStore.perfClient!=undefined && state.performanceStore.perfClient.progress==1 ? state.performanceStore.perfClient.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'PerformanceMessagingSearchByDate',	// a unique identifier for this form
  })(PerformanceMessagingSearchByDate)
  )
)